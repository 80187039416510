import { SideBar, SideBarItem } from "../../../components/sideBar/SideBar";
import manageServiceIcon from "../../../../assets/sideBarIcons/blueNormal/manageService.png";
import manageServiceIcon_x from "../../../../assets/sideBarIcons/blueNormal/manageService_x.png";
import manageAgentIcon from "../../../../assets/sideBarIcons/company/manageAgent.png";
import manageAgentIcon_x from "../../../../assets/sideBarIcons/company/manageAgent_x.png";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { zIndex } from "../../../../configs/zIndex";
import { ManageCompanyPage } from "../ManageCompanyPage";
import { LoanResultListComponent } from "../MyPage_Company/LoanResultListComponent";
import { LoanResultListComponent_Bank } from "./LoanResultListComponent_Bank";
import { ManageAgent_Bank } from "./ManageAgent_Bank";

export const MyPageIndex = {
  Unselected: "-1",
  ManageServiceData: "0",
  ManageAgent: "2",
};

export function BankMyPage() {
  const [step, setStep] = useState(MyPageIndex.ManageServiceData);
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    switch (step) {
      case MyPageIndex.Unselected:
        setContent(null);
        break;
      case MyPageIndex.ManageServiceData:
        setContent(
          // 여기서는 전체 목록을 불러올 것
          <LoanResultListComponent_Bank loanInfo={{ loanRequestId: "" }} />,
        );
        break;
      case MyPageIndex.ManageAgent:
        setContent(<ManageAgent_Bank />);
        break;
    }
  }, [step]);

  return (
    <SeparateStyle>
      <StickyStyle>
        <SideBar
          items={getItems(
            step ? step : MyPageIndex.Unselected,
            (step: string) => {
              setStep(step);
            },
          )}
        />
      </StickyStyle>
      <ContentStyle>{content ? content : ""}</ContentStyle>
    </SeparateStyle>
  );
}

const SeparateStyle = styled.div`
  display: flex;
`;
const StickyStyle = styled.div`
  position: fixed;
  top: 25%;
  z-index: ${zIndex.stikyContent};
  width: 15%;
  height: 50%;
`;
const ContentStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

function getItems(
  step: string,
  onClickStep: (step: string) => void,
): SideBarItem[] {
  const items: SideBarItem[] = [];
  items.push({
    text: "서비스 데이터 관리",
    imgSrc: manageServiceIcon,
    imgSrc_Selected: manageServiceIcon_x,
    isSelected: step === MyPageIndex.ManageServiceData,
    onClick: () => {
      onClickStep(MyPageIndex.ManageServiceData);
    },
  });
  items.push({
    text: "담당자 관리",
    imgSrc: manageAgentIcon,
    imgSrc_Selected: manageAgentIcon_x,
    isSelected: step === MyPageIndex.ManageAgent,
    onClick: () => {
      onClickStep(MyPageIndex.ManageAgent);
    },
  });

  return items;
}
