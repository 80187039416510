import { CommonBlueButton } from "./buttons/commonButtons";
import CommonCheckBox from "./CommonCheckBox";
import { styled } from "styled-components";
function PolicyAgreement({
  checked,
  onChange,
  url,
}: {
  checked: boolean;
  onChange: (checked: boolean) => void;
  url: string;
}) {
  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  const VIEW_FULL_TEXT = "전문 보기";
  const CHECK_AGREEMENT_POLICY = "약관 동의";

  return (
    <Wrapper>
      <CommonBlueButton
        text={VIEW_FULL_TEXT}
        onclick={() => {
          handleOpenNewTab(url);
        }}
        minWidth="50px"
      />

      <Space />
      <CommonCheckBox
        text={CHECK_AGREEMENT_POLICY}
        checked={checked}
        onChange={onChange}
      />
    </Wrapper>
  );
}

export default PolicyAgreement;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
`;

const Space = styled.div`
  height: 20px;
`;
