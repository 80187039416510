import { useEffect, useState } from "react";
import { CommonFloatingCard } from "../../../components/CommonFloatingCard";
import styled from "styled-components";
import { fontFamily } from "../../../../configs/font";
import { CommonInput } from "../../../components/inputs/CommonInput";
import {
  CommonBlueButton,
  CommonWhiteButton,
} from "../../../components/buttons/commonButtons";
import { changePassword, checkPassword } from "../../../../repository/userApi";
import { getLoginedUser, logout } from "../../../../configs/user";
import { useNavigate } from "react-router-dom";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import { randomInt } from "crypto";
import {
  getBankAgentInfo,
  setAgentInfo_bank,
} from "../../../../repository/agentApi";
import { CommonDoneUI } from "../../../components/CommonDoneUi";
import { InputMode } from "../../../../configs/inputMode";
import { PassWordInput } from "../../../components/inputs/PassWordInput";
import { PassWordCheckInput } from "../../../components/inputs/PassWordInputCheck";
import { PhoneNumberInput } from "../../../components/inputs/PhoneNumberInput";
import { isDebug } from "../../../../configs/debug";
import EmailInput from "../../../components/inputs/EmailInput";

const STEP = {
  password: "password",
  manageInfo: "manageIndo",
  done: "done",
};
const width = "350px";

export function ManageAgent_Bank() {
  const [step, setStep] = useState<string>(STEP.password);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [oldPw, setOldPw] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    switch (step) {
      case STEP.password:
        setContent(
          <PasswordCard
            setNextStep={(oldPw: string) => {
              setOldPw(oldPw);
              setStep(STEP.manageInfo);
            }}
          />,
        );
        break;
      case STEP.manageInfo:
        showLoading(true);
        getBankAgentInfo()
          .then((result: ManageBankAgentInfoInputs) => {
            setContent(
              <ChangeInfoCards
                gotoDone={() => {
                  setStep(STEP.done);
                }}
                initialInputs={result}
                oldPw={oldPw}
              />,
            );
          })
          .catch((e) => {
            showToast("정보를 불러오는 중 오류가 발생했습니다", 1000);
          })
          .finally(() => {
            showLoading(false);
          });
        break;
      case STEP.done:
        setContent(<GotoDonePage />);
        break;
    }
  }, [step]);
  return <div>{content}</div>;
}

function GotoDonePage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      "/done/담당자 정보 변경 완료/담당자 정보 변경이 성공적으로 완료되었습니다.",
    );
  }, []);
  return <div></div>;
}

function PasswordCard({
  setNextStep,
}: {
  setNextStep: (oldPw: string) => void;
}) {
  const navigate = useNavigate();
  let password = "";
  let clearInput: (() => void) | null = null;
  return (
    <CommonFloatingCard>
      <PassWordInput
        needRegex={false}
        inputMode={InputMode.WRITE}
        width="350px"
        onTextChanged={(str) => {
          password = str;
        }}
      />

      <SpaceStyle />
      <CenterStyle>
        <CommonBlueButton
          minWidth="100px"
          text="비밀번호 확인"
          onclick={() => {
            const loginedUser = getLoginedUser();
            if (loginedUser) {
              checkPassword(loginedUser, password)
                .then((result) => {
                  if (result) {
                    setNextStep(password);
                  } else {
                    showToast("비밀번호가 일치하지 않습니다.", 3000);
                  }
                })
                .catch(() => {
                  showToast("오류가 발생 했습니다.", 3000);
                });
            } else {
              logout(() => {
                navigate("/");
              });
            }
          }}
        />
      </CenterStyle>
    </CommonFloatingCard>
  );
}
const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const SpaceStyle = styled.div`
  width: 1px;
  height: 40px;
`;

function ChangeInfoCards({
  gotoDone,
  initialInputs,
  oldPw,
}: {
  gotoDone: () => void;
  initialInputs?: ManageBankAgentInfoInputs;
  oldPw: string;
}) {
  return (
    <div>
      <ManageCommonInfoCard gotoDone={gotoDone} initialInputs={initialInputs} />
      <SpaceStyle />
      <ManagePasswordCard oldPw={oldPw} />
    </div>
  );
}

function ManagePasswordCard({ oldPw }: { oldPw: string }) {
  const [pw, setPw] = useState("");
  const [v1, setV1] = useState(false);
  const [v2, setV2] = useState(false);
  return (
    <CommonFloatingCard>
      <PassWordInput
        needRegex={true}
        inputMode={InputMode.WRITE}
        width={width}
        onTextChanged={(s) => {
          setPw(s);
        }}
        isValid={(v) => {
          setV1(v);
        }}
      />

      <PassWordCheckInput
        inputMode={InputMode.WRITE}
        width={width}
        pwValueForCheck={pw}
        onVaild={(v) => {
          setV2(v);
        }}
      />

      <SpaceStyle />
      <CenterStyle>
        <CommonBlueButton
          minWidth="100px"
          text="비밀번호 변경하기"
          onclick={() => {
            if (v1 && v2) {
              showLoading(true);
              changePassword(oldPw, pw)
                .then(() => {})
                .catch((e) => {
                  showToast("비밀번호 변경 실패", 1000);
                })
                .finally(() => {
                  showLoading(false);
                });
            } else {
              showToast("비밀번호를 확인해 주세요", 1000);
            }
          }}
        />
      </CenterStyle>
      <SpaceStyle />
    </CommonFloatingCard>
  );
}

export interface ManageBankAgentInfoInputs {
  agentName: string;
  agentEMail: string;
  agentPhone: string;
}

export interface ManageBankAgentAtPopUP {
  bank: string;
  agentName: string;
  agentEMail: string;
  agentPhone: string;
}

function ManageCommonInfoCard({
  gotoDone,
  initialInputs,
}: {
  gotoDone: () => void;
  initialInputs?: ManageBankAgentInfoInputs;
}) {
  const [inputs, setInputs] = useState<ManageBankAgentInfoInputs>(
    initialInputs
      ? initialInputs
      : {
          agentEMail: "",
          agentName: "",
          agentPhone: "",
        },
  );
  return (
    <CommonFloatingCard>
      <CommonInput
        inputMode={InputMode.RW}
        initialInput={inputs.agentName}
        title="담당자 이름"
        onTextChanged={(s) => {
          inputs.agentName = s;
        }}
        setClearInput={() => {}}
        description={null}
        placeHold={null}
        width={width}
      />

      <EmailInput
        inputMode={InputMode.RW}
        initialInput={inputs.agentEMail}
        onTextChanged={(s) => {
          inputs.agentEMail = s;
        }}
        width={width}
      />

      <PhoneNumberInput
        inputMode={InputMode.RW}
        initialInput={inputs.agentPhone}
        onTextChanged={(s) => {
          inputs.agentPhone = s;
        }}
        width={width}
      />

      <SpaceStyle />
      <CenterStyle>
        <CommonBlueButton
          minWidth="100px"
          text="담당자 정보 변경"
          onclick={() => {
            if (isDebug) {
              console.log(inputs);
            }
            showLoading(true);
            setAgentInfo_bank(
              inputs.agentName,
              inputs.agentPhone,
              inputs.agentEMail,
            )
              .then((b) => {
                if (b) {
                  gotoDone();
                } else {
                  showToast("실패하였습니다.", 3000);
                }
              })
              .catch(() => {
                showToast("실패하였습니다.", 3000);
              })
              .finally(() => showLoading(false));
          }}
        />
      </CenterStyle>
      <SpaceStyle />
    </CommonFloatingCard>
  );
}
