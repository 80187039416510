export const colors = {
  main: "#4272F6",
  sub: "#A0B9FB",
  background: "#F3F5F9",
  error: "#DD2020",
  active: "#4272F6",
  label: "#2C2C2C",
  helper: "#5B667A",
  placeholder: "#8D97A9",
  stroke: "#C9CFD7",
  white: "#FFFFFF",
};
