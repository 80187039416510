import { isDebug } from "../configs/debug";

interface CommonItem {
  value: any;
  expire: number;
}

export function saveObjectToLocalStorage(
  key: string,
  object: Object,
  expireMin: number,
) {
  const item: CommonItem = {
    value: object,
    expire: Date.now() + expireMin * 60 * 1000,
  };
  if (localStorage.getItem(key) !== null) {
    if (isDebug) {
      console.log("key값이 존재했으나 덮어씌웠습니다.");
    }
  }
  localStorage.setItem(key, JSON.stringify(item));
}

export function getObjectFromLocalStorage(key: string): any {
  const result = localStorage.getItem(key);
  if (result === null) {
    return null;
  }

  const item = JSON.parse(result);
  try {
    const commonItem = item as CommonItem;
    if (commonItem.expire > Date.now()) {
      return commonItem.value;
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

export function saveExitTime() {
  localStorage.setItem("exitTime", Date.now().toString());
}

// 첫 진입이거나, 5초안에 복귀했다면 새로고침으로 보지 않음
export function isSafeExitTime(): boolean {
  const dateString = localStorage.getItem("exitTime");
  if (dateString) {
    const diff = Date.now() - new Date(Number(dateString)).getTime();
    if (isDebug) {
      console.log(diff);
    }
    if (diff < 1000 * 5) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}
