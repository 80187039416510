import { styled } from "styled-components";
import { colors } from "../../configs/colors";
import { useEffect, useRef, useState } from "react";
import dateIcon from "../../assets/dateCalendarImg.png";
import { InputMode } from "../../configs/inputMode";

export default function CommonDatePicker({
  inputMode,
  initialDate,
  placeHolder,
  onInput,
}: {
  inputMode: string;
  initialDate?: Date;
  placeHolder?: string;
  onInput: (date: Date) => void;
}) {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (initialDate && inputRef.current) {
      inputRef.current.valueAsDate = initialDate;
    }
  }, [inputRef]);

  return (
    <div>
      <DatePicker
        onClick={() => {
          if (inputMode !== InputMode.READ) {
            inputRef.current?.showPicker();
          }
        }}
        readOnly={inputMode === InputMode.READ}
        ref={inputRef}
        type="date"
        onInput={(e) => {
          if (inputMode !== InputMode.READ) {
            const target = e.target as HTMLInputElement;
            const date = new Date(target.valueAsNumber);
            onInput(date);
            setSelectedDate(target.value);
          }
        }}
      />
    </div>
  );
}

const DatePicker = styled.input`
  border: 1px solid ${colors.placeholder}; /* 테두리 설정은 본인 맘대로 */
  border-radius: 8px;
  position: relative; /* 캘린더 아이콘을 클릭해야만 달력이 보이기 때문에 이 영역 자체를 제어하기 위해 설정 */
  width: 150px;
  padding: 8px;
  height: 30px;
  background-color: white;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;
const DateImg = styled.img`
  width: 24px;
  height: 24px;
`;
