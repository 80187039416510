import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fontFamily } from "../../configs/font";
import { CommonBlueButton, CommonWhiteButton } from "./buttons/commonButtons";
import { CommonFloatingCard } from "./CommonFloatingCard";
import commonDoneIcon from "../../assets/commonDoneIcon.png";

export function CommonDoneUI({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  const navigate = useNavigate();
  return (
    // <CommonFloatingCard>
    <ParentStyle>
      <ImgStyle src={commonDoneIcon} />
      <TitleStyle>{title}</TitleStyle>
      <DescriptionStyle>{description}</DescriptionStyle>
      {/* <CommonBlueButton
        text="마이페이지"
        minWidth="100px"
        onclick={() => {
          navigate("/MyPage");
        }}
      /> */}
      <Spacer />
      <CommonWhiteButton
        text="홈으로"
        minWidth="100px"
        onclick={() => {
          navigate("/");
        }}
      />
    </ParentStyle>
    // </CommonFloatingCard>
  );
}

const ImgStyle = styled.img`
  height: 220px;
  width: 220px;
  margin-right: 5px;
`;

const Spacer = styled.div`
  height: 10px;
`;

const ParentStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  align-content: center;
  align-items: center;
`;

const TitleStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 50px;
`;

const DescriptionStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 24px;
`;
