import styled from "styled-components";
import { fontFamily } from "../../../configs/font";
import { colors } from "../../../configs/colors";
import { useEffect, useState } from "react";
import { InputMode } from "../../../configs/inputMode";
import { isDebug } from "../../../configs/debug";

export interface CommonInputProps {
  inputMode: string;
  title: string;
  placeHold: string | null;
  description: string | null;
  errorText?: string | null;
  width: string;
  onTextChanged: (str: string) => void;
  setClearInput: (clearInputFunction: () => void) => void;
  initialInput?: string;
  passWordType?: boolean;
  needPercent?: boolean;
  needWon?: boolean;
}

export function CommonInput({
  inputMode,
  title,
  placeHold,
  description,
  errorText,
  width,
  onTextChanged,
  setClearInput,
  initialInput,
  passWordType,
  needPercent,
  needWon,
}: CommonInputProps) {
  const [value, setValue] = useState(initialInput ? initialInput : "");
  useEffect(() => {
    setClearInput(() => setValue(""));
  }, []);
  useEffect(() => {
    if (initialInput) {
      setValue(initialInput);
    }
  }, [initialInput]);

  return (
    <ParentStyle style={{ width: width }}>
      <TitleArea> {title} </TitleArea>

      {/* "원" 단위 필요할때 */}
      {needWon ? (
        <InputWithUnitWrapper>
          <InputAreaWithUnit
            readOnly={inputMode === InputMode.READ}
            placeholder={placeHold ? placeHold : "입력해주세요"}
            onChange={(event) => {
              setValue(event.target.value);
              onTextChanged(event.target.value);
            }}
            value={value}
            type={passWordType ? "password" : ""}
          />
          <UnitLabel>원</UnitLabel>
        </InputWithUnitWrapper>
      ) : (
        ""
      )}

      {/* % 단위 필요할때 */}
      {needPercent ? (
        <InputWithUnitWrapper>
          <InputAreaWithUnit
            readOnly={inputMode === InputMode.READ}
            placeholder={placeHold ? placeHold : "입력해주세요"}
            onChange={(event) => {
              setValue(event.target.value);
              onTextChanged(event.target.value);
            }}
            value={value}
            type={passWordType ? "password" : ""}
          />
          <UnitLabel>%</UnitLabel>
        </InputWithUnitWrapper>
      ) : (
        ""
      )}

      {!needWon && !needPercent ? (
        <InputArea
          readOnly={inputMode === InputMode.READ}
          placeholder={placeHold ? placeHold : "입력해주세요"}
          onChange={(event) => {
            setValue(event.target.value);
            onTextChanged(event.target.value);
          }}
          value={value}
          type={passWordType ? "password" : ""}
        />
      ) : (
        ""
      )}

      {description
        ? description.split("\n").map((str: string, key) => {
            return <DescriptionStyle key={key}>{str}</DescriptionStyle>;
          })
        : ""}

      {/* errorText 확인용 코드   */}

      {errorText
        ? errorText.split("\n").map((str: string, key) => {
            return <ErrorStyle key={key}>{str}</ErrorStyle>;
          })
        : ""}
    </ParentStyle>
  );
}
export const DescriptionStyle = styled.p`
  width: calc(100% - 20px);
  font-family: ${fontFamily.Medium};
  font-size: 14px;
  color: ${colors.placeholder};
`;

const ErrorStyle = styled(DescriptionStyle)`
  width: calc(100% - 20px);
  font-family: ${fontFamily.Medium};
  font-size: 14px;
  color: ${colors.error};
`;

export const TitleArea = styled.p`
  width: calc(100% - 20px);
  font-family: ${fontFamily.Medium};
  font-size: 18px;
  color: ${colors.label};
`;
const InputArea = styled.input`
  padding: 10px;
  width: calc(100% - 20px);
  font-family: ${fontFamily.Medium};
  font-size: 16px;
  &::placeholder {
    color: ${colors.placeholder};
  }
  color: ${colors.label};
  border: 1px solid ${colors.placeholder};
  border-radius: 8px;
  background-color: white;
`;

const InputWithUnitWrapper = styled.div`
  display: flex;
`;

const InputAreaWithUnit = styled(InputArea)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: transparent;
`;
const UnitLabel = styled.div`
  padding: 10px;
  width: fit-content;
  font-family: ${fontFamily.Medium};
  font-size: 16px;
  color: ${colors.label};
  border: 1px solid ${colors.placeholder};
  border-radius: 8px;
  background-color: white;

  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: transparent;
`;
const ParentStyle = styled.div`
  width: ${(props) => (props.style?.width ? props.style?.width : "300px")};
`;
