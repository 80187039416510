import styled from "styled-components";
import logo from "../../../assets/logo.png";
import {
  CommonBlueButton,
  CommonLabelButton,
  CommonWhiteButton,
} from "../buttons/commonButtons";
import { LoginedUser } from "../../../uiModels/User";
import { colors } from "../../../configs/colors";
import { useNavigate } from "react-router-dom";
import { zIndex } from "../../../configs/zIndex";

export function Header({
  loginUserInfo,
}: {
  loginUserInfo: LoginedUser | null;
}) {
  return (
    <HeaderStyle>
      <RowStyle>
        <Logo />
        <ScrollStyle>
          <Menus />
        </ScrollStyle>
        <UserArea loginUserInfo={loginUserInfo} />
      </RowStyle>
    </HeaderStyle>
  );
}

function Logo() {
  const navigation = useNavigate();
  return (
    <LogoStyle
      src={logo}
      height={30}
      width={163}
      onClick={() => navigation("/")}
    />
  );
}

function UserArea({ loginUserInfo }: { loginUserInfo: LoginedUser | null }) {
  const navigate = useNavigate();
  let button1 = (
    <CommonWhiteButton
      text="마이페이지"
      onclick={() => navigate("/myPage")}
      minWidth="100px"
    />
  );
  let button2 = (
    <CommonBlueButton
      text="로그아웃"
      onclick={() => navigate("/logout")}
      minWidth="100px"
    />
  );
  if (loginUserInfo === null) {
    button1 = (
      <CommonWhiteButton
        text="로그인"
        onclick={() => navigate("/login")}
        minWidth="100px"
      />
    );
    button2 = (
      <CommonBlueButton
        text="회원가입"
        onclick={() => navigate("/registerCompany")}
        minWidth="100px"
      />
    );
  }
  return (
    <RowStyle>
      {button1}
      <Spacer20 />
      {button2}
    </RowStyle>
  );
}

function Menus() {
  const navigate = useNavigate();
  return (
    <MenuStyle>
      <CommonLabelButton
        text={"블루노멀 소개"}
        onclick={() => {
          // navigate("/introduceBluenormal");
          openWithNewWindow("https://bluenormal.info/about");
        }}
      />
      <CommonLabelButton
        text={"서비스 소개"}
        onclick={() => {
          // navigate("/introduceService");
          openWithNewWindow("https://bluenormal.info/service");
        }}
      />
      <CommonLabelButton
        text={"서비스 신청"}
        onclick={() => {
          navigate("/applyService");
        }}
      />
      <CommonLabelButton
        text={"문의하기"}
        onclick={() => {
          // navigate("/contact");
          openWithNewWindow("https://bluenormal.info/contact");
        }}
      />
      <CommonLabelButton
        text={"이용 후기"}
        onclick={() => {
          // navigate("/use");
          openWithNewWindow("https://bluenormal.info/review");
        }}
      />
    </MenuStyle>
  );
}

function openWithNewWindow(url: string) {
  // 임시 로직
  window.open(url.replace("https", "http"), "newWindow");
}

const Spacer20 = styled.div`
  width: 20px;
`;
const HeaderStyle = styled.div`
  background-color: ${colors.background};
  height: 75px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-content: center;
  position: sticky;
  left: 0;
  top: 0;
  z-index: ${zIndex.header};
`;
const RowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MenuStyle = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;
const ScrollStyle = styled.div`
  overflow-x: scroll;
  margin-left: 10px;
  margin-right: 10px;
`;
const LogoStyle = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
