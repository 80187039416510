import { randomInt } from "crypto";
import {
  MainAudit_Corperation_Input,
  MainAudit_Corperation_Input_Id,
} from "../views/components/MainAudit_Corperation";
import {
  MainAudit_Personal_Input,
  MainAudit_Personal_Input_Id,
} from "../views/components/MainAudit_Personal";
import { PreScreeningInput } from "../views/components/PreScreening";
import { BANK_PRIME, LOAN_TYPE, REF_DOC_TYPE } from "./models";
import {
  getLoanInfoById,
  ServerLoanDetailFileInfo,
  ServerLoanDetailInfo,
} from "./loanApi";
import { downLoadFile, File_Server } from "./fileApi";
import { dataURLtoFile } from "../utils/fileUtils";

export function getMainScreeningCorperationTmp(loanId: string): Promise<{
  input: MainAudit_Corperation_Input;
  ids: MainAudit_Corperation_Input_Id;
}> {
  return getLoanInfoById(loanId).then((info: ServerLoanDetailInfo) => {
    const result: MainAudit_Corperation_Input = {
      FIN_STAT_1: null,
      FIN_STAT_2: null,
      FIN_STAT_3: null,
      SURTAX_1: null,
      SURTAX_2: null,
      SP_TAX: null,
      CORP_TAX: null,
      PAY_1: null,
      PAY_2: null,
      PAY_3: null,
      PROOF_TAX_ENT: null,
      PROOF_TAX_PRI: null,
      LOCAL_TAX_ENT: null,
      LOCAL_TAX_PRI: null,
      STOCK: null,
      WITHOLDING: null,
      TAX_CREDIT: null,
    };
    const ids: MainAudit_Corperation_Input_Id = {
      FIN_STAT_1: null,
      FIN_STAT_2: null,
      FIN_STAT_3: null,
      SURTAX_1: null,
      SURTAX_2: null,
      SP_TAX: null,
      CORP_TAX: null,
      PAY_1: null,
      PAY_2: null,
      PAY_3: null,
      PROOF_TAX_ENT: null,
      PROOF_TAX_PRI: null,
      LOCAL_TAX_ENT: null,
      LOCAL_TAX_PRI: null,
      STOCK: null,
      WITHOLDING: null,
      TAX_CREDIT: null,
    };
    const loadAllFiles: Promise<boolean>[] = info.loanRefFiles.map(
      (sfInfo: ServerLoanDetailFileInfo) => {
        return downLoadFile(sfInfo.fileId.toString()).then(
          (fs: File_Server) => {
            return dataURLtoFile(
              fs.file,
              REF_DOC_TYPE[sfInfo.fileType] + ".pdf",
            ).then((f: File) => {
              result[sfInfo.fileType] = f;
              ids[sfInfo.fileType] = sfInfo.fileId.toString();
              return true;
            });
          },
        );
      },
    );
    return Promise.all(loadAllFiles).then(() => {
      return { input: result, ids: ids };
    });
  });
}

export function getMainScreeningPersonalTmp(loanId: string): Promise<{
  input: MainAudit_Personal_Input;
  ids: MainAudit_Personal_Input_Id;
}> {
  return getLoanInfoById(loanId).then((info: ServerLoanDetailInfo) => {
    const result: MainAudit_Personal_Input = {
      FIN_TRANS: null,
      PROOF_TAX_PROP: null,
      PROOF_TAX_PROP_BY: null,
      LEASE: null,
      RENT: null,
      REGISTRATION: null,
      isSamePerson: info.loanRefReq.collProvEqual === "Y",
      PROOF_TAX_PROP_P: null,
      PROOF_TAX_PROP_BY_P: null,
      LOCAL_TAX_P_1: null,
      LOCAL_TAX_P_2: null,
      LOCAL_TAX_P_3: null,
      E_LOCAL_TAX_P_1: null,
      E_LOCAL_TAX_P_2: null,
      E_LOCAL_TAX_P_3: null,
    };
    const ids: MainAudit_Personal_Input_Id = {
      FIN_TRANS: null,
      PROOF_TAX_PROP: null,
      PROOF_TAX_PROP_BY: null,
      LEASE: null,
      RENT: null,
      REGISTRATION: null,
      PROOF_TAX_PROP_P: null,
      PROOF_TAX_PROP_BY_P: null,
      LOCAL_TAX_P_1: null,
      LOCAL_TAX_P_2: null,
      LOCAL_TAX_P_3: null,
      E_LOCAL_TAX_P_1: null,
      E_LOCAL_TAX_P_2: null,
      E_LOCAL_TAX_P_3: null,
    };
    const loadAllFiles: Promise<boolean>[] = info.loanRefFiles.map(
      (sfInfo: ServerLoanDetailFileInfo) => {
        return downLoadFile(sfInfo.fileId.toString()).then(
          (fs: File_Server) => {
            return dataURLtoFile(
              fs.file,
              REF_DOC_TYPE[sfInfo.fileType] + ".pdf",
            ).then((f: File) => {
              result[sfInfo.fileType] = f;
              ids[sfInfo.fileType] = sfInfo.fileId.toString();
              return true;
            });
          },
        );
      },
    );
    return Promise.all(loadAllFiles).then(() => {
      return { input: result, ids: ids };
    });
  });
}
