import styled from "styled-components";
import { fontFamily } from "../../configs/font";

interface PageTitleWithDesProps {
  title: string;
  description: string;
}

interface PageGuideWithDesProps {
  title: string;
  GuideList: string[];
}

export function PageTitleWithDes({
  title,
  description,
}: PageTitleWithDesProps) {
  const desList = description.split("\n");
  return (
    <Wrapper>
      <Title>{title}</Title>
      {desList.map((des, key) => {
        return <Text key={key}>{des}</Text>;
      })}
    </Wrapper>
  );
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

export function PageGuideWithDes({ title, GuideList }: PageGuideWithDesProps) {
  return (
    <Wrapper>
      <Text>{title} 서류리스트</Text>

      {GuideList.map((guide, key) => {
        return (
          <Text key={key}>
            가이드 {key + 1}: {guide}{" "}
          </Text>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${fontFamily.Regular};
  margin: 30px;
`;

const Text = styled.p`
  font-size: 20px;
  margin: 0px;
`;
