// import fs from "fs";
import { LegacyRef, useEffect, useRef, useState } from "react";
import { file2Base64, dataURLtoFile } from "../../../utils/fileUtils";
import { PdfViewer } from "../pdfViewer/Pdfviewer";
import styled from "styled-components";
import { showLoading, showToast } from "../baseComponents/BaseComponent";
import { colors } from "../../../configs/colors";
import clipImg from "../../../assets/attachClip.png";
import { InputMode } from "../../../configs/inputMode";

const mb = 1024 * 1024; //1mb(메가)
const limit_size = mb * 50;

export default function AttachedPdfInput({
  showPreview,
  onLoad,
  inputMode,
  initialFile,
  initialFileString,
  initialFileName,
}: {
  showPreview: boolean;
  onLoad: (file: File, src: string) => void;
  inputMode: string;
  initialFile?: File;
  initialFileString?: string;
  initialFileName?: string;
}) {
  const refInput = useRef<HTMLInputElement>(null);
  const refA = useRef<HTMLAnchorElement>(null);
  const [pdfFile, setpdfFile] = useState<File | null>(null);
  const [src, setSrc] = useState<string | null>(null);
  useEffect(() => {
    if (!(inputMode == InputMode.WRITE) && (initialFile || initialFileString)) {
      if (initialFile) {
        setpdfFile(initialFile);
      } else if (initialFileString) {
        dataURLtoFile(
          initialFileString,
          initialFileName ? initialFileName : "임시 파일.pdf",
        ).then((f: File) => {
          setpdfFile(f);
        });
      }
    }
  }, [initialFile]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      if (e.target.files[0].size > limit_size) {
        showToast("50MB 이하의 파일만 업로드 가능합니다.", 2000);
        try {
          if (refInput.current) {
            refInput.current.value = "";
          }
        } catch (err) {}
      } else {
        showLoading(true);
        file2Base64(e.target.files[0])
          .then((result: string) => {
            console.log(result);
            setSrc(result);
            dataURLtoFile(
              result,
              refInput.current?.value
                ? refInput.current?.value
                : "알 수 없는 파일 이름",
            )
              .then((file: File) => {
                onLoad(file, result);
                setpdfFile(file);
                console.log(file);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            showLoading(false);
          });
      }
    }
  };

  // bas364
  return (
    <div>
      <InputCoverStyle
        onClick={() => {
          if (!(inputMode === InputMode.READ)) {
            refInput.current?.click();
          } else {
            refA.current?.click();
          }
        }}
        color={pdfFile?.name ? colors.label : colors.placeholder}
      >
        {pdfFile?.name ? pdfFile?.name : "파일을 선택해 주세요"}
        <AttachClipStyle src={clipImg} />
      </InputCoverStyle>
      <a
        ref={refA}
        href={pdfFile ? URL.createObjectURL(pdfFile) : ""}
        download={pdfFile?.name}
        hidden={true}
      />
      <input
        ref={refInput}
        type="file"
        onChange={onChange}
        accept="application/pdf"
        hidden={true}
      />
      {showPreview && src ? (
        <PdfViewerStyle>
          <PdfViewer src={src} />
        </PdfViewerStyle>
      ) : (
        ""
      )}
    </div>
  );
}

const InputCoverStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: calc(100% - 16px);
  border: 1px solid ${colors.stroke};
  border-radius: 8px;
  background-color: ${colors.white};
  padding: 8px;
  color: ${(props) => (props.color ? props.color : colors.label)};
`;
const AttachClipStyle = styled.img`
  width: 20px;
  height: 20px;
`;

const PdfViewerStyle = styled.div`
  width: 300px;
  height: 600px;
  background-color: gray;
`;
