import styled from "styled-components";
import {
  PreScreeningComponent,
  PreScreeningInput,
} from "../../components/PreScreening";
import { InputMode } from "../../../configs/inputMode";
import { useNavigate, useParams } from "react-router-dom";
import {
  MainAudit_Personal,
  MainAudit_Personal_Input,
  MainAudit_Personal_Input_Id,
} from "../../components/MainAudit_Personal";
import {
  requestMainAudit_Personal,
  tmpStore_Personal,
} from "../../../repository/loanApi";
import {
  showLoading,
  showToast,
} from "../../components/baseComponents/BaseComponent";
import { useEffect, useState } from "react";
import { getMainScreeningPersonalTmp } from "../../../repository/screeningApiForTmp";

export function ApplyService_Personal() {
  const params = useParams();
  const loanId = params.loanId ? params.loanId : "";
  const tmpMode = params.tmpMode ? params.tmpMode : "false";
  const navigate = useNavigate();
  const [ui, setUi] = useState(<div></div>);
  useEffect(() => {
    if (tmpMode === "true") {
      showLoading(true);
      getMainScreeningPersonalTmp(loanId)
        .then(
          (r: {
            input: MainAudit_Personal_Input;
            ids: MainAudit_Personal_Input_Id;
          }) => {
            setUi(
              <CenterStyle>
                <MainAudit_Personal
                  inputMode={InputMode.RW}
                  onClickTmpSave={(
                    inputs: MainAudit_Personal_Input,
                    ids: MainAudit_Personal_Input_Id | null,
                  ) => {
                    tmpClicked(loanId, inputs, ids, () => {
                      navigate(
                        "/done/임시저장 완료/저장이 정상적으로 완료되었습니다.",
                      );
                    });
                  }}
                  onClickRequest={(
                    inputs: MainAudit_Personal_Input,
                    ids: MainAudit_Personal_Input_Id | null,
                  ) => {
                    requestMainAuditPersonalO(loanId, inputs, ids, () => {
                      navigate(
                        "/done/본심사 신청 완료/신청이 정상적으로 완료되었습니다.은행 검토 후 4주 이내에 담당자 이메일로 알람을 보내드리겠습니다.",
                      );
                    });
                  }}
                  onClickReject={null}
                  onClickSuggestLoan={null}
                  mainAudit_Personal_Inputs={r.input}
                  mainAudit_Personal_Input_Id={r.ids}
                />
              </CenterStyle>,
            );
          },
        )
        .catch(() => {
          navigate("/");
        })
        .finally(() => {
          showLoading(false);
        });
    } else {
      setUi(
        <CenterStyle>
          <MainAudit_Personal
            inputMode={tmpMode !== "false" ? InputMode.RW : InputMode.WRITE}
            onClickTmpSave={(
              inputs: MainAudit_Personal_Input,
              ids: MainAudit_Personal_Input_Id | null,
            ) => {
              tmpClicked(loanId, inputs, ids, () => {
                navigate(
                  "/done/임시저장 완료/저장이 정상적으로 완료되었습니다.",
                );
              });
            }}
            onClickRequest={(
              inputs: MainAudit_Personal_Input,
              ids: MainAudit_Personal_Input_Id | null,
            ) => {
              requestMainAuditPersonalO(loanId, inputs, ids, () => {
                navigate(
                  "/done/본심사 신청 완료/신청이 정상적으로 완료되었습니다.은행 검토 후 4주 이내에 담당자 이메일로 알람을 보내드리겠습니다.",
                );
              });
            }}
            onClickReject={null}
            onClickSuggestLoan={null}
            mainAudit_Personal_Inputs={undefined}
            mainAudit_Personal_Input_Id={undefined}
          />
        </CenterStyle>,
      );
    }
  }, []);
  return ui;
}
/**
 * 임시저장_개인
 * @param loanId
 * @param inputs
 * @param ids
 * @param gotoDone
 */
function tmpClicked(
  loanId: string,
  inputs: MainAudit_Personal_Input,
  ids: MainAudit_Personal_Input_Id | null,
  gotoDone: () => void,
) {
  let hasFile = Object.keys(inputs).find((k) => {
    if (inputs[k] !== null && typeof inputs[k] !== "boolean") {
      return true;
    }
  });
  if (hasFile) {
    showLoading(true);
    tmpStore_Personal(loanId, inputs, ids)
      .then(() => {
        gotoDone();
      })
      .catch(() => {
        showToast("오류가 발생했습니다.", 1000);
      })
      .finally(() => {
        showLoading(false);
      });
  } else {
    showToast("입력된 파일이 없습니다", 2000);
  }
}

function requestMainAuditPersonalO(
  loanId: string,
  inputs: MainAudit_Personal_Input,
  ids: MainAudit_Personal_Input_Id | null,
  gotoDone: () => void,
) {
  let isValid = true;
  const sameList = [
    "FIN_TRANS",
    "PROOF_TAX_PROP",
    "PROOF_TAX_PROP_BY",
    "LEASE",
    "RENT",
    "REGISTRATION",
  ];
  if (inputs.isSamePerson) {
    sameList.forEach((k: string) => {
      if (!inputs[k]) {
        isValid = false;
        return;
      }
    });
  } else {
    const hasNull = Object.keys(inputs).find((k) => {
      return inputs[k] === null;
    });
    if (hasNull) {
      isValid = false;
    }
  }

  if (!isValid) {
    showToast("모든 파일을 넣어주세요", 2000);
  } else {
    showLoading(true);
    requestMainAudit_Personal(loanId, inputs, ids)
      .then(() => {
        gotoDone();
      })
      .catch(() => {
        showToast("오류가 발생했습니다.", 2000);
      })
      .finally(() => {
        showLoading(false);
      });
  }
}

const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
