import styled from "styled-components";
import { colors } from "../../../../configs/colors";
import { CommonFloatingCard } from "../../../components/CommonFloatingCard";
import { fontFamily } from "../../../../configs/font";
import { CommonWhiteButton } from "../../../components/buttons/commonButtons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoanCardItem } from "../MyPage_Company/ManageLoanPage";
import {
  getAllLoans,
  getLoanInfoById,
  LoanRequestInfo,
  ServerLoanDetailFileInfo,
  ServerLoanDetailInfo,
} from "../../../../repository/loanApi";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import { APPLY_STATUS } from "../../../../repository/models";
import { parseDateForServer } from "../../../../utils/dateUtils";
import { isMainAuditPersonal } from "../../../components/MainAudit_Personal";

// export interface LoanCardItem {
//   loanId: string;
//   topLabelText: string;
//   contentText: string;
//   dateText: string;
//   enable: boolean;
//   isEnd: boolean;
//   onClick: () => void;
// }

// function getDummyLoadnItem(
//   isEnd1: boolean,
//   setNext: (loadId: string) => void,
// ): LoanCardItem {
//   const enable = Math.random() % 2 === 1;
//   const id = Math.random().toString();
//   return {
//     loanId: id,
//     topLabelText: "테스트",
//     contentText: "text",
//     dateText: "date TExt",
//     enable: enable,
//     isEnd: isEnd1,
//     onClick: () => {
//       setNext(id);
//     },
//   };
// }

export function ManageLoanTax() {
  const navigate = useNavigate();
  return (
    <TmpStyle>
      <ManageLoanPageMain
        setNext={(item: LoanCardItem) => {
          // todo -> 임시저장, 본심사 신청 페이지 분기처리 필요
          if (item.topLabelText === APPLY_STATUS.TEMP) {
            if (item.isPersonalTemp) {
              navigate("/applyService/Peronnal/" + item.loanId + "/true");
            } else {
              navigate("/applyService/Corperation/" + item.loanId + "/true");
            }
          } else {
            navigate("/applyService2/" + item.loanId);
          }
        }}
      />
    </TmpStyle>
  );
}
const TmpStyle = styled.div`
  width: 100%;
`;

function ManageLoanPageMain({
  setNext,
}: {
  setNext: (item: LoanCardItem) => void;
}) {
  const [inProgressLoans, setInProgressLoans] = useState<LoanCardItem[]>([]);
  const [inProgressLoansCardItem, setInProgressLoansCardItem] = useState<
    JSX.Element[]
  >([]);
  useEffect(() => {
    let index = 0;
    setInProgressLoansCardItem(
      inProgressLoans.map((item) => {
        return <CardItem key={index++} item={item}></CardItem>;
      }),
    );
  }, [inProgressLoans]);
  //   const [endLoans, setEndLoans] = useState<LoanCardItem[]>([]);
  //   const [endLoansCardItem, setEndLoansCardItem] = useState<JSX.Element[]>([]);
  //   useEffect(() => {
  //     let index = 0;
  //     setEndLoansCardItem(
  //       endLoans.map((item) => {
  //         return <CardItem key={index++} item={item}></CardItem>;
  //       }),
  //     );
  //   }, [endLoans]);

  // todo 실제 api 호출로 바꿀 것
  // const dummy = [
  //   getDummyLoadnItem(true, setNext),
  //   getDummyLoadnItem(true, setNext),
  //   getDummyLoadnItem(true, setNext),
  //   getDummyLoadnItem(true, setNext),
  //   getDummyLoadnItem(true, setNext),
  //   getDummyLoadnItem(true, setNext),
  //   getDummyLoadnItem(false, setNext),
  // ];
  useEffect(() => {
    showLoading(true);
    getAllLoans()
      .then((result: LoanRequestInfo[]) => {
        const cardItemPromises: Promise<LoanCardItem>[] = [];
        result.forEach((item: LoanRequestInfo) => {
          const statusKey = item.status;
          if (APPLY_STATUS[statusKey] === APPLY_STATUS.PREV_COMP) {
            // 세무사 페이지에서는 본심사 신청, 본심사 임시저장 재개만 가능
            const tmp = getLoanInfoById(item.loanRefReqId.toString()).then(
              (info: ServerLoanDetailInfo) => {
                const i = {
                  loanId: item.loanRefReqId.toString(),
                  topLabelText: statusKey ? APPLY_STATUS[statusKey] : "",
                  contentText: item.cpNm,
                  dateText:
                    item.reqDate.split("T")[0] +
                    "(" +
                    item.reqDate.split("T")[1].split(":")[0] +
                    " : " +
                    item.reqDate.split("T")[1].split(":")[1] +
                    ")",
                  enable: statusKey !== "EXP" && statusKey !== "TEMP",
                  isEnd: statusKey === "EXP",
                  isPersonalTemp: isMainAuditPersonal(
                    info.loanRefFiles.map((f: ServerLoanDetailFileInfo) => {
                      return f.fileType;
                    }),
                  ),
                  onClick: () => {
                    setNext(i);
                  },
                };
                if (info.loanRefFiles.length > 3) {
                  // 임시저장임
                  i.topLabelText = APPLY_STATUS.TEMP;
                  i.enable = false;
                  return i;
                }
                return i;
              },
            );
            cardItemPromises.push(tmp);
          }
          // if (tmp.isEnd) {
          //   // end.push(tmp);
          // } else if (statusKey === "PREV_COMP") {
          //   inProgress.push(tmp);
          // }
        });
        // setInProgressLoans(inProgress);
        // // setEndLoans(end);
        return cardItemPromises;
      })
      .then((promises: Promise<LoanCardItem>[]) => {
        return Promise.all(promises).then((item: LoanCardItem[]) => {
          setInProgressLoans(item);
        });
      })
      .catch((e) => {
        showToast("정보를 불러오는 중 오류가 발생했습니다.", 1000);
      })
      .finally(() => {
        showLoading(false);
      });
    // setEndLoans(dummy);
  }, []);
  return (
    <ParentStyle>
      {/* <TopCard /> */}
      <TitleText text="진행중인 대출비교" />
      <GridStyle>{inProgressLoansCardItem}</GridStyle>
      {/* <TitleText text="종료된 대출비교" />
      <GridStyle>{endLoansCardItem}</GridStyle> */}
    </ParentStyle>
  );
}

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

function TopCard() {
  const navigate = useNavigate();

  return (
    <CommonFloatingCard>
      <TopCardStyle>
        <TopCardTextStyle>제조 금융의 혁신 블루노멀</TopCardTextStyle>
        <TopCardTextStyle>10분안에 20개 은행 대출 비교 받기</TopCardTextStyle>
        <ButtonArea>
          <CommonWhiteButton
            text={"지금 바로 대출 비교 시작하기"}
            onclick={() => {
              navigate("/applyService");
            }}
            minWidth="100px"
          />
        </ButtonArea>
      </TopCardStyle>
    </CommonFloatingCard>
  );
}

const ButtonArea = styled.div`
  width: fit-content;
  margin: auto;
`;
const TopCardTextStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 22px;
  text-align: center;
`;

const TopCardStyle = styled.div`
  width: 800px;
  background-color: ${colors.background};
`;

const ParentStyle = styled.div`
  width: 800px;
  margin: auto;
`;

function TitleText({ text }: { text: string }) {
  return (
    <div>
      <TitleTextStyle>{text}</TitleTextStyle>
      <UnderLineStyle />
    </div>
  );
}

const UnderLineStyle = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.main};
`;
const TitleTextStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 24px;
`;

function CardItem({ item }: { item: LoanCardItem }) {
  let label = <LabelDisableStyle>{item.topLabelText}</LabelDisableStyle>;
  if (item.isEnd) {
    label = <LabelEndStyle>{item.topLabelText}</LabelEndStyle>;
  } else if (item.enable) {
    label = <LabelEnableStyle>{item.topLabelText}</LabelEnableStyle>;
  }
  const dText = item.dateText;
  return (
    <CommonFloatingCard>
      <div onClick={item.onClick}>
        <FixWidth>
          {label} <ContentStyle>{item.contentText}</ContentStyle>{" "}
          <ContentStyle>{dText}</ContentStyle>
        </FixWidth>
      </div>
    </CommonFloatingCard>
  );
}
const FixWidth = styled.div`
  width: 166px;
`;
const LabelEnableStyle = styled.p`
  width: fit-content;
  font-family: ${fontFamily.Medium};
  font-size: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: ${colors.main};
  border-radius: 2px;
  color: white;
`;
const LabelDisableStyle = styled.p`
  width: fit-content;
  font-family: ${fontFamily.Medium};
  font-size: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: ${colors.sub};
  border-radius: 2px;
  color: white;
`;
const LabelEndStyle = styled.p`
  width: fit-content;
  font-family: ${fontFamily.Medium};
  font-size: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: ${colors.label};
  border-radius: 2px;
  color: white;
`;
const ContentStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 12px;
`;
