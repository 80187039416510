import styled from "styled-components";
import {
  PreScreeningComponent,
  PreScreeningInput,
} from "../../components/PreScreening";
import { InputMode } from "../../../configs/inputMode";
import {
  PageGuideWithDes,
  PageTitleWithDes,
} from "../../components/PageInfoWithDes";
import { title } from "process";

/**
 * 사전심사
 * @returns
 */
export function ApplyService_Pre() {
  const ApplyServicePreDescription = `우선 블루노멀에서 사전심사를 통해 기업분류를 진행하며, 블루노멀 담당자가 배정됩니다.
사전심사 후 본 심사가 진행될 때 세부금리 산출을 위해 추가서류를 요청하게 됩니다`;

  const ApplyServicePreGuideList = [
    "대출 갈아타기(대환대출)을 희망하는 기존 이용중인 대출정보를 기입하여 주시기 바랍니다(예시 참조).",
    "작성 시 문의사항이 있으신 경우 블루노멀에 문의하여 주시기 바랍니다.",
  ];

  return (
    <CenterStyle>
      <PageTitleWithDes
        title={"사전심사"}
        description={ApplyServicePreDescription}
      />
      <PreScreeningComponent
        inputMode={InputMode.WRITE}
        onClickAccept={(inputs: PreScreeningInput) => {}}
        onClickReject={(inputs: PreScreeningInput) => {}}
        onClickSubmit={(inputs: PreScreeningInput) => {
          console.log("제출하기 클릭");
        }}
        onClickTmpSave={(inputs: PreScreeningInput) => {
          console.log("임시저장 클릭");
        }}
      />

      <PageGuideWithDes
        title={"사전심사"}
        GuideList={ApplyServicePreGuideList}
      />
    </CenterStyle>
  );
}

const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
