import { useState } from "react";
import { CommonFloatingCard } from "./CommonFloatingCard";
import AttachedPdfInput from "./inputs/AttachedPdfInput";
import styled from "styled-components";
import { colors } from "../../configs/colors";
import { InputMode } from "../../configs/inputMode";
import { CommonRedButton, CommonWhiteButton } from "./buttons/commonButtons";
import { requestMainAudit_Personal } from "../../repository/loanApi";
import { CommonSpacer } from "./CommonSpacer";
import { PageGuideWithDes, PageTitleWithDes } from "./PageInfoWithDes";
import { showToast } from "./baseComponents/BaseComponent";
import PolicyAgreement from "./PolicyAgreement";
import { FullViewURL } from "../../configs/url";

// const mainAduitPersonalFilesName: { [key: string]: string } = {
//   FIN_TRANS: "금융거래확인서",
//   PROOF_TAX_PROP: "국세 납세증명서",
//   PROOF_TAX_PROP_BY: "국세 납세사실증명원",
//   LEASE: "임대차계약서 사본",
//   RENT: "최근 6개월 임대료 입금내역",
//   REGISTRATION: "등기권리증(등기필증) 원본(공동명의자 포함)",
//   PROOF_TAX_PROP_P: "국세 납세증명서",
//   PROOF_TAX_PROP_BY_P: "국세 납세사실증명원",
//   LOCAL_TAX_P_1: "지방세 납세증명서 1",
//   LOCAL_TAX_P_2: "지방세 납세증명서 2",
//   LOCAL_TAX_P_3: "지방세 납세증명서 3",
//   E_LOCAL_TAX_P_1: "지방세 세목별 과제증명서 1",
//   E_LOCAL_TAX_P_2: "지방세 세목별 과제증명서 2",
//   E_LOCAL_TAX_P_3: "지방세 세목별 과제증명서 3",
// };
export function isMainAuditPersonal(fileTypes: string[]): boolean {
  const hasPersonalFile = fileTypes.find((str: string) => {
    const result =
      str === "FIN_TRANS" ||
      str === "PROOF_TAX_PROP" ||
      str === "PROOF_TAX_PROP_BY" ||
      str === "LEASE" ||
      str === "RENT" ||
      str === "REGISTRATION" ||
      str === "isSamePerson" ||
      str === "PROOF_TAX_PROP_P" ||
      str === "PROOF_TAX_PROP_BY_P" ||
      str === "LOCAL_TAX_P_1" ||
      str === "LOCAL_TAX_P_2" ||
      str === "LOCAL_TAX_P_3" ||
      str === "E_LOCAL_TAX_P_1" ||
      str === "E_LOCAL_TAX_P_2" ||
      str === "E_LOCAL_TAX_P_3";
    return result;
  });
  if (hasPersonalFile) {
    return true;
  } else {
    return false;
  }
}
export interface MainAudit_Personal_Input {
  FIN_TRANS: File | null;
  PROOF_TAX_PROP: File | null;
  PROOF_TAX_PROP_BY: File | null;
  LEASE: File | null;
  RENT: File | null;
  REGISTRATION: File | null;
  isSamePerson: boolean;
  PROOF_TAX_PROP_P: File | null;
  PROOF_TAX_PROP_BY_P: File | null;
  LOCAL_TAX_P_1: File | null;
  LOCAL_TAX_P_2: File | null;
  LOCAL_TAX_P_3: File | null;
  E_LOCAL_TAX_P_1: File | null;
  E_LOCAL_TAX_P_2: File | null;
  E_LOCAL_TAX_P_3: File | null;
  [prop: string]: any;
}
export interface MainAudit_Personal_Input_Id {
  FIN_TRANS: string | null;
  PROOF_TAX_PROP: string | null;
  PROOF_TAX_PROP_BY: string | null;
  LEASE: string | null;
  RENT: string | null;
  REGISTRATION: string | null;
  PROOF_TAX_PROP_P: string | null;
  PROOF_TAX_PROP_BY_P: string | null;
  LOCAL_TAX_P_1: string | null;
  LOCAL_TAX_P_2: string | null;
  LOCAL_TAX_P_3: string | null;
  E_LOCAL_TAX_P_1: string | null;
  E_LOCAL_TAX_P_2: string | null;
  E_LOCAL_TAX_P_3: string | null;
  [prop: string]: any;
}

/**
 * 본심사_개인
 * @param param0
 * @returns
 */
export function MainAudit_Personal({
  inputMode,
  onClickTmpSave,
  onClickRequest,
  onClickReject,
  onClickSuggestLoan,
  mainAudit_Personal_Inputs,
  mainAudit_Personal_Input_Id,
}: {
  inputMode: string;
  onClickTmpSave:
    | ((
        inputs: MainAudit_Personal_Input,
        ids: MainAudit_Personal_Input_Id | null,
      ) => void)
    | null;
  onClickRequest:
    | ((
        inputs: MainAudit_Personal_Input,
        ids: MainAudit_Personal_Input_Id | null,
      ) => void)
    | null;
  onClickReject: ((inputs: MainAudit_Personal_Input) => void) | null;
  onClickSuggestLoan: ((inputs: MainAudit_Personal_Input) => void) | null;
  mainAudit_Personal_Inputs?: MainAudit_Personal_Input;
  mainAudit_Personal_Input_Id?: MainAudit_Personal_Input_Id;
}) {
  const [checkedAgreement, setCheckedAgreement] = useState(false);
  const [inputs, setInputs] = useState<MainAudit_Personal_Input>(
    mainAudit_Personal_Inputs
      ? mainAudit_Personal_Inputs
      : {
          FIN_TRANS: null,
          PROOF_TAX_PROP: null,
          PROOF_TAX_PROP_BY: null,
          LEASE: null,
          RENT: null,
          REGISTRATION: null,
          isSamePerson: false,
          PROOF_TAX_PROP_P: null,
          PROOF_TAX_PROP_BY_P: null,
          LOCAL_TAX_P_1: null,
          LOCAL_TAX_P_2: null,
          LOCAL_TAX_P_3: null,
          E_LOCAL_TAX_P_1: null,
          E_LOCAL_TAX_P_2: null,
          E_LOCAL_TAX_P_3: null,
        },
  );
  const [same, setSame] = useState<boolean>(
    mainAudit_Personal_Inputs ? mainAudit_Personal_Inputs.isSamePerson : false,
  );

  const ApplyServiceMainDescription = `본 심사를 통해 귀사에서 국내 1금융권에서 받을 수 있는 최적의 조건을 연계하여 드립니다.
  블루노멀은 특정은행이 아닌 최적의 조건을 어떠한 편견도 없이 제시합니다.`;

  const ApplyServiceMainGuideList = [
    "대부분의 서류는 귀사에서 기장을 위탁하는 세무사 사무실을 통해 받으실 수 있으며, 세무사 사무실에 서류제출을 위탁하실 수 있습니다.",
    "작성 시 문의사항이 있으신 경우 블루노멀에 문의하여 주시기 바랍니다.",
  ];

  return (
    <MainAuditWrapper>
      {inputMode === InputMode.READ ? (
        ""
      ) : (
        <PageTitleWithDes
          title={"본심사"}
          description={ApplyServiceMainDescription}
        />
      )}
      <CommonFloatingCard>
        <ForWidth>
          <p>대출신청인 정보</p>
          <p>금융거래확인서</p>
          <AttachedPdfInput
            initialFile={inputs.FIN_TRANS ? inputs.FIN_TRANS : undefined}
            inputMode={inputMode}
            showPreview={false}
            onLoad={(file, src) => {
              inputs.FIN_TRANS = file;
            }}
          />
          <p>국세 납세증명서</p>{" "}
          <AttachedPdfInput
            initialFile={
              inputs.PROOF_TAX_PROP ? inputs.PROOF_TAX_PROP : undefined
            }
            inputMode={inputMode}
            showPreview={false}
            onLoad={(file, src) => {
              inputs.PROOF_TAX_PROP = file;
            }}
          />
          <p>국세 납세사실증명</p>{" "}
          <AttachedPdfInput
            initialFile={
              inputs.PROOF_TAX_PROP_BY ? inputs.PROOF_TAX_PROP_BY : undefined
            }
            inputMode={inputMode}
            showPreview={false}
            onLoad={(file, src) => {
              inputs.PROOF_TAX_PROP_BY = file;
            }}
          />
          <p>임대차계약서 사본</p>{" "}
          <AttachedPdfInput
            initialFile={inputs.LEASE ? inputs.LEASE : undefined}
            inputMode={inputMode}
            showPreview={false}
            onLoad={(file, src) => {
              inputs.LEASE = file;
            }}
          />
          <p>최근 6개월 임대료 입금내역</p>{" "}
          <AttachedPdfInput
            initialFile={inputs.RENT ? inputs.RENT : undefined}
            inputMode={inputMode}
            showPreview={false}
            onLoad={(file, src) => {
              inputs.RENT = file;
            }}
          />
          <p>등기권리증(등기필증)원본</p>{" "}
          <AttachedPdfInput
            initialFile={inputs.REGISTRATION ? inputs.REGISTRATION : undefined}
            inputMode={inputMode}
            showPreview={false}
            onLoad={(file, src) => {
              inputs.REGISTRATION = file;
            }}
          />
          <p>담보제공자 동일인 여부</p>
          <RowSt>
            <CheckAbleButtonWrapper>
              <CheckAbleButton
                style={{
                  backgroundColor: same ? colors.main : colors.placeholder,
                }}
                onClick={() => {
                  if (inputMode !== InputMode.READ) {
                    inputs.isSamePerson = true;
                    setSame(true);
                  }
                }}
              />
              대출 신청인과 동일
            </CheckAbleButtonWrapper>

            <CheckAbleButtonWrapper>
              <CheckAbleButton
                style={{
                  backgroundColor: !same ? colors.main : colors.placeholder,
                }}
                onClick={() => {
                  if (inputMode !== InputMode.READ) {
                    inputs.isSamePerson = false;
                    setSame(false);
                  }
                }}
              />
              대출 신청인과 다름
            </CheckAbleButtonWrapper>
          </RowSt>
          {!same ? (
            <div>
              <p>국세 납세증명서</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.PROOF_TAX_PROP_P ? inputs.PROOF_TAX_PROP_P : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.PROOF_TAX_PROP_P = file;
                }}
              />
              <p>국세 납입 사실증명원</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.PROOF_TAX_PROP_BY_P
                    ? inputs.PROOF_TAX_PROP_BY_P
                    : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.PROOF_TAX_PROP_BY_P = file;
                }}
              />
              <p>지방세 납입증명서1</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.LOCAL_TAX_P_1 ? inputs.LOCAL_TAX_P_1 : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.LOCAL_TAX_P_1 = file;
                }}
              />
              <p>지방세 납입증명서2</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.LOCAL_TAX_P_2 ? inputs.LOCAL_TAX_P_2 : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.LOCAL_TAX_P_2 = file;
                }}
              />
              <p>지방세 납입증명서3</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.LOCAL_TAX_P_3 ? inputs.LOCAL_TAX_P_3 : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.LOCAL_TAX_P_3 = file;
                }}
              />
              <p>지방세 세목별 과세증명서1</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.E_LOCAL_TAX_P_1 ? inputs.E_LOCAL_TAX_P_1 : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.E_LOCAL_TAX_P_1 = file;
                }}
              />
              <p>지방세 세목별 과세증명서2</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.E_LOCAL_TAX_P_2 ? inputs.E_LOCAL_TAX_P_2 : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.E_LOCAL_TAX_P_2 = file;
                }}
              />
              <p>지방세 세목별 과세증명서3</p>{" "}
              <AttachedPdfInput
                initialFile={
                  inputs.E_LOCAL_TAX_P_3 ? inputs.E_LOCAL_TAX_P_3 : undefined
                }
                inputMode={inputMode}
                showPreview={false}
                onLoad={(file, src) => {
                  inputs.E_LOCAL_TAX_P_3 = file;
                }}
              />{" "}
            </div>
          ) : (
            ""
          )}
          {inputMode === InputMode.WRITE || inputMode === InputMode.RW ? (
            <div>
              <PolicyAgreement
                checked={checkedAgreement}
                onChange={setCheckedAgreement}
                url={FullViewURL}
              />

              <CenterStyle>
                <CommonSpacer />
                <CommonSpacer />
                <RowSt>
                  <CommonWhiteButton
                    text="임시 저장"
                    onclick={() => {
                      if (onClickTmpSave) {
                        onClickTmpSave(
                          inputs,
                          mainAudit_Personal_Input_Id
                            ? mainAudit_Personal_Input_Id
                            : null,
                        );
                      }
                    }}
                    minWidth="100px"
                  />

                  <CommonWhiteButton
                    text="본심사 신청하기"
                    onclick={() => {
                      if (!checkedAgreement) {
                        showToast("약관 동의를 확인해주세요", 3000);
                        return;
                      }

                      if (onClickRequest) {
                        onClickRequest(
                          inputs,
                          mainAudit_Personal_Input_Id
                            ? mainAudit_Personal_Input_Id
                            : null,
                        );
                      }
                    }}
                    minWidth="100px"
                  />
                </RowSt>
              </CenterStyle>
            </div>
          ) : (
            ""
          )}
          {inputMode === InputMode.READ ? (
            <CenterStyle>
              <CommonSpacer />
              <CommonSpacer />
              <CenterStyle2>
                <CommonRedButton
                  text="반려하기"
                  onclick={() => {
                    if (onClickReject) {
                      onClickReject(inputs);
                    }
                  }}
                  minWidth="100px"
                />
                <CommonWhiteButton
                  text="대출 정보 입력하기"
                  onclick={() => {
                    if (onClickSuggestLoan) {
                      onClickSuggestLoan(inputs);
                    }
                  }}
                  minWidth="100px"
                />
              </CenterStyle2>
            </CenterStyle>
          ) : (
            ""
          )}
        </ForWidth>
      </CommonFloatingCard>

      {inputMode === InputMode.READ ? (
        ""
      ) : (
        <PageGuideWithDes
          title={"본심사"}
          GuideList={ApplyServiceMainGuideList}
        />
      )}
    </MainAuditWrapper>
  );
}
const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CenterStyle2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ForWidth = styled.div`
  width: 800px;
`;
const RowSt = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
const CheckAbleButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: ${(props) =>
    props.style?.backgroundColor
      ? props.style?.backgroundColor
      : colors.placeholder};

  border-color: #c9cfd7;
  border-style: solid;
  margin-right: 10px;
`;

const CheckAbleButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
`;

const MainAuditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
