import styled from "styled-components";
import { fontFamily } from "../../../configs/font";
import { colors } from "../../../configs/colors";
import {
  CommonFloatingCard,
  CommonFloatingWhiteCard,
} from "../CommonFloatingCard";

export interface SideBarItem {
  text: string;
  imgSrc: string;
  imgSrc_Selected: string;
  isSelected: boolean;
  onClick: () => void;
}
export function SideBar({ items }: { items: SideBarItem[] }) {
  const buttons = items.map((item) => {
    return <SideBarButton key={item.text} item={item} />;
  });
  return <CommonFloatingWhiteCard>{buttons}</CommonFloatingWhiteCard>;
}

function SideBarButton({ item }: { item: SideBarItem }) {
  let color = colors.label;
  let src = item.imgSrc;
  if (item.isSelected) {
    color = colors.main;
    src = item.imgSrc_Selected;
  }
  return (
    <RowStyle onClick={item.onClick}>
      <ImgStyle src={src} />
      <LabelStyle color={color}>{item.text}</LabelStyle>
    </RowStyle>
  );
}

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
const ImgStyle = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 5px;
`;
const LabelStyle = styled.p`
  font-family: ${fontFamily.Medium};
  color: ${(props) => (props.color ? props.color : colors.label)};
`;
