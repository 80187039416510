import { RegisterCompanyInputs } from "../views/pages/Register/RegisterCompanyPage";
import { callWithJwt, callWithOutJwt } from "./baseApi";
import { COMAPNY_TYPE, COMPANY_SIZE } from "./models";

export function RegisterCompanyApi(
  inputs: RegisterCompanyInputs,
  onSuccess: () => void,
): Promise<any> {
  const cpType = Object.keys(COMAPNY_TYPE).find((k) => {
    if (COMAPNY_TYPE[k] === inputs.companyType) {
      return k;
    }
  });
  const cpScale = Object.keys(COMPANY_SIZE).find((k) => {
    if (COMPANY_SIZE[k] === inputs.salesVolum) {
      return k;
    }
  });
  const body = {
    userId: inputs.id,
    userPw: inputs.pw,
    cpNm: inputs.companyName,
    cpType: cpType,
    cpAddress: inputs.companyPlace,
    cpScale: cpScale,
    ceoNm: inputs.ceoNm ? inputs.ceoNm : "기업 대표자명 입력받지 않음",
    adminDept: inputs.agnetDepartment,
    adminNm: inputs.agentName,
    adminEmail: inputs.agentEmail,
    adminPhone: inputs.agentPhoneNumber,
    businessLicense: inputs.businessRegisterFile,
    sendAd: "Y",
  };
  return callWithOutJwt("/api/user/join/company", "post", JSON.stringify(body));

  //   {
  //     "logKey": "408f92eb-869b-4009-97e3-ed69c2cf64a0",
  //     "data": {
  //         "code": "TX001",
  //         "message": "INSERT COMPLETE",
  //         "count": 1,
  //         "data": null,
  //         "totalCount": null
  //     },
  //     "serverDt": "2024-09-28 20:03"
  // }
}

export function checkDuplicateId(id: string): Promise<boolean> {
  const url = "/api/user/validate?userId=" + id;
  const method = "get";
  return callWithOutJwt(url, method, null).then((json) => {
    return json["data"]["data"];
  });
}
