import { IndexPage } from "./views/pages/Index";
import {
  PageType,
  RegisterCompanyComponent,
} from "./views/pages/Register/RegisterCompanyPage";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { BaseComponent } from "./views/components/baseComponents/BaseComponent";
import { MyPage } from "./views/pages/MyPages/MyPage";
import { LogoutPage } from "./views/pages/LogoutPage";
import { LoginPage } from "./views/pages/LoginPage";
import { IntroduceBluenormalPage } from "./views/pages/IntroduceBluenormalPage";
import { IntroduceServicePage } from "./views/pages/IntroduceServicePage";
import { ApplyServicePageIndex } from "./views/pages/ApplyPages/ApplyServicePageIndex";
import { ContactPage } from "./views/pages/ContactPage";
import { UsePage } from "./views/pages/UsePage";
import { DonePage } from "./views/pages/DonePage";
import { ApplyService_Personal } from "./views/pages/ApplyPages/ApplyService_Personal";
import { ApplyService_Corperation } from "./views/pages/ApplyPages/ApplyService_Corperation";
import { createGlobalStyle } from "styled-components";
import { ForgotPassWordPage } from "./views/pages/ForgotPassWordPage";
import { ApplyService_Pre } from "./views/pages/ApplyPages/ApplyService_Pre";
import { ApplyServicePageIndex2 } from "./views/pages/ApplyPages/ApplyServicePageIndex2";

function App() {
  return (
    <BrowserRouter>
      <BaseComponent>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route
            path="/registerCompany"
            element={
              <RegisterCompanyComponent
                pageType={PageType.register}
                deleteMember={null}
              />
            }
          />
          <Route path="/myPage" element={<MyPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/login" element={<LoginPage />} />
          {/* <Route
            path="/introduceBluenormal"
            element={<IntroduceBluenormalPage />}
          /> */}
          {/* <Route path="/introduceService" element={<IntroduceServicePage />} /> */}
          <Route path="/applyService" element={<ApplyServicePageIndex />} />
          <Route
            path="/applyService2/:loanId"
            element={<ApplyServicePageIndex2 />}
          />

          <Route
            path="/applyService/Corperation/:loanId/:tmpMode"
            element={<ApplyService_Corperation />}
          />
          <Route
            path="/applyService/Peronnal/:loanId/:tmpMode"
            element={<ApplyService_Personal />}
          />
          <Route path="/applyService/Pre" element={<ApplyService_Pre />} />
          {/* <Route path="/contact" element={<ContactPage />} /> */}
          {/* <Route path="/use" element={<UsePage />} /> */}
          <Route path="/done/:title/:description" element={<DonePage />} />

          {/* 비밀번호 찾기 페이지  */}
          <Route path="/forgotPassWord" element={<ForgotPassWordPage />} />
        </Routes>
      </BaseComponent>
    </BrowserRouter>
  );
}

export default App;
