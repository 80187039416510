import { useEffect, useState } from "react";
import {
  CommonFloatingCard,
  CommonFloatingWhiteCard,
} from "../../../components/CommonFloatingCard";
import styled from "styled-components";
import { fontFamily } from "../../../../configs/font";
import {
  CommonRedButton,
  CommonWhiteButton,
} from "../../../components/buttons/commonButtons";
import { colors } from "../../../../configs/colors";
import {
  CommonDropDown,
  CommonDropDownItem,
} from "../../../components/CommonDropDown";
import { APPLY_STATUS, COMAPNY_TYPE } from "../../../../repository/models";
import {
  PreScreeningComponent,
  PreScreeningInput,
} from "../../../components/PreScreening";
import {
  MainAudit_Corperation,
  MainAudit_Corperation_Input,
} from "../../../components/MainAudit_Corperation";
import {
  MainAudit_Personal,
  MainAudit_Personal_Input,
} from "../../../components/MainAudit_Personal";
import { SuggestComponent } from "./SuggestComponent";
import {
  getMainScreeningCorperation,
  getMainScreeningPersonal,
} from "../../../../repository/screeningApi";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import { InputMode } from "../../../../configs/inputMode";
import TitleWithLine from "../../../components/TitleWithLine";
import {
  getAllLoans,
  getConvertedLoanByID_PreScreening,
  getLoanInfoById,
  LoanRequestInfo,
  rejectMainAudit,
  ServerLoanDetailFileInfo,
  ServerLoanDetailInfo,
} from "../../../../repository/loanApi";
import { parseDateForServer } from "../../../../utils/dateUtils";
import { CommonSpacer } from "../../../components/CommonSpacer";
import { CommonInput } from "../../../components/inputs/CommonInput";
import {
  getMainScreeningCorperationAndPre,
  getMainScreeningPersonalAndPre,
} from "../../../../repository/screeningApiBank";

const STEP = {
  list: "list",
  auditCorperation: "auditCorperation",
  auditPersonal: "auditPersonal",
  reject: "reject",
  suggest: "suggest",
};
interface LoanInfo {
  loanRequestId: string;
}
interface LoanResultInfo {
  company: string;
  state: string;
  date: Date;
  id: string;
  companyType: string;
}
const SortValue = {
  date: "최신순",
  dateOld: "느린순",
};
function getSortedList(
  list: LoanResultInfo[],
  sortValue: string,
): LoanResultInfo[] {
  let result: LoanResultInfo[] = list;
  switch (sortValue) {
    case SortValue.date:
      result = result.sort(function (a: LoanResultInfo, b: LoanResultInfo) {
        return b.date.getTime() - a.date.getTime();
      });
      break;
    case SortValue.dateOld:
      result = result.sort(function (a: LoanResultInfo, b: LoanResultInfo) {
        return a.date.getTime() - b.date.getTime();
      });
      break;
  }
  return list;
}

export function LoanResultListComponent_Bank({
  loanInfo,
}: {
  loanInfo: LoanInfo;
}) {
  const [step, setStep] = useState<string>(STEP.list);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [loanType, setLoanType] = useState<string>("");
  useEffect(() => {
    if (step === STEP.list) {
      setContent(
        <LoanResultListComponentList
          loanInfo={loanInfo}
          onClickItem={(item: LoanResultInfo) => {
            showLoading(true);
            setSelectedId(item.id);
            getLoanInfoById(item.id)
              .then((info: ServerLoanDetailInfo) => {
                // 신청한 대출 종류를 알 수 없어, 개인 대출 필수 심사 파일을 보유하고 있는지에 대한 여부로 판단함
                const hasPersonalFile = info.loanRefFiles.find(
                  (f: ServerLoanDetailFileInfo) => {
                    if (f.fileType === "REGISTRATION") {
                      return f;
                    }
                  },
                );
                if (hasPersonalFile) {
                  // 개인 사업자
                  setStep(STEP.auditPersonal);
                } else {
                  // 법인 사업자
                  setStep(STEP.auditCorperation);
                }
              })
              .catch(() => {})
              .finally(() => {
                showLoading(false);
              });
          }}
        />,
      );
    } else if (step === STEP.auditCorperation) {
      showLoading(true);
      getMainScreeningCorperationAndPre(selectedId)
        .then(
          (r: {
            pre: PreScreeningInput;
            mainCor: MainAudit_Corperation_Input;
          }) => {
            const preScreenginInput = r.pre;
            const result = r.mainCor;
            setLoanType(preScreenginInput.loanType);
            setContent(
              <CardLayoutStyle>
                <PreScreeningComponent
                  inputMode={InputMode.READ}
                  onClickAccept={() => {}}
                  onClickReject={() => {}}
                  onClickSubmit={() => {}}
                  onClickTmpSave={() => {}}
                  preScreeningInput={preScreenginInput}
                  preventBtns={true}
                />
                <CommonSpacer />
                <CommonSpacer />
                <MainAudit_Corperation
                  inputMode={InputMode.READ}
                  onClickTmpSave={null}
                  onClickRequest={null}
                  onClickReject={(inputs: MainAudit_Corperation_Input) => {
                    setStep(STEP.reject);
                  }}
                  onClickSuggestLoan={(inputs: MainAudit_Corperation_Input) => {
                    setStep(STEP.suggest);
                  }}
                  mainAudit_Corperation_Inputs={result}
                />
              </CardLayoutStyle>,
            );
          },
        )
        .catch((e) => {
          showToast("정보를 불러오는 중 오류가 발생했습니다.", 1000);
        })
        .finally(() => {
          showLoading(false);
        });
    } else if (step === STEP.auditPersonal) {
      showLoading(true);
      getMainScreeningPersonalAndPre(selectedId)
        .then(
          (r: {
            pre: PreScreeningInput;
            mainPer: MainAudit_Personal_Input;
          }) => {
            const preScreenginInput = r.pre;
            const result = r.mainPer;
            setLoanType(preScreenginInput.loanType);
            setContent(
              <CardLayoutStyle>
                <PreScreeningComponent
                  inputMode={InputMode.READ}
                  onClickAccept={() => {}}
                  onClickReject={() => {}}
                  onClickSubmit={() => {}}
                  onClickTmpSave={() => {}}
                  preScreeningInput={preScreenginInput}
                  preventBtns={true}
                />
                <CommonSpacer />
                <CommonSpacer />
                <MainAudit_Personal
                  inputMode={InputMode.READ}
                  onClickTmpSave={null}
                  onClickRequest={null}
                  onClickReject={(inputs: MainAudit_Personal_Input) => {
                    setStep(STEP.reject);
                  }}
                  onClickSuggestLoan={(inputs: MainAudit_Personal_Input) => {
                    setStep(STEP.suggest);
                  }}
                  mainAudit_Personal_Inputs={result}
                />
              </CardLayoutStyle>,
            );
          },
        )
        .catch((e) => {
          showToast("정보를 불러오는 중 오류가 발생했습니다.", 1000);
        })
        .finally(() => {
          showLoading(false);
        });
    } else if (step === STEP.suggest) {
      setContent(
        <SuggestComponent
          loanId={selectedId}
          loanType={loanType}
          gotoPrev={() => {
            setStep(STEP.list);
          }}
        />,
      );
    } else if (step === STEP.reject) {
      setContent(
        <ScreeningReject
          id={selectedId}
          onClickPre={() => {
            setStep(STEP.list);
          }}
        />,
      );
    }
  }, [step]);
  return (
    <div>
      <TitleWithLine title="서비스 데이터 관리" />
      <div>{content ? content : ""}</div>
    </div>
  );
}

// interface LoanDetailInfo {
//   bank: string;
//   type: string;
//   purpose: string;
//   rate: string;
//   max: string;
//   note: string;
// }
// function LoanResultListComponentDetail({ selectedId }: { selectedId: string }) {
//   const info: LoanDetailInfo = {
//     bank: "은행이름",
//     type: "대환",
//     purpose: "대출 변경",
//     rate: "10%",
//     max: "10000000원",
//     note: "담당자의 말",
//   };
//   return (
//     <ParentStyle>
//       <div>
//         <TitleStyle>대환대출 결과 확인하기</TitleStyle>
//         <UnderLine />
//         <TitleStyle>은행 명</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.bank}</TextStyle>

//         <TitleStyle>대출 종류</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.type}</TextStyle>

//         <TitleStyle>대출 목적</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.purpose}</TextStyle>

//         <TitleStyle>대출 금리</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.rate}</TextStyle>

//         <TitleStyle>대출 한도</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.max}</TextStyle>

//         <TitleStyle>담당자 한말씀</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.note}</TextStyle>
//       </div>
//     </ParentStyle>
//   );
// }

function LoanResultListComponentList({
  loanInfo,
  onClickItem,
}: {
  loanInfo: LoanInfo;
  onClickItem: (item: LoanResultInfo) => void;
}) {
  const [loanResults, setLoanResults] = useState<LoanResultInfo[]>([]);
  const [contentUi, setContentUi] = useState<JSX.Element[]>([]);
  const [sort, setSort] = useState<string>(SortValue.date);
  const dropDownItems: CommonDropDownItem[] = [
    {
      text: SortValue.date,
      onClick: () => {
        setSort(SortValue.date);
      },
    },
    {
      text: SortValue.dateOld,
      onClick: () => {
        setSort(SortValue.dateOld);
      },
    },
  ];
  useEffect(() => {
    let uiList: JSX.Element[] = [];
    const sorted = getSortedList(loanResults, sort);
    sorted.forEach((item) => {
      uiList.push(
        <LoanResultItemComponent
          item={item}
          key={item.id}
          onClick={() => {
            onClickItem(item);
          }}
        />,
      );
    });
    setContentUi(uiList);
  }, [loanResults, sort]);
  useEffect(() => {
    showLoading(true);
    getAllLoans()
      .then((result: LoanRequestInfo[]) => {
        const list: LoanResultInfo[] = [];
        result.forEach((r: LoanRequestInfo) => {
          const tmp = {
            company: r.cpNm,
            state: APPLY_STATUS[r.status],
            date: new Date(parseDateForServer(r.reqDate)),
            id: r.loanRefReqId.toString(),
            companyType: COMAPNY_TYPE.PRI,
          };
          if (tmp.state === APPLY_STATUS.MAIN_PROG) {
            list.push(tmp);
          }
        });
        setLoanResults(list);
      })
      .finally(() => {
        showLoading(false);
      });
  }, []);
  return (
    <ParentStyle>
      <div>
        <LeftStyle>
          <CommonDropDown
            inputMode={InputMode.WRITE}
            text={sort}
            items={dropDownItems}
            width="fit-content"
            extraZIndex={0}
          />
        </LeftStyle>
        {contentUi}
      </div>
    </ParentStyle>
  );
}
const LeftStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
`;

const TitleStyle = styled.div`
  font-family: ${fontFamily.Medium};
  font-size: 24px;
`;
const UnderLine = styled.div`
  width: 800px;
  background-color: ${colors.main};
  height: 2px;
`;

const ParentStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function LoanResultItemComponent({
  item,
  onClick,
}: {
  item: LoanResultInfo;
  onClick: () => void;
}) {
  return (
    <CommonFloatingWhiteCard>
      <ItemPatentStyle>
        <TextStyle>{item.company}</TextStyle>
        <TextStyle>{item.state}</TextStyle>
        <CommonWhiteButton
          text="확인하기"
          minWidth="80px"
          onclick={() => {
            onClick();
          }}
        />
      </ItemPatentStyle>
    </CommonFloatingWhiteCard>
  );
}

const ItemPatentStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
`;

const TextStyle = styled.p`
  display: flex;
  align-self: center;
  margin: 0px;

  font-family: ${fontFamily.Regular};
  font-size: 20px;
  width: 30%;
  word-break: break-all;
`;

function ScreeningReject({
  id,
  onClickPre,
}: {
  id: string;
  onClickPre: () => void;
}) {
  const [reason, setReason] = useState<string>("");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Wrapper>
        <CommonFloatingCard>
          <p>반려 사유</p>
          <CommonInput
            inputMode={InputMode.WRITE}
            title="반려 사유 입력하기"
            placeHold={null}
            description={null}
            width="500px"
            onTextChanged={(s: string) => {
              setReason(s);
            }}
            setClearInput={() => {}}
          />

          <ButtonWrapper>
            <RowStyle>
              <CommonWhiteButton
                text="이전으로"
                onclick={() => {
                  onClickPre();
                }}
                minWidth="100px"
              />
              <CommonRedButton
                text="반려하기"
                onclick={() => {
                  showLoading(true);
                  rejectMainAudit(id, reason)
                    .then(() => {})
                    .catch(() => {})
                    .finally(() => {
                      showLoading(false);
                      onClickPre();
                    });
                }}
                minWidth="100px"
              />
            </RowStyle>
          </ButtonWrapper>
        </CommonFloatingCard>
      </Wrapper>
    </>
  );
}

const RowStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 90px;
  padding-bottom: 200px;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
`;

const CardLayoutStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
