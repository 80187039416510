import styled from "styled-components";
import { CommonFloatingCard } from "../../components/CommonFloatingCard";
import { fontFamily } from "../../../configs/font";
import { colors } from "../../../configs/colors";
import { useEffect, useState } from "react";
import { getCompanyAgentInfo_myPage } from "../../../repository/agentApi";
import {
  showLoading,
  showToast,
} from "../../components/baseComponents/BaseComponent";

interface ContentItem {
  title: string;
  content: string;
}
export interface CompanyInfos {
  companyName: string;
  companyType: string;
  companyPlace: string;
}
export function ManageCompanyPage() {
  const [contentItems, setContentItems] = useState<ContentItem[]>([]);
  const [ui, setUi] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const list: JSX.Element[] = [];
    contentItems.forEach((item: ContentItem) => {
      list.push(
        <Content title={item.title} content={item.content} key={list.length} />,
      );
    });
    setUi(list);
  }, [contentItems]);
  useEffect(() => {
    showLoading(true);
    getCompanyAgentInfo_myPage()
      .then((result: CompanyInfos) => {
        setContentItems([
          { title: "기업 명", content: result.companyName },
          { title: "회사 유형", content: result.companyType },
          { title: "사업장 주소지", content: result.companyPlace },
        ]);
      })
      .catch((e) => {
        showToast("오류가 발생했습니다.", 2000);
      })
      .finally(() => {
        showLoading(false);
      });
  }, []);
  return (
    <div>
      <Spacer />
      <Spacer />
      <Spacer />
      <Spacer />
      <CommonFloatingCard>
        <ParentStyle>
          <BigTitleStyle>마이페이지</BigTitleStyle>
          {ui}
        </ParentStyle>
      </CommonFloatingCard>
    </div>
  );
}
const Spacer = styled.div`
  height: 20px;
`;

const ParentStyle = styled.div`
  width: 800px;
  padding-left: 8px;
  padding-right: 8px;
`;

const BigTitleStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 25px;
`;

function Content({ title, content }: { title: string; content: string }) {
  return (
    <div>
      <SubTitleStyle>{title}</SubTitleStyle>
      <UnderLineStyle />
      <ContentStyle>{content}</ContentStyle>
      <Spacer />
    </div>
  );
}
const SubTitleStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 20px;
`;
const UnderLineStyle = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.main};
`;
const ContentStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 16px;
`;
