import { useEffect, useState } from "react";
import { CommonDropDownItem } from "./CommonDropDown";
import { styled } from "styled-components";
import { colors } from "../../configs/colors";

export interface CommonTabItem {
  text: string;
  onClick: () => void;
}

export default function CommonTab({
  items,
  clickedTabIdx,
}: {
  items: CommonTabItem[];
  clickedTabIdx: number;
}) {
  return (
    <TabWrapper>
      {items.map((elem, key) => {
        return (
          <Tab
            key={key}
            onClick={elem.onClick}
            className={clickedTabIdx === key ? `clickedTab` : ``}
          >
            {elem.text}
          </Tab>
        );
      })}
    </TabWrapper>
  );
}

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 20px;

  margin-bottom: -10px;
`;
const Tab = styled.div`
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  background-color: ${colors.sub};
  color: ${colors.white};
  min-width: 80px;
  max-width: max-content;
  padding: 0px 10px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  size: 8px;

  &.clickedTab {
    background-color: ${colors.main};
  }
`;
