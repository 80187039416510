import styled from "styled-components";
import { colors } from "../../../configs/colors";
import { fontFamily } from "../../../configs/font";

export function CommonWhiteButton({
  text,
  onclick,
  minWidth,
}: {
  text: string;
  onclick: () => void;
  minWidth: string;
}) {
  return (
    <WhiteButtonStyle onClick={onclick} style={{ minWidth: minWidth }}>
      {text}
    </WhiteButtonStyle>
  );
}

const WhiteButtonStyle = styled.div`
  height: 40px;
  width: fit-content;
  min-width: ${(props) =>
    props?.style?.minWidth ? props?.style?.minWidth : "50px"};
  align-content: center;
  align-self: center;
  text-align: center;
  border: 1px solid ${colors.main};
  border-radius: 15px;
  background-color: ${colors.white};
  align-items: center;
  font-family: ${fontFamily.Bold};
  color: ${colors.main};
  padding-left: 7px;
  padding-right: 7px;
  &:hover {
    cursor: pointer;
  }
`;

export function CommonBlueButton({
  text,
  onclick,
  minWidth,
}: {
  text: string;
  onclick: () => void;
  minWidth: string;
}) {
  return (
    <BlueButtonStyle onClick={onclick} style={{ minWidth: minWidth }}>
      {text}
    </BlueButtonStyle>
  );
}

const BlueButtonStyle = styled.div`
  height: 40px;
  align-content: center;
  text-align: center;
  width: fit-content;
  min-width: ${(props) =>
    props.style?.minWidth ? props.style?.minWidth : "50px"};
  border: 16px;
  border-radius: 15px;
  background-color: ${colors.main};
  font-family: ${fontFamily.Regular};
  color: ${colors.white};
  &:hover {
    cursor: pointer;
  }
  padding-left: 8px;
  padding-right: 8px;
`;

export function CommonLabelButton({
  text,
  onclick,
}: {
  text: string;
  onclick: () => void;
}) {
  return <LabelButtonStyle onClick={onclick}>{text}</LabelButtonStyle>;
}

const LabelButtonStyle = styled.div`
  height: 80px;
  align-content: center;
  text-align: center;
  width: 100px;
  align-items: center;
  font-family: ${fontFamily.Bold};
  &:hover {
    cursor: pointer;
  }
`;

export function CommonRedButton({
  text,
  onclick,
  minWidth,
}: {
  text: string;
  onclick: () => void;
  minWidth: string;
}) {
  return (
    <RedButtonStyle onClick={onclick} style={{ minWidth: minWidth }}>
      {text}
    </RedButtonStyle>
  );
}

const RedButtonStyle = styled.div`
  height: 40px;
  align-content: center;
  text-align: center;
  width: fit-content;
  min-width: ${(props) =>
    props.style?.minWidth ? props.style?.minWidth : "50px"};
  border: 16px;
  border-radius: 15px;
  background-color: ${colors.error};
  font-family: ${fontFamily.Regular};
  color: ${colors.white};
  &:hover {
    cursor: pointer;
  }
  padding-left: 8px;
  padding-right: 8px;
`;

/**
 * 로그인 페이지 회원가입/ 비밀번호 찾기 버튼
 * 텍스트만 있는 버튼
 * @param param0
 * @returns
 */
export function CommonOnlyTextButton({
  text,
  onclick,
}: {
  text: string;
  onclick: () => void;
}) {
  return <OnlyTextButtonStyle onClick={onclick}>{text}</OnlyTextButtonStyle>;
}

const OnlyTextButtonStyle = styled.div`
  height: 40px;
  align-content: center;
  text-align: center;
  width: fit-content;

  border: 16px;
  border-radius: 15px;
  font-family: ${fontFamily.Regular};
  color: black;
  &:hover {
    cursor: pointer;
  }
  padding-left: 8px;
  padding-right: 8px;
`;

export function CommonBlackButton({
  text,
  onclick,
  minWidth,
}: {
  text: string;
  onclick: () => void;
  minWidth: string;
}) {
  return (
    <BlackButtonStyle onClick={onclick} style={{ minWidth: minWidth }}>
      {text}
    </BlackButtonStyle>
  );
}

const BlackButtonStyle = styled(WhiteButtonStyle)`
  color: black;
  border-color: black;
`;
