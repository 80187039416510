import React, { useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { RegisterCompanyInputs } from "../../pages/Register/RegisterCompanyPage";
import { CommonBlueButton } from "../buttons/commonButtons";
import styled from "styled-components";

export default function SearchAddressInput({
  onFullAddressChanged,
}: {
  onFullAddressChanged: (addr: string) => void;
}) {
  const scriptUrl =
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(scriptUrl);

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    onFullAddressChanged(fullAddress);
    console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <Wrapper>
      <CommonBlueButton
        text="주소 찾기"
        onclick={handleClick}
        minWidth={"100px"}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-self: center;
  margin: 10px;
`;
