import styled from "styled-components";
import { colors } from "../../configs/colors";
import mainPageImg from "../../assets/mainPage.png";

import mainImg000 from "../../assets/mainImages/mainImg000.png";
import mainImg001 from "../../assets/mainImages/mainImg001.png";
import mainImg002 from "../../assets/mainImages/mainImg002.png";
import mainImg003 from "../../assets/mainImages/mainImg003.png";
import { CommonBlueButton } from "../components/buttons/commonButtons";
import { useNavigate } from "react-router-dom";

export function IndexPage() {
  const mainImgArr = [mainImg000, mainImg001, mainImg002, mainImg003];
  return (
    <>
      {mainImgArr.map((imgSrc, key) => {
        if (imgSrc === mainImg000) {
          return <MainImageComponentWithBtn src={imgSrc} key={key} />;
        }
        return <MainImageComponent src={imgSrc} key={key} />;
      })}
    </>
  );
}

function MainImageComponent({ src }: { src: string }) {
  return <FullImageStyle src={src} />;
}

function MainImageComponentWithBtn({ src }: { src: string }) {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <FullImageStyle src={src} />

      <ButtonWrapper>
        <CommonBlueButton
          text="서비스 신청하기"
          onclick={() => {
            navigate("/applyService");
          }}
          minWidth="fit-content"
        />
      </ButtonWrapper>
    </Wrapper>
  );
}

const FullImageStyle = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;
const Wrapper = styled.div`
  position: relative;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  width: fit-content;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const TestStyle = styled.div`
  height: 2000px;
  background-color: ${colors.error};
`;
