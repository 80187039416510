import { url } from "inspector";
import { getLoginedUser } from "../configs/user";
import { CompanyInfos } from "../views/pages/MyPages/ManageCompanyPage";
import {
  ManageBankAgentAtPopUP,
  ManageBankAgentInfoInputs,
} from "../views/pages/MyPages/MyPage_Bank/ManageAgent_Bank";
import { ManageCompanyAgentInfo } from "../views/pages/MyPages/MyPage_Company/ManageAgentPage";
import { ManageTaxAgentInfo } from "../views/pages/MyPages/MyPage_Company/ManageTaxPage";
import { ManageTaxAgnetInfoSelf } from "../views/pages/MyPages/MyPage_Tax/ManageAgentTax";
import { callWithJwt } from "./baseApi";
import { BANK_PRIME, COMAPNY_TYPE, COMPANY_SIZE, USER_TYPE } from "./models";
import { RegisterCompanyInputs } from "../views/pages/Register/RegisterCompanyPage";
import { value2Type } from "../utils/convertUtils";
import { isDebug } from "../configs/debug";

export function setAgentInfo_bank(
  name: string,
  phone: string,
  email: string,
): Promise<boolean> {
  const loginedId = getLoginedUser()?.userId;
  const url = "/api/user/bank";
  const method = "put";
  const body = JSON.stringify({
    userId: loginedId ? loginedId : "",
    adminNm: name,
    adminPhone: phone,
    adminEmail: email,
    updtId: loginedId ? loginedId : loginedId,
  });
  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}

export function setAgentInfo_TaxAgent(
  agentCpNm: string,
  agentNm: string,
  agentEmail: string,
  agentPhone: string,
): Promise<boolean> {
  const loginedUser = getLoginedUser();
  const userId = loginedUser?.userId ? loginedUser?.userId : "";
  const url = "/api/user/agent";
  const method = "put";
  const body = JSON.stringify({
    userId: userId,
    agentCpNm: agentCpNm,
    agentNm: agentNm,
    agentEmail: agentEmail,
    agentPhone: agentPhone,
    updtId: userId,
  });
  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}

export function setAgentInfo_Company(
  agentDepartment: string,
  agentName: string,
  agentEmail: string,
  agentPhone: string,
): Promise<boolean> {
  const loginedUser = getLoginedUser();
  const id = loginedUser?.userId ? loginedUser?.userId : "";
  const url = "/api/user/company";
  const method = "put";
  const body = JSON.stringify({
    userId: id,
    adminDept: agentDepartment,
    adminNm: agentName,
    adminEmail: agentEmail,
    adminPhone: agentPhone,
    sendAd: "Y",
    updtId: id,
  });
  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}

export function getBankAgentInfo(): Promise<ManageBankAgentInfoInputs> {
  const url = "/api/user/bank/" + getLoginedUser()?.userId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      const result: ManageBankAgentInfoInputs = {
        agentName: data["adminNm"],
        agentEMail: data["adminEmail"],
        agentPhone: data["adminPhone"],
      };
      return result;
    } catch (e) {
      throw e;
    }
  });
}

// 팝업창 은행 담당자 정보 조회 API
export function getBankAgentInfoForPopUp(
  bankName: string,
): Promise<ManageBankAgentAtPopUP> {
  if (isDebug) {
    console.log(value2Type(BANK_PRIME, bankName).toLowerCase());
  }
  const url =
    "/api/user/bank/" + value2Type(BANK_PRIME, bankName).toLowerCase();

  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    if (isDebug) {
      console.log("getBankAgentInfoForPopUp json");
      console.log(json);
    }
    try {
      const data = json["data"]["data"];

      const result: ManageBankAgentAtPopUP = {
        bank: data["bankCd"],
        agentName: data["adminNm"],
        agentEMail: data["adminEmail"],
        agentPhone: data["adminPhone"],
      };
      return result;
    } catch (e) {
      throw e;
    }
  });
}

export function getCompanyInfo_BLUENORMAL(
  cpId: string,
): Promise<RegisterCompanyInputs> {
  const url = "/api/user/company/" + cpId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      const result: RegisterCompanyInputs = {
        ceoNm: data["ceoNm"],
        companyName: data["cpNm"],
        companyType: COMAPNY_TYPE[data["cpType"]],
        companyPlace: data["cpAddress"],
        id: cpId,
        pw: "",
        salesVolum: COMPANY_SIZE[data["cpScale"]],
        businessRegisterFile: data["businessLicense"],
        agnetDepartment: data["adminDept"],
        agentName: data["adminNm"],
        agentEmail: data["adminEmail"],
        agentPhoneNumber: data["adminPhone"],
      };
      return result;
    } catch (e) {
      throw new Error();
    }
  });
}
export function getCompanyAgentInfo_myPage(): Promise<CompanyInfos> {
  let id = getLoginedUser()?.userId.split("_세무")[0];
  const url = "/api/user/company/" + id;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      const result: CompanyInfos = {
        companyName: data["cpNm"],
        companyType: COMAPNY_TYPE[data["cpType"]],
        companyPlace: data["cpAddress"],
      };
      return result;
    } catch (e) {
      throw new Error();
    }
  });
}
export function getCompanyAgentInfo_manageAgent(): Promise<ManageCompanyAgentInfo> {
  const loginedUser = getLoginedUser();
  const url = "/api/user/company/" + loginedUser?.userId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      const result: ManageCompanyAgentInfo = {
        agentDepartment: data["adminDept"],
        agentName: data["adminNm"],
        agentEMail: data["adminEmail"],
        agentPhone: data["adminPhone"],
      };
      return result;
    } catch (e) {
      throw new Error();
    }
  });
}

export function getTaxAgentInfoSelf(
  taxAgentId: string,
): Promise<ManageTaxAgnetInfoSelf> {
  const loginedUser = getLoginedUser();
  const url = "/api/user/agent/" + taxAgentId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      const result = {
        agentDepartment: data["agentCpNm"],
        agnetName: data["agentNm"],
        agnetEMail: data["agentEmail"],
        agnetPhone: data["agentPhone"],
      };
      return result;
    } catch (e) {
      throw e;
    }
    // {
    //         "userId": "agent_test",
    //         "userPw": null,
    //         "cpId": "company_test",
    //         "agentNm": "세무 대리인 명",
    //         "agentEmail": "agent@@email.com",
    //         "agentPhone": "세무 대리인 핸드폰",
    //         "agentCpNm": "세무 사무소 명",
    //         "regiDt": "2024-09-28T02:20:15.324+00:00",
    //         "updtDt": null,
    //         "updtId": null
    //     },
  });
}

export function makeTaxAgent(id: string, pw: string): Promise<boolean> {
  const loginedUser = getLoginedUser();
  if (loginedUser?.userType !== "COMPANY") {
    throw new Error("회사 계정만 세무사 계정 생성 가능");
  }
  const loginedId = loginedUser.userId ? loginedUser.userId : "";

  const url = "/api/user/agent";
  const method = "post";
  const bodyString = JSON.stringify({
    userId: id,
    userPw: pw,
    cpId: loginedId,
    agentNm: "세무사",
    agentEmail: "temp@temp.com",
    agentPhone: "01012341234",
    agentCpNm: "세무사무명",
  });
  return callWithJwt(url, method, bodyString).then((json) => {
    return true;
  });
}

export function deleteTaxAgent(cpId: string): Promise<boolean> {
  const url = "/api/user/agent/" + cpId + "_세무";
  const method = "delete";
  return callWithJwt(url, method, null).then((json) => {
    return true;
  });
}
