import { useEffect, useState } from "react";
import { CommonFloatingCard } from "../../components/CommonFloatingCard";
import { CommonInput, TitleArea } from "../../components/inputs/CommonInput";
import styled from "styled-components";
import {
  CommonDropDown,
  CommonDropDownItem,
} from "../../components/CommonDropDown";
import { COMAPNY_TYPE, COMPANY_SIZE } from "../../../repository/models";
import { zIndex } from "../../../configs/zIndex";
import AttachedPdfInput from "../../components/inputs/AttachedPdfInput";
import {
  CommonBlackButton,
  CommonRedButton,
} from "../../components/buttons/commonButtons";
import {
  showLoading,
  showToast,
} from "../../components/baseComponents/BaseComponent";
import {
  checkDuplicateId,
  RegisterCompanyApi,
} from "../../../repository/registerCompany";
import { useNavigate } from "react-router-dom";
import { isDebug } from "../../../configs/debug";
import { InputMode } from "../../../configs/inputMode";

import { fontFamily } from "../../../configs/font";
import { colors } from "../../../configs/colors";
import {
  getPasswordCheck,
  getEmailCheck,
  getPhoneNumberCheck,
} from "../../../utils/login/inputCheck";
import SearchAddressInput from "../../components/inputs/SearchAddressInput";
import {
  PassWordInput,
  PWInputType,
} from "../../components/inputs/PassWordInput";
import { PassWordCheckInput } from "../../components/inputs/PassWordInputCheck";
import { PhoneNumberInput } from "../../components/inputs/PhoneNumberInput";
import EmailInput from "../../components/inputs/EmailInput";
import { dataURLtoFile } from "../../../utils/fileUtils";
import {
  ManageTaxCommonInfoCard,
  ManageTaxAgnetInfoSelf,
} from "../MyPages/MyPage_Tax/ManageAgentTax";
import TitleWithLine from "../../components/TitleWithLine";

export const PageType = {
  register: "register",
  manageMember: "manageMember",
};
export interface RegisterCompanyInputs {
  ceoNm?: string;
  companyName: string;
  companyType: string;
  companyPlace: string;
  id: string;
  pw: string;
  salesVolum: string;
  businessRegisterFile: string;
  agnetDepartment: string;
  agentName: string;
  agentEmail: string;
  agentPhoneNumber: string;
}

export interface ErrorTextObjs {
  pw: string | null;
  agentEmail: string | null;
  agentPhoneNumber: string | null;
}

export function RegisterCompanyComponent({
  pageType,
  deleteMember,
  companyInfo,
  taxInfo,
}: {
  pageType: string;
  deleteMember: (() => void) | null;
  companyInfo?: RegisterCompanyInputs;
  taxInfo?: ManageTaxAgnetInfoSelf;
}) {
  const inputMode =
    pageType === PageType.register ? InputMode.WRITE : InputMode.READ;
  const navigate = useNavigate();
  const [inputs, setInputs] = useState<RegisterCompanyInputs>(
    companyInfo && pageType === PageType.manageMember
      ? companyInfo
      : {
          companyName: "",
          companyType: "",
          companyPlace: "",
          id: "",
          pw: "",
          salesVolum: "",
          businessRegisterFile: "",
          agnetDepartment: "",
          agentName: "",
          agentEmail: "",
          agentPhoneNumber: "",
        },
  );
  const [pwConfirmDes, setPwConfirmDes] = useState<string>("");
  const [companyDropText, setCompanyDroptext] = useState<string>(
    pageType === PageType.manageMember ? inputs.companyType : "회사 유형",
  );
  const [companyTypeDrop, setCompanyTypeDrop] = useState<CommonDropDownItem[]>(
    Object.keys(COMAPNY_TYPE).map((k) => {
      return {
        text: COMAPNY_TYPE[k],
        onClick: () => {
          inputs.companyType = COMAPNY_TYPE[k];
          setCompanyDroptext(COMAPNY_TYPE[k]);
        },
      };
    }),
  );
  const [salesVolum, setSalesVolum] = useState<string>(
    pageType === PageType.manageMember ? inputs.salesVolum : "매출 규모",
  );
  const [salesVolumDrop, setSalesVolumDrop] = useState<CommonDropDownItem[]>(
    Object.keys(COMPANY_SIZE).map((k) => {
      return {
        text: COMPANY_SIZE[k],
        onClick: () => {
          inputs.salesVolum = COMPANY_SIZE[k];
          setSalesVolum(COMPANY_SIZE[k]);
        },
      };
    }),
  );

  const passwordCheck = getPasswordCheck();
  const emailCheck = getEmailCheck();

  const [errorText, setErrorText] = useState<ErrorTextObjs>({
    pw: "",
    agentEmail: "",
    agentPhoneNumber: "",
  });
  const [companyPlace, setCompanyPlace] = useState<string>("");
  const [pwValue, setPwValue] = useState<string>("");

  useEffect(() => {
    if (pageType === PageType.manageMember) {
      setCompanyPlace(inputs.companyPlace);
    }
    window.scroll(0, 0);
  }, []);

  /**
   * 사업자 등록증 미적용 ->
   * 매출규모 미적용 -> 드롭다운으로 대체되어있음
   */
  const descriptionObj = {
    회사명: "정확한 회사명을 입력해주세요. ex)주식회사 블루노멀",
    사업장주소지: "정확한 주소지를 입력해주세요.",
    아이디: "ID는 회사명입니다. 변경을 원하실 경우 입력해주세요.",
    비밀번호: `최소 비밀번호 길이 8자리 \n 1개 이상의 대문자, 숫자, 특수문자 필수`,
    매출규모: "작년도 기준 매출을 선택해주세요.",
    사업자등록증: "사업자 등록증을 업로드해주세요.",
    담당자부서: "담당자 부서를 입력해주세요.",
    담당자이메일: "서비스 이용 및 비밀번호 찾기에 이용됩니다.",
    담당자휴대전화번호: "정확한 휴대전화 번호를 입력해주세요.",
  };

  return (
    <Center>
      {pageType === PageType.register ? (
        <>
          <PageTitle>회원가입</PageTitle>
          <SubDescrition>
            블루노멀 회원가입을 위해 하단 정보를 기입해주세요.
          </SubDescrition>
        </>
      ) : (
        ""
      )}
      {pageType === PageType.manageMember ? (
        <>
          <TitleWithLine title="회원 정보 관리" />
        </>
      ) : (
        ""
      )}

      <CommonFloatingCard>
        <ParentStyle>
          <CommonInput
            inputMode={inputMode}
            title="대표자 성명"
            placeHold={null}
            description={null}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.ceoNm = str;
            }}
            setClearInput={(clearInputFunction: () => void) => {}}
            initialInput={inputs.ceoNm ? inputs.ceoNm : ""}
          />
          <CommonInput
            inputMode={inputMode}
            title="회사명"
            placeHold={null}
            description={descriptionObj["회사명"]}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.companyName = str;
            }}
            setClearInput={(clearInputFunction: () => void) => {}}
            initialInput={inputs.companyName}
          />

          <TitleArea></TitleArea>
          <CommonDropDown
            dropDownTitle="회사 유형"
            inputMode={inputMode}
            text={companyDropText}
            items={companyTypeDrop}
            width="100%"
            extraZIndex={100}
          />
          {/* 버튼 옆에 두기 위에 수정 */}
          <CompanyPlaceWrapper>
            <CommonInput
              inputMode={inputMode}
              title="사업장 주소지"
              initialInput={companyPlace}
              placeHold={null}
              description={descriptionObj["사업장주소지"]}
              width="100%"
              onTextChanged={(str: string) => {
                inputs.companyPlace = str;
              }}
              setClearInput={(clearInputFunction: () => void) => {}}
            />
            {pageType === PageType.register ? (
              <AddressWrapper>
                <SearchAddressInput
                  onFullAddressChanged={(addr: string) => {
                    setCompanyPlace(addr);
                  }}
                />
              </AddressWrapper>
            ) : (
              ""
            )}
          </CompanyPlaceWrapper>
          <CommonInput
            inputMode={inputMode}
            title="아이디"
            placeHold={null}
            description={descriptionObj["아이디"]}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.id = str;
            }}
            setClearInput={(clearInputFunction: () => void) => {}}
            initialInput={inputs.id}
          />
          {/* <CommonInput
            inputMode={InputMode.WRITE}
            title="비밀번호"
            placeHold={null}
            description={descriptionObj["비밀번호"]}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.pw = str;
              passwordCheck.input = str;
              setErrorText({
                ...errorText,
                pw: passwordCheck.getErrorMessage(),
              });
            }}
            errorText={errorText.pw}
            setClearInput={(clearInputFunction: () => void) => {}}
          /> */}
          {pageType === PageType.register ? (
            <div>
              <PassWordInput
                needRegex={true}
                inputMode={inputMode}
                width="100%"
                onTextChanged={(str: string) => {
                  inputs.pw = str;
                  setPwValue(str);
                }}
              />

              <PassWordCheckInput
                inputMode={inputMode}
                width="100%"
                pwValueForCheck={pwValue}
              />
            </div>
          ) : (
            ""
          )}

          <CommonDropDown
            dropDownTitle="매출 규모"
            inputMode={inputMode}
            text={salesVolum}
            items={salesVolumDrop}
            width="100%"
            extraZIndex={99}
          />
          <DescriptionStyle>{descriptionObj["매출규모"]}</DescriptionStyle>
          <p>사업자등록증</p>
          <AttachedPdfInput
            inputMode={inputMode}
            showPreview={false}
            onLoad={(file: File, src: string) => {
              inputs.businessRegisterFile = src;
            }}
            initialFileString={inputs.businessRegisterFile}
            initialFileName={"사업자등록즉_" + inputs.companyName}
          />
          <DescriptionStyle>{descriptionObj["사업자등록증"]}</DescriptionStyle>
          <CommonInput
            inputMode={inputMode}
            title="담당자 부서"
            placeHold={null}
            description={descriptionObj["담당자부서"]}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.agnetDepartment = str;
            }}
            setClearInput={(clearInputFunction: () => void) => {}}
            initialInput={inputs.agnetDepartment}
          />
          <CommonInput
            inputMode={inputMode}
            title="담당자 이름"
            placeHold={null}
            description={null}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.agentName = str;
            }}
            setClearInput={(clearInputFunction: () => void) => {}}
            initialInput={inputs.agentName}
          />
          <EmailInput
            inputMode={inputMode}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.agentEmail = str;
            }}
            initialInput={inputs.agentEmail}
          />
          <PhoneNumberInput
            inputMode={inputMode}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.agentPhoneNumber = str;
            }}
            initialInput={inputs.agentPhoneNumber}
          />

          {pageType === PageType.manageMember && taxInfo ? (
            <>
              <ManageTaxCommonInfoCard
                inputMode={InputMode.READ}
                gotoDone={() => {}}
                info={taxInfo}
              />
            </>
          ) : (
            ""
          )}
          <ButtonWrapper>
            {pageType === PageType.register ? (
              <CommonBlackButton
                text="회원가입 완료"
                onclick={() => {
                  onClickRegister(inputs, () => {
                    navigate("/done/회원가입 완료/회원가입이 완료 되었습니다.");
                  });
                }}
                minWidth="100px"
              />
            ) : (
              ""
            )}
            {pageType === PageType.manageMember ? (
              <CommonRedButton
                text="회원 삭제"
                onclick={() => {
                  if (deleteMember) {
                    deleteMember();
                  }
                }}
                minWidth="100px"
              />
            ) : (
              ""
            )}
          </ButtonWrapper>
        </ParentStyle>
      </CommonFloatingCard>
    </Center>
  );
}

function onClickRegister(inputs: RegisterCompanyInputs, onSuccess: () => void) {
  if (isDebug) {
    console.log(inputs);
  }
  let tmpValidCheck = true;

  // 전화번호 형식 확인 로직 추가 필요
  Object.values(inputs).forEach((s) => {
    if (typeof s === "string") {
      if (s.length <= 0) {
        tmpValidCheck = false;
      }
    }
  });
  if (!tmpValidCheck) {
    showToast("입력 정보를 확인해 주세요", 3000);
  } else {
    showLoading(true);
    checkDuplicateId(inputs.id)
      .then((r: boolean) => {
        if (r) {
          return RegisterCompanyApi(inputs, () => {}).then(() => {
            onSuccess();
          });
        } else {
          showToast("아이디가 중복되었습니다.", 3000);
        }
      })
      .catch(() => {
        showToast("오류가 발생했습니다", 3000);
      })
      .finally(() => {
        showLoading(false);
      });
  }
}

const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

const ParentStyle = styled.div`
  width: 800px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  font-size: 60px;
  margin: 0px;
  margin-top: 30px;
  padding: 5px;
  font-family: ${fontFamily.Regular};
`;

const SubDescrition = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin: 0px;
  margin-bottom: 30px;
  padding: 5px;
  font-family: ${fontFamily.Regular};
`;

const DescriptionStyle = styled.p`
  width: calc(100% - 20px);
  font-family: ${fontFamily.Medium};
  font-size: 14px;
  color: ${colors.placeholder};
`;

const CompanyPlaceWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const AddressWrapper = styled.div`
  display: flex;
  padding-top: 10px;
`;
