import styled from "styled-components";

export function PdfViewer({ src }: { src: string }) {
  return (
    <FillMaxStyle>
      <embed src={src} type="application/pdf" height={"100%"} width={"100%"} />
    </FillMaxStyle>
  );
}

const FillMaxStyle = styled.div`
  width: 100%;
  height: 100%;
`;
