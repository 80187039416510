import { useEffect, useState } from "react";
import {
  CommonFloatingCard,
  CommonFloatingWhiteCard,
} from "../../../components/CommonFloatingCard";
import styled from "styled-components";
import { fontFamily } from "../../../../configs/font";
import {
  number2FormatMoneyString,
  number2FormatPerCentString,
} from "../../../../utils/formatUtils";
import {
  CommonBlueButton,
  CommonWhiteButton,
} from "../../../components/buttons/commonButtons";
import { colors } from "../../../../configs/colors";
import {
  CommonDropDown,
  CommonDropDownItem,
} from "../../../components/CommonDropDown";
import {
  BANK_PRIME,
  LOAN_PURPOSE,
  LOAN_TYPE,
} from "../../../../repository/models";
import { CommonSpacer } from "../../../components/CommonSpacer";
import { InputMode } from "../../../../configs/inputMode";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import { parseDateForServer } from "../../../../utils/dateUtils";
import {
  getAllCertianLoans,
  getMainAuditResultDetail,
} from "../../../../repository/loanApi";

import { PhoneNumberInput } from "../../../components/inputs/PhoneNumberInput";
import { CommonInput } from "../../../components/inputs/CommonInput";
import EmailInput from "../../../components/inputs/EmailInput";
import {
  getBankAgentInfo,
  getBankAgentInfoForPopUp,
} from "../../../../repository/agentApi";
import {
  ManageBankAgentAtPopUP,
  ManageBankAgentInfoInputs,
} from "../MyPage_Bank/ManageAgent_Bank";
import { isDebug } from "../../../../configs/debug";
import { zIndex } from "../../../../configs/zIndex";
import {
  compareDateFast,
  compareDateLate,
  compareMoneyHeight,
  compareMoneyLow,
  compareRateHigh,
  compareRateLow,
} from "../../../../utils/compareLoanUtils";

const STEP = {
  list: "list",
  detail: "detail",
};
interface LoanInfo {
  loanRequestId: string;
}
export interface LoanResultInfo {
  bank: string;
  maxLoan: number;
  interestRate: number;
  date: Date;
  id: string;
}
const SortValue = {
  date: "최신순",
  dateOld: "느린순",
  rateHigh: "금리 높은순",
  rateLow: "금리 낮은순",
  MoneyHigh: "대출 한도 높은순",
  MoneyLow: "대출 한도 낮은순",
};
function getSortedList(
  list: LoanResultInfo[],
  sortValue: string,
): LoanResultInfo[] {
  let result: LoanResultInfo[] = list;
  switch (sortValue) {
    case SortValue.date:
      result = result.sort(compareDateFast);
      break;
    case SortValue.dateOld:
      result = result.sort(compareDateLate);
      break;
    case SortValue.MoneyHigh:
      result = result.sort(compareMoneyHeight);
      break;
    case SortValue.MoneyLow:
      result = result.sort(compareMoneyLow);
      break;
    case SortValue.rateHigh:
      result = result.sort(compareRateHigh);
      break;
    case SortValue.rateLow:
      result = result.sort(compareRateLow);
      break;
  }
  return list;
}

export function LoanResultListComponent({ loanInfo }: { loanInfo: LoanInfo }) {
  const [step, setStep] = useState<string>(STEP.list);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  useEffect(() => {
    if (step === STEP.list) {
      setContent(
        <LoanResultListComponentList
          loanInfo={loanInfo}
          onClickItem={(item: LoanResultInfo) => {
            setSelectedId(item.id);
            setStep(STEP.detail);
          }}
        />,
      );
    } else if (step === STEP.detail) {
      setContent(<LoanResultListComponentDetail selectedId={selectedId} />);
    }
  }, [step]);
  return <div>{content ? content : ""}</div>;
}

interface LoanDetailInfo {
  bank: string;
  type: string;
  purpose: string;
  rate: string;
  max: string;
  note: string;
}

/**
 * 임시 처리 해놓은듯
 * @param param0
 * @returns
 */
export function LoanResultListComponentDetail({
  selectedId,
}: {
  selectedId: string;
}) {
  const INIT_LOAN_DETAIL_DATA: LoanDetailInfo = {
    bank: "",
    type: "",
    purpose: "",
    rate: "",
    max: "",
    note: "",
  };

  const [info, setInfo] = useState<LoanDetailInfo>(INIT_LOAN_DETAIL_DATA);
  const [content, setContent] = useState<JSX.Element>(<></>);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    showLoading(true);
    getMainAuditResultDetail(selectedId)
      .then((res) => {
        if (isDebug) {
          console.log(res);
          console.log(res.loanType, res.loanPurpose);
        }
        showLoading(true);
        setInfo({
          bank: BANK_PRIME[res.bank],
          type: LOAN_TYPE[res.loanType],
          purpose: LOAN_PURPOSE[res.loanPurpose],
          rate: res.interestRate.toString(),
          max: res.maxLoan.toString(),
          note: res.memo,
        });
      })
      .catch((e) => {
        showToast("정보를 불러오는 중 오류가 발생했습니다.", 1000);
      })
      .finally(() => {
        showLoading(false);
      });

    console.log(info);
  }, []);
  return (
    <>
      <ParentStyle>
        <div>
          <CommonSpacer />
          <TitleStyle>대환대출 결과 확인하기 Detail</TitleStyle>
          <UnderLine />
          <CommonSpacer />
          <TitleStyle>은행 명</TitleStyle>
          <UnderLine />
          <TextStyle>{info.bank}</TextStyle>

          <TitleStyle>대출 종류</TitleStyle>
          <UnderLine />
          <TextStyle>{info.type}</TextStyle>

          <TitleStyle>대출 목적</TitleStyle>
          <UnderLine />
          <TextStyle>{info.purpose}</TextStyle>

          <TitleStyle>대출 금리</TitleStyle>
          <UnderLine />
          <TextStyle>{info.rate}%</TextStyle>

          <TitleStyle>대출 한도</TitleStyle>
          <UnderLine />
          <TextStyle>{info.max}원</TextStyle>

          <TitleStyle>담당자 한말씀</TitleStyle>
          <UnderLine />
          <TextStyle>{info.note}</TextStyle>
        </div>

        <CommonBlueButton
          text="대출 신청하기"
          minWidth="fit-content"
          onclick={() => {
            //bank이름이

            // if (!info.bank) {
            //   showToast("은행 이름이 없습니다.", 1000);
            //   return;
            // }

            // if (isDebug && !info.bank) {
            //   info.bank = "우리은행";
            // }

            showLoading(true);
            getBankAgentInfoForPopUp(info.bank)
              .then((result: ManageBankAgentAtPopUP) => {
                setContent(
                  <PopUpScreen
                    input={result}
                    onClick={() => {
                      setModalOpen(false);
                      console.log(modalOpen);
                      console.log("sdfsdf");
                    }}
                  />,
                );

                setModalOpen(true);
              })
              .catch((e) => {
                console.log(e);
                showToast("정보를 불러오는 중 오류가 발생했습니다", 1000);
              })
              .finally(() => {
                showLoading(false);
              });
          }}
        />
      </ParentStyle>

      <div>{modalOpen && content}</div>
    </>
  );
}

function PopUpScreen({
  input,
  onClick,
}: {
  input: ManageBankAgentAtPopUP;
  onClick: () => void;
}) {
  const width = "100%";
  return (
    <PopUpBackgroundStyle>
      <PopUpBackParentStyle>
        <CommonFloatingCard>
          <InnerWrapper>
            <CommonInput
              inputMode={InputMode.READ}
              initialInput={BANK_PRIME[input.bank]}
              title="은행 명"
              onTextChanged={() => {}}
              setClearInput={() => {}}
              description={null}
              placeHold={null}
              width={width}
            />

            <CommonInput
              inputMode={InputMode.READ}
              initialInput={input.agentName}
              title="담당자 이름"
              onTextChanged={() => {}}
              setClearInput={() => {}}
              description={null}
              placeHold={null}
              width={width}
            />

            <EmailInput
              inputMode={InputMode.READ}
              initialInput={input.agentEMail}
              onTextChanged={() => {}}
              width={width}
            />

            <PhoneNumberInput
              inputMode={InputMode.READ}
              initialInput={input.agentPhone}
              onTextChanged={() => {}}
              width={width}
            />

            <p>&middot; 안내 멘트는 여기로 들어갑니다.</p>
          </InnerWrapper>

          <ButtonWrapper>
            <CommonBlueButton
              text="창 닫기"
              onclick={onClick}
              minWidth="fit-content"
            />
          </ButtonWrapper>
        </CommonFloatingCard>
      </PopUpBackParentStyle>
    </PopUpBackgroundStyle>
  );
}

const PopUpBackgroundStyle = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loading};
  position: fixed;
  transform: translateY(0);
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.9);
`;
const PopUpBackParentStyle = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loading + 1};
  position: fixed;
  transform: translateY(0);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  width: 400px;
  height: 100%;
  padding: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  > * {
    padding: 0px 20px;
  }
`;

/**
 * 기업_대환 대출 결과확인
 * @param param0
 * @returns
 */
function LoanResultListComponentList({
  loanInfo,
  onClickItem,
}: {
  loanInfo: LoanInfo;
  onClickItem: (item: LoanResultInfo) => void;
}) {
  const [loanResults, setLoanResults] = useState<LoanResultInfo[]>([]);
  const [contentUi, setContentUi] = useState<JSX.Element[]>([]);
  const [sort, setSort] = useState<string>(SortValue.date);

  const dropDownItems: CommonDropDownItem[] = [
    {
      text: SortValue.date,
      onClick: () => {
        setSort(SortValue.date);
      },
    },
    {
      text: SortValue.dateOld,
      onClick: () => {
        setSort(SortValue.dateOld);
      },
    },
    {
      text: SortValue.MoneyHigh,
      onClick: () => {
        setSort(SortValue.MoneyHigh);
      },
    },
    {
      text: SortValue.MoneyLow,
      onClick: () => {
        setSort(SortValue.MoneyLow);
      },
    },
    {
      text: SortValue.rateHigh,
      onClick: () => {
        setSort(SortValue.rateHigh);
      },
    },
    {
      text: SortValue.rateLow,
      onClick: () => {
        setSort(SortValue.rateLow);
      },
    },
  ];

  // 대환 대출 결과 확인하기-> 정렬방법에 따라 컴포넌트 정렬 변동 [은행/ 가격/ 확인하기]
  useEffect(() => {
    let uiList: JSX.Element[] = [];
    const sorted = getSortedList(loanResults, sort);
    sorted.forEach((item) => {
      uiList.push(
        <LoanResultItemComponent
          item={item}
          key={item.id}
          onClick={() => {
            onClickItem(item);
          }}
        />,
      );
    });
    setContentUi(uiList);
  }, [loanResults, sort]);

  /**
   * 특정 대출 결과 전체조회
   */
  // loanInfo.loanRequestId 이용하여 리스트 불러와야 함
  // getAllLoans() 따라함
  // date 파싱 맞는지 확인

  useEffect(() => {
    showLoading(true);
    getAllCertianLoans(loanInfo.loanRequestId)
      .then((res) => {
        const converted = res.map((info) => {
          const { loanResId, bank, maxLoan, interestRate, date } = info;

          const tmp: LoanResultInfo = {
            bank: BANK_PRIME[bank],
            maxLoan: maxLoan,
            interestRate: interestRate,
            date: new Date(parseDateForServer(date)),
            id: loanResId.toString(),
          };
          return tmp;
        });

        setLoanResults(converted);
      })
      .catch((e) => {
        showToast("정보를 불러오는 중 오류가 발생했습니다.", 1000);
      })
      .finally(() => {
        showLoading(false);
      });
  }, []);

  return (
    <ParentStyle>
      <div>
        <CommonSpacer />
        <TitleStyle>대환대출 결과 확인하기</TitleStyle>
        <UnderLine />
        <CommonSpacer />
        <LeftStyle>
          <CommonDropDown
            inputMode={InputMode.WRITE}
            text={sort}
            items={dropDownItems}
            width="fit-content"
            extraZIndex={0}
          />
        </LeftStyle>
        {contentUi}
      </div>
    </ParentStyle>
  );
}
const LeftStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
`;

const TitleStyle = styled.div`
  font-family: ${fontFamily.Medium};
  font-size: 24px;
`;
const UnderLine = styled.div`
  width: 800px;
  background-color: ${colors.main};
  height: 2px;
`;

const ParentStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function getBankIcon(bankName: string): string {
  let result = "";
  try {
    result = require(`../../../../assets/bankLogos/${bankName}.png`);
  } catch (e) {}
  return result;
}

function LoanResultItemComponent({
  item,
  onClick,
}: {
  item: LoanResultInfo;
  onClick: () => void;
}) {
  return (
    <CommonFloatingWhiteCard>
      <ItemPatentStyle>
        <BankIconStyle src={getBankIcon(item.bank)} />
        <TextStyle style={{ width: "250px" }}>
          {number2FormatMoneyString(item.maxLoan)}
        </TextStyle>
        <TextStyle style={{ width: "150px" }}>{item.interestRate}%</TextStyle>
        <CommonWhiteButton
          text="확인하기"
          minWidth="80px"
          onclick={() => {
            onClick();
          }}
        />
      </ItemPatentStyle>
    </CommonFloatingWhiteCard>
  );
}
const BankIconStyle = styled.img`
  width: 100px;
  height: 50px;
  align-self: center;
`;

const ItemPatentStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 8px;
  width: 800px;
`;

const TextStyle = styled.p`
  width: ${(props) =>
    props.style?.width ? props.style?.width : "fit-content"};
  font-style: ${fontFamily.Medium};
  font-size: 20px;
  text-align: end;
`;
