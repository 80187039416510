import React from "react";
import styled from "styled-components";
import { colors } from "../../configs/colors";

export function CommonFloatingCard({
  children,
}: {
  children: React.ReactNode;
}) {
  return <CardStyle>{children}</CardStyle>;
}

const CardStyle = styled.div`
  border: 1px solid ${colors.stroke};
  border-radius: 10px;
  background-color: ${colors.background};
  padding: 10px;
  box-shadow: 5px 5px 3px #666;
  margin: 10px;
  width: max-content;
  height: fit-content;
`;

export function CommonFloatingWhiteCard({
  children,
}: {
  children: React.ReactNode;
}) {
  return <WhiteCardStyle>{children}</WhiteCardStyle>;
}

const WhiteCardStyle = styled.div`
  border: 1px solid ${colors.stroke};
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 5px 3px #666;
  margin: 10px;
  width: max-content;
  height: 100%;
  align-content: center;
  background-color: white;
`;
