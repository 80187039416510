import styled from "styled-components";
import { colors } from "../../../configs/colors";
import { fontFamily } from "../../../configs/font";
import logo from "../../../assets/logo.png";

export function Footer() {
  return (
    <FooterStyle>
      <FooterImg src={logo} />
      <FooterText>
        (주)블루노멀 · 대표이사 정진원 사업자등록번호 110111-8578754 · <br />
        서울특별시 송파구 송파대로 18길 14-15, 1층 (문정동) 1F, 14-15, <br />
        Songpa-daero 18-gil, Songpa-gu,  Seoul, Republic of Korea 고객지원{" "}
        <br />
        admin@bluenormal.co.kr <br />
        <br />
        Copyright Bluenormal, lnc. All Rights Reserved
      </FooterText>
    </FooterStyle>
  );
}

const FooterImg = styled.img`
  width: 200px;
  height: 40px;
`;
const FooterText = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 15px;
  color: ${colors.placeholder};
`;
const FooterStyle = styled.div`
  width: calc(100% - 20%);
  background-color: #f5f6fa;
  padding: 10px;
  align-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 25px;
`;
