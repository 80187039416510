import { isDebug } from "../configs/debug";

export function value2Type(
  type: { [key: string]: string },
  value: string,
): string {
  const result = Object.keys(type).find((k: string) => {
    if (type[k] === value) {
      return k;
    }
  });

  if (isDebug && !result) {
    console.log(`값에 맞는 타입을 찾을 수 없음 (${value}-${type})`);
  }
  return result ? result : "";
}
