import styled from "styled-components";
import { fontFamily } from "../../../../configs/font";
import { colors } from "../../../../configs/colors";
import { CommonFloatingWhiteCard } from "../../../components/CommonFloatingCard";
import { CommonWhiteButton } from "../../../components/buttons/commonButtons";
import { useEffect, useState } from "react";
import {
  PageType,
  RegisterCompanyComponent,
  RegisterCompanyInputs,
} from "../../Register/RegisterCompanyPage";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import {
  deleteCompanyId,
  getAllCompanyInfo,
} from "../../../../repository/userApi";
import {
  deleteTaxAgent,
  getCompanyInfo_BLUENORMAL,
  getTaxAgentInfoSelf,
} from "../../../../repository/agentApi";
import { ManageTaxAgnetInfoSelf } from "../MyPage_Tax/ManageAgentTax";
import { useNavigate } from "react-router-dom";
import TitleWithLine from "../../../components/TitleWithLine";

const STEP = {
  list: "list",
  detail: "detail",
};
export function ManageMembers() {
  const [step, setStep] = useState<string>(STEP.list);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    if (step === STEP.list) {
      setContent(
        <ManageMembers_List
          setStepDetail={(selectedId: string) => {
            setSelectedId(selectedId);
            setStep(STEP.detail);
          }}
        />,
      );
    } else if (step === STEP.detail) {
      showLoading(true);
      getCompanyInfo_BLUENORMAL(selectedId)
        .then((companyInfo: RegisterCompanyInputs) => {
          getTaxAgentInfoSelf(selectedId + "_세무")
            .then((info: ManageTaxAgnetInfoSelf) => {
              setContent(
                <RegisterCompanyComponent
                  pageType={PageType.manageMember}
                  deleteMember={() => {
                    showLoading(true);
                    deleteTaxAgent(selectedId)
                      .finally(() => {
                        deleteCompanyId(selectedId)
                          .then(() => {
                            setStep(STEP.list);
                          })
                          .finally(() => {
                            showLoading(false);
                          });
                      })
                      .finally(() => {
                        showLoading(false);
                      });
                  }}
                  companyInfo={companyInfo}
                  taxInfo={info}
                />,
              );
            })
            .catch(() => {
              // 세무사 없는 경우 에러 내려올때 처리
              setContent(
                <RegisterCompanyComponent
                  pageType={PageType.manageMember}
                  deleteMember={() => {
                    showLoading(true);
                    deleteTaxAgent(selectedId)
                      .finally(() => {
                        deleteCompanyId(selectedId)
                          .then(() => {
                            setStep(STEP.list);
                          })
                          .finally(() => {
                            showLoading(false);
                          });
                      })
                      .finally(() => {
                        showLoading(false);
                      });
                  }}
                  companyInfo={companyInfo}
                  taxInfo={{
                    agentDepartment: "",
                    agnetName: "",
                    agnetEMail: "",
                    agnetPhone: "",
                  }}
                />,
              );
            });
        })
        .catch(() => {
          showToast("오류가 발생했습니다.", 1000);
        })
        .finally(() => {
          showLoading(false);
        });
    }
  }, [step]);

  return <div>{content ? content : ""}</div>;
}

export interface BNManageMemberInfo {
  cpId: string;
  companyName: string;
  ceoName: string;
}
function ManageMembers_List({
  setStepDetail,
}: {
  setStepDetail: (selelctedId: string) => void;
}) {
  const [userList, setUserList] = useState<BNManageMemberInfo[]>([]);
  const [uiList, setUiList] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const result: JSX.Element[] = [];
    userList.forEach((item: BNManageMemberInfo) => {
      result.push(
        <MemberComponent
          item={item}
          onClick={() => {
            setStepDetail(item.cpId);
          }}
        />,
      );
    });
    setUiList(result);
  }, [userList]);
  useEffect(() => {
    showLoading(true);
    getAllCompanyInfo()
      .then((infos: BNManageMemberInfo[]) => {
        setUserList(infos);
      })
      .catch((e) => {
        showToast("오류가 발생했습니다.", 1000);
      })
      .finally(() => {
        showLoading(false);
      });
    // // 유저 리스트 api 호출
    // setUserList([
    //   { id: "id1", companyName: "companyName1", agentName: "agentName1" },
    //   { id: "id2", companyName: "companyName2", agentName: "agentName2" },
    //   { id: "id3", companyName: "companyName3", agentName: "agentName3" },
    //   { id: "id4", companyName: "companyName4", agentName: "agentName4" },
    //   { id: "id5", companyName: "companyName5", agentName: "agentName5" },
    // ]);
  }, []);
  return (
    <ParentStyle>
      <TitleWithLine title="회원 정보 관리" />
      {uiList}
    </ParentStyle>
  );
}

const ParentStyle = styled.div`
  width: max-content;
`;

function MemberComponent({
  item,
  onClick,
}: {
  item: BNManageMemberInfo;
  onClick: () => void;
}) {
  return (
    <CommonFloatingWhiteCard>
      <ItemPatentStyle>
        <TextStyle>{item.companyName}</TextStyle>
        <TextStyle>{item.ceoName}</TextStyle>
        <CommonWhiteButton
          text="확인하기"
          minWidth="80px"
          onclick={() => {
            onClick();
          }}
        />
      </ItemPatentStyle>
    </CommonFloatingWhiteCard>
  );
}

const ItemPatentStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
`;

const TextStyle = styled.p`
  display: flex;
  align-self: center;
  margin: 0px;

  font-family: ${fontFamily.Regular};
  font-size: 20px;
  width: 30%;
  word-break: break-all;
`;
