import { LoanResultInfo } from "../views/pages/MyPages/MyPage_Company/LoanResultListComponent";
/**
 * 유저가 우선순위로 선택한 조건이 [유리]한 조건인지 [불리]한 조건인지 판단하여 1순위 조건이 같을 때 2순위 이상의 조건을 판단한다.
 * "금리 낮음","한도 높음", "날짜 빠름" 순으로 [유리]한 영향도가 높다고 판단한다.
 * 만약 유저가 [유리]한 조건을 선택한다면 2순위 이상의 조건도 유리한 방향으로 설정한다.
 * 만약 유저가 [불리]한 조건을 선택한다면 역으로 2순위 이상의 조건도 불리한 방향으로 설정한다.
 */

// 2순위부터 고객에게 [유리]한 조건이 위로
export function compareDateFast(a: LoanResultInfo, b: LoanResultInfo) {
  if (isDateSame(a, b)) {
    if (isRateSame(a, b)) {
      return simpleCompareMoneyHeight(a, b);
    }
    return simpleCompareRateLow(a, b);
  }
  return simpleCompareDateFast(a, b);
}
function simpleCompareDateFast(a: LoanResultInfo, b: LoanResultInfo) {
  return b.date.getTime() - a.date.getTime();
}

// 2순위부터 고객에게 [유리]한 조건이 위로
export function compareDateLate(a: LoanResultInfo, b: LoanResultInfo) {
  if (isDateSame(a, b)) {
    if (isRateSame(a, b)) {
      return simpleCompareMoneyHeight(a, b);
    }
    return simpleCompareRateLow(a, b);
  }
  return simpleCompareDateLate(a, b);
}
function simpleCompareDateLate(a: LoanResultInfo, b: LoanResultInfo) {
  return a.date.getTime() - b.date.getTime();
}

// 2순위부터 고객에게 [유리]한 조건이 위로
export function compareMoneyHeight(a: LoanResultInfo, b: LoanResultInfo) {
  if (isMoneySame(a, b)) {
    if (isRateSame(a, b)) {
      return simpleCompareDateFast(a, b);
    }
    return simpleCompareRateLow(a, b);
  }
  return simpleCompareMoneyHeight(a, b);
}
function simpleCompareMoneyHeight(a: LoanResultInfo, b: LoanResultInfo) {
  return b.maxLoan - a.maxLoan;
}

// 역순 정렬이기 때문에 고객은 아래에서부터 확인할 가능성이 높다.
// 2순위부터 고객에게 [불리]한 조건이 위로
export function compareMoneyLow(a: LoanResultInfo, b: LoanResultInfo) {
  if (isMoneySame(a, b)) {
    if (isRateSame(a, b)) {
      return simpleCompareDateLate(a, b);
    }
    return simpleCompareRateHigh(a, b);
  }
  return simpleCompareMoneyLow(a, b);
}
function simpleCompareMoneyLow(a: LoanResultInfo, b: LoanResultInfo) {
  return a.maxLoan - b.maxLoan;
}

// 역순 정렬이기 때문에 고객은 아래에서부터 확인할 가능성이 높다.
// 2순위부터 고객에게 [불리]한 조건이 위로
export function compareRateHigh(a: LoanResultInfo, b: LoanResultInfo) {
  if (isRateSame(a, b)) {
    if (isMoneySame(a, b)) {
      return simpleCompareDateLate(a, b);
    }
    return simpleCompareMoneyLow(a, b);
  }
  return simpleCompareRateHigh(a, b);
}
function simpleCompareRateHigh(a: LoanResultInfo, b: LoanResultInfo) {
  return b.interestRate - a.interestRate;
}

// 2순위부터 고객에게 [유리]한 조건이 위로
export function compareRateLow(a: LoanResultInfo, b: LoanResultInfo) {
  if (isRateSame(a, b)) {
    if (isMoneySame(a, b)) {
      return simpleCompareDateFast(a, b);
    }
    return simpleCompareMoneyHeight(a, b);
  }
  return simpleCompareRateLow(a, b);
}
function simpleCompareRateLow(a: LoanResultInfo, b: LoanResultInfo) {
  return a.interestRate - b.interestRate;
}

function isDateSame(a: LoanResultInfo, b: LoanResultInfo) {
  return b.date.getTime() === a.date.getTime();
}
function isMoneySame(a: LoanResultInfo, b: LoanResultInfo) {
  return b.maxLoan === a.maxLoan;
}
function isRateSame(a: LoanResultInfo, b: LoanResultInfo) {
  return a.interestRate === b.interestRate;
}
