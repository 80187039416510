function CommonCheckBox({
  text,
  checked,
  onChange,
}: {
  text: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={({ target: { checked } }) => onChange(checked)}
      />
      {text}
    </label>
  );
}

export default CommonCheckBox;
