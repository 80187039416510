function file2Base64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject("timeOut");
      return;
    }, 5000);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result;
      if (typeof result === "string") {
        resolve(result);
        return;
      } else {
        reject("string 형식으로 반환되지 않았음");
        return;
      }
    };
    reader.onerror = () => {
      reject("string 형식으로 반환되지 않았음");
      return;
    };
  });
}

function dataURLtoFile(dataurl: string, filename: string): Promise<File> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject("timeOut");
      return;
    }, 5000);
    if (filename.length < 1) {
      reject("만들 파일 이름을 지정하지 않았음");
      return;
    }
    var arr = dataurl.split(",");
    var mime = arr[0].match(/:(.*?);/);
    if (!mime || !mime[1]) {
      reject("파일 타입을 알 수 없음");
      return;
    }
    var bstr = atob(arr[arr.length - 1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    resolve(new File([u8arr], filename, { type: mime[0] }));
    return;
  });
}

export { file2Base64, dataURLtoFile };
