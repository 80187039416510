import { login } from "../repository/userApi";
import { LoginedUser } from "../uiModels/User";
import {
  saveObjectToLocalStorage,
  getObjectFromLocalStorage,
} from "../utils/localStorageUtils";
import { showToast } from "../views/components/baseComponents/BaseComponent";
import { isDebug } from "./debug";

const userStorageKey = "loginedUserInfo";
const vaildLoginTimeMin = 24 * 60;

function debugLogin(
  id: string,
  pw: string,
  userType: string,
  onSuccess: (loginedUser: LoginedUser) => void,
  onFail: (reason: string) => void,
) {
  const loginedUser: LoginedUser = {
    userId: id,
    userType: userType,
    jwtToken: "",
    jwtRefreshToekn: "",
  };
  saveObjectToLocalStorage(userStorageKey, loginedUser, vaildLoginTimeMin);
  onSuccess(loginedUser);
  // eslint-disable-next-line no-restricted-globals
  location.reload();
}
export function tryLogin(
  id: string,
  pw: string,
  userType: string,
  onSuccess: (loginedUser: LoginedUser) => void,
  onFail: (reason: string) => void,
  preventReLoadOnSuccess?: boolean,
  preventReLoadOnFail?: boolean,
) {
  if (false) {
    debugLogin(id, pw, userType, onSuccess, onFail);
  } else {
    login(id, pw, userType)
      .then((loginedUser) => {
        if (loginedUser.userType !== userType) {
          throw new Error("다른 타입의 로그인 방식");
        }
        saveObjectToLocalStorage(
          userStorageKey,
          loginedUser,
          vaildLoginTimeMin,
        );
        onSuccess(loginedUser);
        if (!preventReLoadOnSuccess) {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
      })
      .catch((e) => {
        if (!preventReLoadOnFail) {
          logout(() => {
            onFail("로그인 실패");
          });
        } else {
          onFail("로그인 실패");
        }
        throw e;
      });
  }
}

export function getLoginedUser(): LoginedUser | null {
  try {
    const user = getObjectFromLocalStorage(userStorageKey) as LoginedUser;
    return user;
  } catch {
    return null;
  }
}

export function logout(afterLogout: () => void) {
  saveObjectToLocalStorage(userStorageKey, "", -1);
  afterLogout();
  // eslint-disable-next-line no-restricted-globals
  location.reload();
}
