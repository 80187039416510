import { isDebug } from "../configs/debug";

// 월 차이
export function getDayDiff(d1: Date | string, d2: Date | string): number {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  const diffDate = date1.getTime() - date2.getTime();

  return Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24)));
}

export function parseDateForServer(str: string) {
  if (!(str.length === 8)) {
    return new Date(Date.parse(str));
  }
  const y = Number(str.substring(0, 4));
  const m = Number(str.substring(4, 6));
  const d = Number(str.substring(6, 8));
  return new Date(y, m, d);
}
