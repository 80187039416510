import { useEffect, useState } from "react";
import { CommonFloatingCard } from "./CommonFloatingCard";
import AttachedPdfInput from "./inputs/AttachedPdfInput";
import styled from "styled-components";
import { colors } from "../../configs/colors";
import { InputMode } from "../../configs/inputMode";
import { PageGuideWithDes, PageTitleWithDes } from "./PageInfoWithDes";
import { CommonSpacer } from "./CommonSpacer";
import { CommonRedButton, CommonWhiteButton } from "./buttons/commonButtons";
import { showToast } from "./baseComponents/BaseComponent";
import PolicyAgreement from "./PolicyAgreement";
import { FullViewURL } from "../../configs/url";

/**
 * 본심사_법인
 */
export interface MainAudit_Corperation_Input {
  FIN_STAT_1: File | null;
  FIN_STAT_2: File | null;
  FIN_STAT_3: File | null;
  SURTAX_1: File | null;
  SURTAX_2: File | null;
  SP_TAX: File | null;
  CORP_TAX: File | null;
  PAY_1: File | null;
  PAY_2: File | null;
  PAY_3: File | null;
  PROOF_TAX_ENT: File | null;
  PROOF_TAX_PRI: File | null;
  LOCAL_TAX_ENT: File | null;
  LOCAL_TAX_PRI: File | null;
  STOCK: File | null;
  WITHOLDING: File | null;
  TAX_CREDIT: File | null;
  [prop: string]: any;
}
export interface MainAudit_Corperation_Input_Id {
  FIN_STAT_1: string | null;
  FIN_STAT_2: string | null;
  FIN_STAT_3: string | null;
  SURTAX_1: string | null;
  SURTAX_2: string | null;
  SP_TAX: string | null;
  CORP_TAX: string | null;
  PAY_1: string | null;
  PAY_2: string | null;
  PAY_3: string | null;
  PROOF_TAX_ENT: string | null;
  PROOF_TAX_PRI: string | null;
  LOCAL_TAX_ENT: string | null;
  LOCAL_TAX_PRI: string | null;
  STOCK: string | null;
  WITHOLDING: string | null;
  TAX_CREDIT: string | null;
  [prop: string]: any;
}
export function MainAudit_Corperation({
  inputMode,
  onClickTmpSave,
  onClickRequest,
  onClickReject,
  onClickSuggestLoan,
  mainAudit_Corperation_Inputs,
  mainAudit_Corperation_Input_Id,
}: {
  inputMode: string;
  onClickTmpSave:
    | ((
        inputs: MainAudit_Corperation_Input,
        ids: MainAudit_Corperation_Input_Id | null,
      ) => void)
    | null;
  onClickRequest:
    | ((
        inputs: MainAudit_Corperation_Input,
        ids: MainAudit_Corperation_Input_Id | null,
      ) => void)
    | null;
  onClickReject: ((inputs: MainAudit_Corperation_Input) => void) | null;
  onClickSuggestLoan: ((inputs: MainAudit_Corperation_Input) => void) | null;
  mainAudit_Corperation_Inputs?: MainAudit_Corperation_Input;
  mainAudit_Corperation_Input_Id?: MainAudit_Corperation_Input_Id;
}) {
  const [checkedAgreement, setCheckedAgreement] = useState(false);
  const [inputs, setInputs] = useState<MainAudit_Corperation_Input>(
    mainAudit_Corperation_Inputs
      ? mainAudit_Corperation_Inputs
      : {
          FIN_STAT_1: null,
          FIN_STAT_2: null,
          FIN_STAT_3: null,
          SURTAX_1: null,
          SURTAX_2: null,
          SP_TAX: null,
          CORP_TAX: null,
          PAY_1: null,
          PAY_2: null,
          PAY_3: null,
          PROOF_TAX_ENT: null,
          PROOF_TAX_PRI: null,
          LOCAL_TAX_ENT: null,
          LOCAL_TAX_PRI: null,
          STOCK: null,
          WITHOLDING: null,
          TAX_CREDIT: null,
        },
  );

  const ApplyServiceMainDescription = `본 심사를 통해 귀사에서 국내 1금융권에서 받을 수 있는 최적의 조건을 연계하여 드립니다.
  블루노멀은 특정은행이 아닌 최적의 조건을 어떠한 편견도 없이 제시합니다.`;

  const ApplyServiceMainGuideList = [
    "대부분의 서류는 귀사에서 기장을 위탁하는 세무사 사무실을 통해 받으실 수 있으며, 세무사 사무실에 서류제출을 위탁하실 수 있습니다.",
    "작성 시 문의사항이 있으신 경우 블루노멀에 문의하여 주시기 바랍니다.",
  ];

  return (
    <MainAuditWrapper>
      {inputMode === InputMode.READ ? (
        ""
      ) : (
        <PageTitleWithDes
          title={"본심사"}
          description={ApplyServiceMainDescription}
        />
      )}

      <CommonFloatingCard>
        <ForWidth>
          <p>재무재표 증명원1</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.FIN_STAT_1 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.FIN_STAT_1 ? inputs.FIN_STAT_1 : undefined}
          />
          <p>재무재표 증명원2</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.FIN_STAT_2 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.FIN_STAT_2 ? inputs.FIN_STAT_2 : undefined}
          />
          <p>재무재표 증명원3</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.FIN_STAT_3 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.FIN_STAT_3 ? inputs.FIN_STAT_3 : undefined}
          />
          <p>부가세 과세표준 증명원1</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.SURTAX_1 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.SURTAX_1 ? inputs.SURTAX_1 : undefined}
          />
          <p>부가세 과세표준 증명원2</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.SURTAX_2 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.SURTAX_2 ? inputs.SURTAX_2 : undefined}
          />
          <p>매출매입처별 세금계산서 합계표</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.SP_TAX = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.SP_TAX ? inputs.SP_TAX : undefined}
          />
          <p>벱인세 신고자료</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.CORP_TAX = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.CORP_TAX ? inputs.CORP_TAX : undefined}
          />
          <p>납부내역 증명서1</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.PAY_1 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.PAY_1 ? inputs.PAY_1 : undefined}
          />
          <p>납부내역 증명서2</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.PAY_2 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.PAY_2 ? inputs.PAY_2 : undefined}
          />
          <p>납부내역 증명서3</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.PAY_3 = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.PAY_3 ? inputs.PAY_3 : undefined}
          />
          <p>국세 완납 증명서(납세증명서)_법인</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.PROOF_TAX_ENT = file;
            }}
            inputMode={inputMode}
            initialFile={
              inputs.PROOF_TAX_ENT ? inputs.PROOF_TAX_ENT : undefined
            }
          />
          <p>국세 완납 증명서(납세증명서)_대표이사 개인</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.PROOF_TAX_PRI = file;
            }}
            inputMode={inputMode}
            initialFile={
              inputs.PROOF_TAX_PRI ? inputs.PROOF_TAX_PRI : undefined
            }
          />
          <p>지방 납세 증명서_법인</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.LOCAL_TAX_ENT = file;
            }}
            inputMode={inputMode}
            initialFile={
              inputs.LOCAL_TAX_ENT ? inputs.LOCAL_TAX_ENT : undefined
            }
          />
          <p>지방 납세 증명서_대표이사 개인</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.LOCAL_TAX_PRI = file;
            }}
            inputMode={inputMode}
            initialFile={
              inputs.LOCAL_TAX_PRI ? inputs.LOCAL_TAX_PRI : undefined
            }
          />
          <p>주식 이동 상황 명세서</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.STOCK = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.STOCK ? inputs.STOCK : undefined}
          />
          <p>원천징수 이행 상황 신고서</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.WITHOLDING = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.WITHOLDING ? inputs.WITHOLDING : undefined}
          />
          <p>세액공제 신청서</p>
          <AttachedPdfInput
            showPreview={false}
            onLoad={(file, src) => {
              inputs.TAX_CREDIT = file;
            }}
            inputMode={inputMode}
            initialFile={inputs.TAX_CREDIT ? inputs.TAX_CREDIT : undefined}
          />
          <CommonSpacer />
          <CommonSpacer />
          {inputMode === InputMode.READ ? (
            <CenterStyle>
              <RowSt>
                <CommonRedButton
                  text="반려하기"
                  onclick={() => {
                    if (onClickReject) {
                      onClickReject(inputs);
                    }
                  }}
                  minWidth="100px"
                />
                <CommonWhiteButton
                  text="대출 정보 입력하기"
                  onclick={() => {
                    if (onClickSuggestLoan) {
                      onClickSuggestLoan(inputs);
                    }
                  }}
                  minWidth="100px"
                />
              </RowSt>
            </CenterStyle>
          ) : (
            ""
          )}
          {inputMode !== InputMode.READ ? (
            <div>
              <PolicyAgreement
                checked={checkedAgreement}
                onChange={setCheckedAgreement}
                url={FullViewURL}
              />
              <RowSt>
                <CommonWhiteButton
                  text="임시 저장"
                  onclick={() => {
                    if (onClickTmpSave) {
                      onClickTmpSave(
                        inputs,
                        mainAudit_Corperation_Input_Id
                          ? mainAudit_Corperation_Input_Id
                          : null,
                      );
                    }
                  }}
                  minWidth="100px"
                />

                <CommonWhiteButton
                  text="본심사 신청하기"
                  onclick={() => {
                    if (!checkedAgreement) {
                      showToast("약관 동의를 확인해주세요", 3000);
                      return;
                    }

                    if (onClickRequest) {
                      onClickRequest(
                        inputs,
                        mainAudit_Corperation_Input_Id
                          ? mainAudit_Corperation_Input_Id
                          : null,
                      );
                    }
                  }}
                  minWidth="100px"
                />
              </RowSt>
            </div>
          ) : (
            ""
          )}
        </ForWidth>
      </CommonFloatingCard>

      {inputMode === InputMode.READ ? (
        ""
      ) : (
        <PageGuideWithDes
          title={"본심사"}
          GuideList={ApplyServiceMainGuideList}
        />
      )}
    </MainAuditWrapper>
  );
}
const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ForWidth = styled.div`
  width: 800px;
`;

const RowSt = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const MainAuditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
