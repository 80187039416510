import { useState } from "react";
import { InputMode } from "../../../configs/inputMode";
import { getEmailCheck } from "../../../utils/login/inputCheck";
import { CommonInput, CommonInputProps } from "./CommonInput";

interface EmailInputProps {
  inputMode: string;
  initialInput?: string;
  onTextChanged: (str: string) => void;
  width: string;
}

export default function EmailInput({
  inputMode,
  width,
  onTextChanged,
  initialInput,
}: EmailInputProps) {
  const emailCheck = getEmailCheck();
  const [errorText, setErrorText] = useState<string | null>("");

  const PhoneNumberOnTextChanged = (str: string) => {
    emailCheck.input = str;
    setErrorText(emailCheck.getErrorMessage());
  };

  const onCustomTextChanged = (str: string) => {
    if (inputMode === InputMode.READ) {
      return;
    }
    onTextChanged(str);
    PhoneNumberOnTextChanged(str);
  };

  return (
    <CommonInput
      inputMode={inputMode}
      initialInput={initialInput}
      title="담당자 이메일"
      onTextChanged={onCustomTextChanged}
      description={"서비스 이용 및 찾기에 이용됩니다."}
      placeHold={null}
      width={width}
      errorText={errorText}
      setClearInput={() => {}}
    />
  );
}
