import styled from "styled-components";
import {
  CommonFloatingCard,
  CommonFloatingWhiteCard,
} from "./CommonFloatingCard";
import { CommonInput, DescriptionStyle } from "./inputs/CommonInput";
import { fontFamily } from "../../configs/font";
import { useEffect, useState } from "react";
import { CommonDropDown, CommonDropDownItem } from "./CommonDropDown";
import CommonDatePicker from "./CommonDatePicker";
import {
  BANK_PRIME,
  COLLATERAL,
  LOAN_PURPOSE,
  LOAN_TYPE,
} from "../../repository/models";
import AttachedPdfInput from "./inputs/AttachedPdfInput";
import { CommonRedButton, CommonWhiteButton } from "./buttons/commonButtons";
import { CommonSpacer } from "./CommonSpacer";
import plusBtnIcon from "../../assets/plusBtnIcon.png";
import { InputMode } from "../../configs/inputMode";
import { showLoading, showToast } from "./baseComponents/BaseComponent";
import { requestPreScreening } from "../../repository/screeningApis";
import { File_Server } from "../../repository/fileApi";
import { date2Strig } from "../../utils/formatUtils";
import { isDebug } from "../../configs/debug";
import { useNavigate } from "react-router-dom";
import { colors } from "../../configs/colors";
import { TitleArea } from "./inputs/CommonInput";
import SearchAddressInput from "./inputs/SearchAddressInput";
import { FullViewURL } from "../../configs/url";
import PolicyAgreement from "./PolicyAgreement";
export interface PreScreeningInput {
  presentName: string;
  presentRecoed: {
    content: string;
    start: Date;
    end: Date;
    uniqueKey: number;
  }[];
  bank: string;
  loanType: string;
  loanDuration: {
    start: Date;
    end: Date;
  };
  loanPurpose: string;
  loanMoney: string;
  loanRate: string;
  pawnType: string;
  pawnPlace: string;
  financialFile1: File | null;
  financialFile2: File | null;
  financialFile3: File | null;
}

/**
 * 사전 심사
 * @param param0
 * @returns
 */
export function PreScreeningComponent({
  inputMode,
  onClickAccept,
  onClickReject,
  onClickSubmit,
  onClickTmpSave,
  preScreeningInput,
  preventBtns,
}: {
  inputMode: string;
  onClickAccept: (inputs: PreScreeningInput) => void;
  onClickReject: (inputs: PreScreeningInput) => void;
  onClickSubmit: (inputs: PreScreeningInput) => void;
  onClickTmpSave: (inputs: PreScreeningInput) => void;
  preScreeningInput?: PreScreeningInput;
  preventBtns?: boolean;
}) {
  const navigate = useNavigate();

  const INIT_DATA = {
    presentName: "",
    presentRecoed: [
      {
        content: "",
        start: new Date(Date.now()),
        end: new Date(Date.now()),
        uniqueKey: Math.random(),
      },
    ],
    bank: "",
    loanType: "",
    loanDuration: {
      start: new Date(Date.now()),
      end: new Date(Date.now()),
    },
    loanPurpose: "",
    loanMoney: "",
    loanRate: "",
    pawnType: "",
    pawnPlace: "",
    financialFile1: null,
    financialFile2: null,
    financialFile3: null,
  };

  const [inputs, setInputs] = useState<PreScreeningInput>(
    preScreeningInput ? preScreeningInput : INIT_DATA,
  );

  const [checkedAgreement, setCheckedAgreement] = useState(false);
  if (isDebug) {
    console.log(inputs);
  }
  const [recordRedraw, setRecordRedraw] = useState<number>(0);
  const [recordUi, setRecordUi] = useState<JSX.Element | null>(null);
  const [loanTypeDropText, setLoanTypeDropText] = useState("대출 유형");
  const [loanTypeDropItem, setLoanTypeDropItem] = useState<
    CommonDropDownItem[]
  >(
    Object.keys(LOAN_TYPE).map((k) => {
      return {
        text: LOAN_TYPE[k],
        onClick: () => {
          inputs.loanType = LOAN_TYPE[k];
          setLoanTypeDropText(LOAN_TYPE[k]);
        },
      };
    }),
  );
  const [loanBankDropText, setLoanBankDropText] = useState("대출 은행");
  const [loanBankDropItem, setLoanBankDropItem] = useState<
    CommonDropDownItem[]
  >(
    Object.keys(BANK_PRIME).map((k) => {
      return {
        text: BANK_PRIME[k],
        onClick: () => {
          inputs.bank = BANK_PRIME[k];
          setLoanBankDropText(BANK_PRIME[k]);
        },
      };
    }),
  );
  const [loanPurposeDropText, setLoanPurposeDropText] = useState("대출 용도");
  const [loanPurposeDropItem, setLoanPurposeDropItem] = useState<
    CommonDropDownItem[]
  >(
    Object.keys(LOAN_PURPOSE).map((k) => {
      return {
        text: LOAN_PURPOSE[k],
        onClick: () => {
          inputs.loanPurpose = LOAN_PURPOSE[k];
          setLoanPurposeDropText(LOAN_PURPOSE[k]);
        },
      };
    }),
  );
  const [collateralDropText, setCollateralDropText] = useState("담보물 유형");
  const [collateralDropItem, setCollateralDropItem] = useState<
    CommonDropDownItem[]
  >(
    Object.keys(COLLATERAL).map((k) => {
      return {
        text: COLLATERAL[k],
        onClick: () => {
          inputs.pawnType = COLLATERAL[k];
          setCollateralDropText(COLLATERAL[k]);
        },
      };
    }),
  );

  //담보물 유형
  const [pawnPlace, setPawnPlace] = useState<string>(
    preScreeningInput ? preScreeningInput.pawnPlace : INIT_DATA.pawnPlace,
  );

  useEffect(() => {
    if (pawnPlace) {
      inputs.pawnPlace = pawnPlace;
    }
  }, [pawnPlace]);

  useEffect(() => {
    const tmpUI: JSX.Element[] = [];
    inputs.presentRecoed.map((item) => {
      const size = tmpUI.length;
      let record = inputs.presentRecoed[size]
        ? inputs.presentRecoed[size]
        : undefined;
      if (inputMode === InputMode.WRITE) {
        record = undefined;
      }
      tmpUI.push(
        <PresentRecordComponent
          inputMode={inputMode}
          initialContent={record ? record.content : undefined}
          initialStart={record ? record.start : undefined}
          initialEnd={record ? record.end : undefined}
          key={item.uniqueKey}
          onTextChanged={(s: string) => {
            item.content = s;
          }}
          onStartDateChanged={(date: Date) => {
            item.start = date;
          }}
          onEndDateChanged={(date: Date) => {
            item.end = date;
          }}
          onDelete={() => {
            inputs.presentRecoed.splice(size, 1);
            setRecordRedraw((recordRedraw + 1) % 10);
          }}
        />,
      );
    });
    setRecordUi(<div>{tmpUI}</div>);
  }, [recordRedraw]);

  useEffect(() => {
    // 읽기 모드일 경우 dropDown 텍스트들 세팅
    if (inputMode === InputMode.READ && preScreeningInput) {
      setLoanTypeDropText(preScreeningInput.loanType);
      setLoanBankDropText(preScreeningInput.bank);
      setLoanPurposeDropText(preScreeningInput.loanPurpose);
      setCollateralDropText(preScreeningInput.pawnType);
    }
  }, []);

  const InputModeReadDes = {
    대출기간: "첫 계약부터 현 시점까지",
    재무재표: "3개년치 재무제표를 업로드해주세요.(pdf)",
  };

  // 대출 금액 , 대출 금리 number 확인 로직
  const isValidNumberInput = (input: string) => {
    if (input === "") {
      return false;
    }

    const WHITE_SPACE_REGEX = /\s/g;
    const numberedInput = Number(input.replace(WHITE_SPACE_REGEX, ""));

    if (Number.isNaN(numberedInput)) {
      return false;
    }

    return true;
  };

  const isValidPawnPlace = (pawnPalce: string) => {
    const WHITE_SPACE_REGEX = /\s/g;
    const removedBlankPawnPlace = pawnPalce.replace(WHITE_SPACE_REGEX, "");

    if (removedBlankPawnPlace === "") {
      return false;
    }

    return true;
  };

  return (
    <CommonFloatingCard>
      <ForWidth>
        <Subtitle>대표이사 정보</Subtitle>
        <CommonInput
          inputMode={inputMode}
          initialInput={inputs.presentName}
          title="대표이사 이름"
          placeHold={null}
          description={null}
          width="100%"
          onTextChanged={(s: string) => {
            inputs.presentName = s;
          }}
          setClearInput={() => {}}
        />
        <CommonSpacer />
        {recordUi ? recordUi : ""}
        {inputMode !== InputMode.READ ? (
          <PlusButtonWrapper>
            <PlusBtnStyle
              src={plusBtnIcon}
              onClick={() => {
                inputs.presentRecoed.push({
                  content: "",
                  start: new Date(Date.now()),
                  end: new Date(Date.now()),
                  uniqueKey: Math.random(),
                });
                setRecordRedraw((recordRedraw + 1) % 100);
              }}
            />
          </PlusButtonWrapper>
        ) : (
          ""
        )}
        <CommonSpacer />

        <Subtitle>은행 / 대출 유형</Subtitle>
        <CommonDropDown
          dropDownTitle="은행"
          inputMode={inputMode}
          text={loanBankDropText}
          items={loanBankDropItem}
          width="100px"
          extraZIndex={100}
        />

        <CommonDropDown
          dropDownTitle="대출 유형"
          inputMode={inputMode}
          text={loanTypeDropText}
          items={loanTypeDropItem}
          width="100px"
          extraZIndex={99}
        />
        <CommonSpacer />
        <CommonSpacer />
        <TitleArea>대출 기간</TitleArea>
        <RowStyle>
          <CommonDatePicker
            inputMode={inputMode}
            initialDate={inputs.loanDuration.start}
            placeHolder="시작 날짜"
            onInput={(date: Date) => {
              if (isDebug) {
                console.log(date);
              }
              inputs.loanDuration.start = date;
            }}
          />
          <p> ~ </p>
          <CommonDatePicker
            inputMode={inputMode}
            initialDate={inputs.loanDuration.end}
            placeHolder="종료 날짜"
            onInput={(date: Date) => {
              if (isDebug) {
                console.log(date);
              }
              inputs.loanDuration.end = date;
            }}
          />
        </RowStyle>
        <DescriptionStyle>{InputModeReadDes["대출기간"]}</DescriptionStyle>

        <CommonSpacer />

        <CommonDropDown
          dropDownTitle="대출 용도"
          inputMode={inputMode}
          text={loanPurposeDropText}
          items={loanPurposeDropItem}
          width="100px"
          extraZIndex={98}
        />
        <CommonSpacer />
        <CommonInput
          inputMode={inputMode}
          initialInput={inputs.loanMoney}
          title="대출 금액"
          placeHold={"숫자만 입력해 주세요"}
          description={null}
          width="100%"
          onTextChanged={(str: string) => {
            inputs.loanMoney = str;
          }}
          setClearInput={() => {}}
          needWon={true}
        />
        <CommonSpacer />
        <CommonInput
          inputMode={inputMode}
          initialInput={inputs.loanRate}
          title="대출 금리"
          placeHold={"숫자만 입력해 주세요"}
          description={null}
          width="100%"
          onTextChanged={(str: string) => {
            inputs.loanRate = str;
          }}
          setClearInput={() => {}}
          needPercent={true}
        />
        <CommonSpacer />
        <CommonSpacer />

        <Subtitle>담보물 정보</Subtitle>

        <CommonDropDown
          dropDownTitle="담보물 유형"
          inputMode={inputMode}
          text={collateralDropText}
          items={collateralDropItem}
          width="120px"
          extraZIndex={97}
        />

        <CompanyPlaceWrapper>
          <CommonInput
            inputMode={inputMode}
            initialInput={pawnPlace}
            title="담보물 위치"
            placeHold={null}
            description={null}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.pawnPlace = str;
            }}
            setClearInput={() => {}}
          />

          {inputMode === InputMode.READ ? (
            ""
          ) : (
            <AddressWrapper>
              <SearchAddressInput
                onFullAddressChanged={(addr: string) => {
                  setPawnPlace(addr);
                }}
              />
            </AddressWrapper>
          )}
        </CompanyPlaceWrapper>
        <CommonSpacer />
        <CommonSpacer />
        <TitleArea>3년치 재무재표</TitleArea>
        <AttachedPdfInput
          inputMode={inputMode}
          showPreview={false}
          onLoad={(file: File, result: string) => {
            inputs.financialFile1 = file;
          }}
          initialFile={
            preScreeningInput?.financialFile1
              ? preScreeningInput?.financialFile1
              : undefined
          }
        />
        <AttachedPdfInput
          inputMode={inputMode}
          showPreview={false}
          onLoad={(file: File, result: string) => {
            inputs.financialFile2 = file;
          }}
          initialFile={
            preScreeningInput?.financialFile2
              ? preScreeningInput?.financialFile2
              : undefined
          }
        />
        <AttachedPdfInput
          inputMode={inputMode}
          showPreview={false}
          onLoad={(file: File, result: string) => {
            inputs.financialFile3 = file;
          }}
          initialFile={
            preScreeningInput?.financialFile3
              ? preScreeningInput?.financialFile3
              : undefined
          }
        />

        <DescriptionStyle>{InputModeReadDes["재무재표"]}</DescriptionStyle>
        <CommonSpacer />
        <CommonSpacer />
        <CommonSpacer />

        {preventBtns ? (
          ""
        ) : (
          <div>
            {inputMode === InputMode.READ ? (
              <RowStyle>
                <CommonWhiteButton
                  text="승인하기"
                  onclick={() => {
                    onClickAccept(inputs);
                  }}
                  minWidth="100px"
                />
                <CommonRedButton
                  text="반려하기"
                  onclick={() => {
                    onClickReject(inputs);
                  }}
                  minWidth="100px"
                />
              </RowStyle>
            ) : (
              ""
            )}

            {inputMode !== InputMode.READ ? (
              <>
                <PolicyAgreement
                  checked={checkedAgreement}
                  onChange={setCheckedAgreement}
                  url={FullViewURL}
                />
                <RowStyle>
                  <CommonWhiteButton
                    text="사전심사 신청하기"
                    onclick={() => {
                      // onClickSubmit(inputs);

                      if (!isValidNumberInput(inputs.loanMoney)) {
                        showToast("대출 금액은 숫자로 입력해주세요.", 3000);
                        return;
                      }

                      if (!isValidNumberInput(inputs.loanRate)) {
                        showToast("대출 금리는 숫자로 입력해주세요.", 3000);
                        return;
                      }
                      if (!isValidPawnPlace(inputs.pawnPlace)) {
                        showToast("담보물 위치를 입력해주세요.", 3000);
                        return;
                      }

                      if (!checkedAgreement) {
                        showToast("약관 동의를 확인해주세요.", 3000);
                        return;
                      }

                      if (isDebug) {
                        console.log(checkedAgreement);
                        console.log(inputs);
                      }

                      _requestPrescreening(inputs, () => {
                        navigate(
                          "/done/사전심사 신청 완료/신청이 정상적으로 완료되었습니다.검토 후 2주 이내에 담당자 이메일로 알람을 보내드리겠습니다",
                        );
                      });
                    }}
                    minWidth="100px"
                  />
                  {/* <CommonRedButton
              text="임시 저장"
              onclick={() => {
                onClickTmpSave(inputs);
              }}
              minWidth="100px"
            /> */}
                </RowStyle>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </ForWidth>
    </CommonFloatingCard>
  );
}

const RowStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
const ForWidth = styled.div`
  width: 500px;
  padding: 20px;
`;

const Subtitle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 22px;
`;

const PlusButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PlusBtnStyle = styled.img`
  width: 20px;
  height: 20px;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const CompanyPlaceWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const AddressWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-bottom: -10px;
`;

function PresentRecordComponent({
  inputMode,
  initialContent,
  initialStart,
  initialEnd,
  onTextChanged,
  onStartDateChanged,
  onEndDateChanged,
  onDelete,
}: {
  inputMode: string;
  initialContent?: string;
  initialStart?: Date;
  initialEnd?: Date;
  onTextChanged: (s: string) => void;
  onStartDateChanged: (date: Date) => void;
  onEndDateChanged: (date: Date) => void;
  onDelete: () => void;
}) {
  return (
    <CommonFloatingWhiteCard>
      <div style={{ width: "450px" }}>
        {inputMode !== InputMode.READ ? (
          <button onClick={() => onDelete()}>삭제</button>
        ) : (
          ""
        )}
        <CommonInput
          inputMode={inputMode}
          initialInput={initialContent}
          title="대표이사 주요이력"
          placeHold={null}
          description={null}
          width="100%"
          onTextChanged={(s: string) => {
            onTextChanged(s);
          }}
          setClearInput={() => {}}
        />
        <CommonSpacer />
        <RowStyle>
          <CommonDatePicker
            inputMode={inputMode}
            initialDate={initialStart}
            placeHolder="시작 날짜"
            onInput={(date: Date) => {
              if (isDebug) {
                console.log(date);
              }
              onStartDateChanged(date);
            }}
          />
          <p>~</p>
          <CommonDatePicker
            inputMode={inputMode}
            initialDate={initialEnd}
            placeHolder="종료 날짜"
            onInput={(date: Date) => {
              if (isDebug) {
                console.log(date);
              }
              onEndDateChanged(date);
            }}
          />
        </RowStyle>
      </div>
    </CommonFloatingWhiteCard>
  );
}

function _requestPrescreening(inputs: PreScreeningInput, gotoDone: () => void) {
  if (inputs.financialFile1 && inputs.financialFile2 && inputs.financialFile3) {
    showLoading(true);
    if (isDebug) {
      console.log(inputs);
    }
    requestPreScreening(
      null,
      null,
      [inputs.financialFile1, inputs.financialFile2, inputs.financialFile3],
      inputs.presentRecoed.map((r) => {
        return {
          content: r.content,
          startDate: date2Strig(r.start),
          endDate: date2Strig(r.end),
        };
      }),
      inputs.bank,
      inputs.loanType,
      date2Strig(inputs.loanDuration.start),
      date2Strig(inputs.loanDuration.end),
      inputs.loanPurpose,
      Number(inputs.loanMoney),
      Number(inputs.loanRate),
      inputs.pawnType,
      inputs.pawnPlace,
    )
      .then(() => {
        gotoDone();
      })
      .catch((e) => {
        showToast("오류가 발생했습니다.", 1000);
      })
      .finally(() => {
        showLoading(false);
      });
  } else {
    showToast("파일을 모두 넣어주세요", 2000);
  }
}
