import styled from "styled-components";
import bottomArrow from "../../assets/bottomArrow.png";
import { fontFamily } from "../../configs/font";
import { title } from "process";
import { CommonFloatingWhiteCard } from "./CommonFloatingCard";
import { useEffect, useState } from "react";
import { zIndex } from "../../configs/zIndex";
import { InputMode } from "../../configs/inputMode";
import { TitleArea } from "./inputs/CommonInput";

export interface CommonDropDownItem {
  text: string;
  onClick: () => void;
}
export function CommonDropDown({
  dropDownTitle,
  inputMode,
  text,
  items,
  width,
  extraZIndex,
}: {
  dropDownTitle?: string;
  inputMode: string;
  text: string;
  items: CommonDropDownItem[];
  width: string;
  extraZIndex: number;
}) {
  const [dropDownFocused, setDropDownFocused] = useState<boolean>(false);
  const [children, setChildren] = useState<JSX.Element | null>(null);
  useEffect(() => {
    if (dropDownFocused) {
      setChildren(
        <ItemsArea
          items={items}
          setFocuseOff={() => {
            setDropDownFocused(false);
          }}
        />,
      );
    } else {
      setChildren(null);
    }
  }, [dropDownFocused, items]);
  const z = zIndex.stikyContent + extraZIndex;
  return (
    <div>
      {dropDownTitle ? <TitleArea>{dropDownTitle}</TitleArea> : ""}
      <StikyAreaStyle style={{ zIndex: z }}>
        <CommonFloatingWhiteCard>
          <RowStyle
            style={{ width: "fit-content" }}
            onClick={() => {
              if (inputMode !== InputMode.READ) {
                setDropDownFocused(!dropDownFocused);
              }
            }}
            onBlur={() => {
              setDropDownFocused(false);
            }}
          >
            <ItemStyle>{text}</ItemStyle>
            <IconStyle src={bottomArrow} />
          </RowStyle>
          {children ? children : null}
        </CommonFloatingWhiteCard>
      </StikyAreaStyle>
      <Spacer />
    </div>
  );
}

const StikyAreaStyle = styled.div`
  position: absolute;
  z-index: ${(props) =>
    props.style?.zIndex ? props.style?.zIndex : zIndex.stikyContent};
`;

const IconStyle = styled.img`
  width: 15px;
  height: 15px;
`;

const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: ${(props) =>
    props.style?.width ? props.style?.width : "fit-content"};
`;
const Spacer = styled.div`
  height: 65px;
`;

const ItemStyle = styled.div`
  width: 100%;
  padding: 8px;
  font-family: ${fontFamily.Medium};
  font-style: 16;
  &:hover {
    cursor: pointer;
  }
`;

function ItemsArea({
  items,
  setFocuseOff,
}: {
  items: CommonDropDownItem[];
  setFocuseOff: () => void;
}) {
  const [e, setE] = useState<JSX.Element[]>([]);
  useEffect(() => {
    let index = 0;
    const constents: JSX.Element[] = [];
    items.map((item) => {
      const tmp = (
        <ItemStyle
          onClick={() => {
            item.onClick();
            setFocuseOff();
          }}
          key={index++}
        >
          {item.text}
        </ItemStyle>
      );
      constents.push(tmp);
    });
    setE(constents);
  }, [items]);
  return <div>{e}</div>;
}
