import { useParams } from "react-router-dom";
import { CommonDoneUI } from "../components/CommonDoneUi";
import styled from "styled-components";

export function DonePage() {
  const params = useParams();
  const title = params.title ? params.title : "";
  const des = params.description ? params.description : "";
  return (
    <MaxWidth>
      <CommonDoneUI title={title} description={des} />
    </MaxWidth>
  );
}

const MaxWidth = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8%;
`;
