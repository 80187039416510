/***은행에서 서류들 받아올 때 파일 두번씩 받아오는 현상 개선하기 위한 api */

import { parseDateForServer } from "../utils/dateUtils";
import { dataURLtoFile } from "../utils/fileUtils";
import { MainAudit_Corperation_Input } from "../views/components/MainAudit_Corperation";
import { MainAudit_Personal_Input } from "../views/components/MainAudit_Personal";
import { PreScreeningInput } from "../views/components/PreScreening";
import { RegisterCompanyInputs } from "../views/pages/Register/RegisterCompanyPage";
import { getCompanyInfo_BLUENORMAL } from "./agentApi";
import { downLoadFile, File_Server } from "./fileApi";
import {
  getLoanInfoById,
  ServerLoanDetailFileInfo,
  ServerLoanDetailInfo,
} from "./loanApi";
import {
  BANK_PRIME,
  COLLATERAL,
  LOAN_PURPOSE,
  LOAN_TYPE,
  REF_DOC_TYPE,
} from "./models";

export function getMainScreeningCorperationAndPre(
  loanId: string,
): Promise<{ pre: PreScreeningInput; mainCor: MainAudit_Corperation_Input }> {
  const mainAuditInfo: MainAudit_Corperation_Input = {
    FIN_STAT_1: null,
    FIN_STAT_2: null,
    FIN_STAT_3: null,
    SURTAX_1: null,
    SURTAX_2: null,
    SP_TAX: null,
    CORP_TAX: null,
    PAY_1: null,
    PAY_2: null,
    PAY_3: null,
    PROOF_TAX_ENT: null,
    PROOF_TAX_PRI: null,
    LOCAL_TAX_ENT: null,
    LOCAL_TAX_PRI: null,
    STOCK: null,
    WITHOLDING: null,
    TAX_CREDIT: null,
  };
  const preScrinfo: PreScreeningInput = {
    presentName: "",
    presentRecoed: [],
    bank: "",
    loanType: "",
    loanDuration: {
      start: new Date(),
      end: new Date(),
    },
    loanPurpose: "",
    loanMoney: "",
    loanRate: "",
    pawnType: "",
    pawnPlace: "",
    financialFile1: null,
    financialFile2: null,
    financialFile3: null,
  };
  return getLoanInfoById(loanId).then((value: ServerLoanDetailInfo) => {
    value.loanRefReq.resume.forEach((item) => {
      preScrinfo.presentRecoed.push({
        content: item.content,
        start: new Date(parseDateForServer(item.startDate)),
        end: new Date(parseDateForServer(item.endDate)),
        uniqueKey: Math.random(),
      });
    });
    preScrinfo.bank = BANK_PRIME[value.prevLoanInfo.loanBankCd];
    preScrinfo.loanType = LOAN_TYPE[value.prevLoanInfo.loanType];
    preScrinfo.loanDuration.start = new Date(
      parseDateForServer(value.prevLoanInfo.loanStartDt),
    );
    preScrinfo.loanDuration.end = new Date(
      parseDateForServer(value.prevLoanInfo.loanEndDt),
    );
    preScrinfo.loanPurpose = LOAN_PURPOSE[value.prevLoanInfo.loanPurpose];
    preScrinfo.loanMoney = value.prevLoanInfo.loanAmount.toString();
    preScrinfo.loanRate = value.prevLoanInfo.interestRate.toString();
    preScrinfo.pawnType = COLLATERAL[value.prevLoanInfo.collateralType];
    preScrinfo.pawnPlace = value.prevLoanInfo.collateralLocation;

    return getCompanyInfo_BLUENORMAL(value.loanRefReq.cpId).then(
      (result: RegisterCompanyInputs) => {
        preScrinfo.presentName = result.ceoNm ? result.ceoNm : "이름 없음";

        const filePromise: Promise<File_Server>[] = [];
        value.loanRefFiles.forEach((item) => {
          filePromise.push(downLoadFile(item.fileId.toString()));
        });
        const loadFiles = filePromise.map((p: Promise<File_Server>) => {
          return p.then((f: File_Server) => {
            return dataURLtoFile(
              f.file,
              REF_DOC_TYPE[f.fileType] + ".pdf",
            ).then((file: File) => {
              if (REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_1) {
                preScrinfo.financialFile1 = file;
              } else if (
                REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_2
              ) {
                preScrinfo.financialFile2 = file;
              } else if (
                REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_3
              ) {
                preScrinfo.financialFile3 = file;
              } else {
                mainAuditInfo[f.fileType] = file;
              }
              return true;
            });
          });
        });
        return Promise.all(loadFiles).then(() => {
          return { pre: preScrinfo, mainCor: mainAuditInfo };
        });
        // return Promise.all(final).then((r: boolean[]) => {
        //   return preScrinfo;
        // });
      },
    );
  });
}

export function getMainScreeningPersonalAndPre(
  loanId: string,
): Promise<{ pre: PreScreeningInput; mainPer: MainAudit_Personal_Input }> {
  return getLoanInfoById(loanId).then((value: ServerLoanDetailInfo) => {
    const mainAuditInfo: MainAudit_Personal_Input = {
      FIN_TRANS: null,
      PROOF_TAX_PROP: null,
      PROOF_TAX_PROP_BY: null,
      LEASE: null,
      RENT: null,
      REGISTRATION: null,
      isSamePerson: value.loanRefReq.collProvEqual === "Y",
      PROOF_TAX_PROP_P: null,
      PROOF_TAX_PROP_BY_P: null,
      LOCAL_TAX_P_1: null,
      LOCAL_TAX_P_2: null,
      LOCAL_TAX_P_3: null,
      E_LOCAL_TAX_P_1: null,
      E_LOCAL_TAX_P_2: null,
      E_LOCAL_TAX_P_3: null,
    };
    const preScrinfo: PreScreeningInput = {
      presentName: "",
      presentRecoed: [],
      bank: "",
      loanType: "",
      loanDuration: {
        start: new Date(),
        end: new Date(),
      },
      loanPurpose: "",
      loanMoney: "",
      loanRate: "",
      pawnType: "",
      pawnPlace: "",
      financialFile1: null,
      financialFile2: null,
      financialFile3: null,
    };
    value.loanRefReq.resume.forEach((item) => {
      preScrinfo.presentRecoed.push({
        content: item.content,
        start: new Date(parseDateForServer(item.startDate)),
        end: new Date(parseDateForServer(item.endDate)),
        uniqueKey: Math.random(),
      });
    });
    preScrinfo.bank = BANK_PRIME[value.prevLoanInfo.loanBankCd];
    preScrinfo.loanType = LOAN_TYPE[value.prevLoanInfo.loanType];
    preScrinfo.loanDuration.start = new Date(
      parseDateForServer(value.prevLoanInfo.loanStartDt),
    );
    preScrinfo.loanDuration.end = new Date(
      parseDateForServer(value.prevLoanInfo.loanEndDt),
    );
    preScrinfo.loanPurpose = LOAN_PURPOSE[value.prevLoanInfo.loanPurpose];
    preScrinfo.loanMoney = value.prevLoanInfo.loanAmount.toString();
    preScrinfo.loanRate = value.prevLoanInfo.interestRate.toString();
    preScrinfo.pawnType = COLLATERAL[value.prevLoanInfo.collateralType];
    preScrinfo.pawnPlace = value.prevLoanInfo.collateralLocation;

    return getCompanyInfo_BLUENORMAL(value.loanRefReq.cpId).then(
      (result: RegisterCompanyInputs) => {
        preScrinfo.presentName = result.ceoNm ? result.ceoNm : "이름 없음";

        const filePromise: Promise<File_Server>[] = [];
        value.loanRefFiles.forEach((item) => {
          filePromise.push(downLoadFile(item.fileId.toString()));
        });
        const loadFiles = filePromise.map((p: Promise<File_Server>) => {
          return p.then((f: File_Server) => {
            return dataURLtoFile(
              f.file,
              REF_DOC_TYPE[f.fileType] + ".pdf",
            ).then((file: File) => {
              if (REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_1) {
                preScrinfo.financialFile1 = file;
              } else if (
                REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_2
              ) {
                preScrinfo.financialFile2 = file;
              } else if (
                REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_3
              ) {
                preScrinfo.financialFile3 = file;
              } else {
                mainAuditInfo[f.fileType] = file;
              }
              return true;
            });
          });
        });
        return Promise.all(loadFiles).then(() => {
          console.log(mainAuditInfo);
          return { pre: preScrinfo, mainPer: mainAuditInfo };
        });
        // return Promise.all(final).then((r: boolean[]) => {
        //   return preScrinfo;
        // });
      },
    );
  });
}
