import { useState } from "react";
import { getPhoneNumberCheck } from "../../../utils/login/inputCheck";
import { CommonInput } from "./CommonInput";
import { InputMode } from "../../../configs/inputMode";

interface PhoneNumberInputProps {
  inputMode: string;
  description?: string;
  errorText?: string;
  width: string;
  onTextChanged: (str: string) => void;
  initialInput?: string;
}
/**
 *  description ? description : "정확한 휴대전화 번호를 입력해 주세요"
 *
 *
 *
 */
export function PhoneNumberInput({
  inputMode,
  description,
  width,
  onTextChanged,
  initialInput,
}: PhoneNumberInputProps) {
  const checkPhoneNumber = getPhoneNumberCheck();
  const [errorText, setErrorText] = useState<string | null>("");

  const PhoneNumberOnTextChanged = (str: string) => {
    checkPhoneNumber.input = str;
    setErrorText(checkPhoneNumber.getErrorMessage());
  };

  const onCustomTextChanged = (str: string) => {
    if (inputMode === InputMode.READ) {
      return;
    }
    onTextChanged(str);
    PhoneNumberOnTextChanged(str);
  };

  return (
    <CommonInput
      inputMode={inputMode}
      title={"담당자 휴대전화 번호"}
      placeHold={null}
      description={
        description || description === ""
          ? description
          : "정확한 휴대전화 번호를 입력해 주세요"
      }
      width={width}
      onTextChanged={onCustomTextChanged}
      errorText={errorText}
      initialInput={initialInput}
      setClearInput={(clearInputFunction: () => void) => {}}
    />
  );
}
