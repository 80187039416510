import { url } from "inspector";
import { getLoginedUser } from "../configs/user";
import { callWithJwt } from "./baseApi";
import { File_Server, Resume_Server, upLoadFile } from "./fileApi";
import {
  BANK_PRIME,
  COLLATERAL,
  LOAN_PURPOSE,
  LOAN_TYPE,
  USER_TYPE,
} from "./models";
import { isDebug } from "../configs/debug";
import { file2Base64 } from "../utils/fileUtils";
import { value2Type } from "../utils/convertUtils";

function getTaxAgentCompanyId(): Promise<string> {
  const loginedUser = getLoginedUser();
  const url = "/api/user/agent/" + loginedUser?.userId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      return data["cpId"];
    } catch (e) {
      throw e;
    }
  });
}
function getCompanyId(): Promise<string> {
  const loginedUser = getLoginedUser();
  if (loginedUser?.userType === "COMPANY") {
    return new Promise((accept, reject) => {
      accept(loginedUser?.userId);
    });
  }
  if (loginedUser?.userType === "AGENT") {
    return getTaxAgentCompanyId();
  } else {
    return new Promise((accept, reject) => {
      reject("접근할 수 없는 계정 타입입니다.");
    });
  }
}

/** 임시저장된 데이터일 경우 loanReqId를 넣고 처음 신청이라면 null을 넣는다 (사전심사는 회사 계정만 신청할 수 있다)*/
export function requestPreScreening(
  loanReqId: number | null,
  prevLoanInfoReqId: number | null,
  files: File[],
  resumes: Resume_Server[],
  loanBankNm: string,
  loanType: string,
  loanStartDt: string,
  loanEndDt: string,
  loanPurpose: string,
  loanAmount: number,
  interestRate: number,
  collateralType: string,
  collateralLocation: string,
): Promise<boolean> {
  return requestPrescreeningWithoutFile(
    loanReqId,
    prevLoanInfoReqId,
    resumes,
    loanBankNm,
    loanType,
    loanStartDt,
    loanEndDt,
    loanPurpose,
    loanAmount,
    interestRate,
    collateralType,
    collateralLocation,
  ).then((result: { loanId: number; cpId: string }) => {
    return upLoadPreScreeningFiles(files, result.loanId, result.cpId).then(
      () => {
        return true;
      },
    );
  });
}

function upLoadPreScreeningFiles(
  files: File[],
  loanId: number,
  cpId: string,
): Promise<any> {
  let index = 0;
  let fileTypes = ["FIN_STAT_REF_1", "FIN_STAT_REF_2", "FIN_STAT_REF_3"];
  const serverFiles: Promise<File_Server>[] = files.map((f: File) => {
    return file2Base64(f).then((fStr: string) => {
      return {
        fileId: null,
        loanReqId: loanId,
        fileType: fileTypes[index++],
        file: fStr,
      };
    });
  });
  const fileUpLoads: Promise<any>[] = serverFiles.map(
    (fs: Promise<File_Server>) => {
      return fs.then((result: File_Server) => {
        return upLoadFile(result, cpId);
      });
    },
  );
  return Promise.all(fileUpLoads)
    .then(() => {
      return true;
    })
    .catch(() => {
      throw new Error("파일 업로드 에러");
    });
}

function requestPrescreeningWithoutFile(
  loanReqId: number | null,
  prevLoanInfoReqId: number | null,
  resumes: Resume_Server[],
  loanBankNm: string,
  loanType: string,
  loanStartDt: string,
  loanEndDt: string,
  loanPurpose: string,
  loanAmount: number,
  interestRate: number,
  collateralType: string,
  collateralLocation: string,
): Promise<{ cpId: string; loanId: number }> {
  return getCompanyId().then((cpId: string) => {
    const resumeObject: any = {};
    let index = 0;
    resumes.forEach((item) => {
      resumeObject[index.toString()] = item;
      index += 1;
    });
    const url = "/api/loan/ref/req/prev";
    const method = "post";
    const loanRefReq: any = {
      cpId: cpId,
      regiId: cpId,
      preApplyId: cpId,
      collProvEqual: "Y",
      resume: JSON.stringify(resumeObject),
    };
    const prevLoanInfo: any = {
      loanBankCd: value2Type(BANK_PRIME, loanBankNm),
      loanType: value2Type(LOAN_TYPE, loanType),
      loanStartDt: loanStartDt,
      loanEndDt: loanEndDt,
      loanPurpose: value2Type(LOAN_PURPOSE, loanPurpose),
      loanAmount: loanAmount,
      interestRate: interestRate,
      collateralType: value2Type(COLLATERAL, collateralType),
      collateralLocation: collateralLocation,
    };
    if (loanReqId) {
      loanRefReq.loanReqId = loanReqId;
    }
    if (prevLoanInfoReqId) {
      prevLoanInfo.loanReqId = prevLoanInfoReqId;
    }
    const body = JSON.stringify({
      loanRefReq: loanRefReq,
      prevLoanInfo: prevLoanInfo,
    });
    return callWithJwt(url, method, body).then((json) => {
      try {
        // todo 김기태 -> loanId 받아오는 인자 맞는지 확인해야 함
        const loanId = json["data"]["data"]["loanReqId"];
        return { cpId: cpId, loanId: loanId };
      } catch (e) {
        if (isDebug) {
          console.log("파일 업로드 전 사전심사에서 에라");
        }
        throw e;
      }
    });
  });
}
