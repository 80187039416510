import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";
import { fontFamily } from "./configs/font";
import { isSafeExitTime, saveExitTime } from "./utils/localStorageUtils";

const GlobalStyle = createGlobalStyle`
  &::-webkit-scrollbar {
    display: none;
  }
  font-family: ${fontFamily.Medium};
`;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
window.addEventListener("beforeunload", (ev) => {
  saveExitTime();
});
if (!isSafeExitTime()) {
  localStorage.clear();
}
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
