import { useNavigate } from "react-router-dom";
import { tryLogin } from "../../configs/user";
import { USER_TYPE } from "../../repository/models";
import { useEffect, useState } from "react";
import { CommonFloatingCard } from "../components/CommonFloatingCard";
import { CommonInput } from "../components/inputs/CommonInput";
import {
  CommonBlueButton,
  CommonOnlyTextButton,
} from "../components/buttons/commonButtons";
import { showToast } from "../components/baseComponents/BaseComponent";
import styled from "styled-components";

import { CommonSpacer } from "../components/CommonSpacer";
import logo from "../../assets/logo.png";

import CommonTab from "../components/CommonTab";
import { isDebug } from "../../configs/debug";
import { CommonTabItem } from "../components/CommonTab";
import { InputMode } from "../../configs/inputMode";
import { PassWordInput } from "../components/inputs/PassWordInput";

interface LoginInputs {
  id: string;
  pw: string;
  userType: string;
}

export function LoginPage() {
  const navigate = useNavigate();

  // 맨 처음 UserType 0번째 인덱스로 초기화 ex) "기업"
  const INIT_INPUTS = {
    id: "",
    pw: "",
    userType: `${Object.keys(USER_TYPE)[0]}`,
  };
  const [inputs, setInputs] = useState<LoginInputs>(INIT_INPUTS);

  const [clickedTabIdx, setClickedTabIdx] = useState(0);
  const [userType, setUserType] = useState<CommonTabItem[]>(
    Object.keys(USER_TYPE).map((key, idx) => {
      return {
        text: USER_TYPE[key],
        onClick: () => {
          setClickedTabIdx(idx);
          inputs.userType = key;
        },
      };
    }),
  );

  if (isDebug) {
    console.log(inputs.userType);
  }
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <CenterStyle>
      <CommonSpacer />
      <CommonSpacer />
      <CommonSpacer />
      <CommonSpacer />

      <CenterStyle>
        <Logo src={logo}></Logo>
        <PageTitle>로그인</PageTitle>
        <SubDescrition>
          제조금융의 혁신 블루노멀에 오신것을 환영합니다.
        </SubDescrition>
      </CenterStyle>

      <Wrapper>
        <CommonTab items={userType} clickedTabIdx={clickedTabIdx} />
        <CommonFloatingCard>
          <ForWidth>
            <CommonInput
              inputMode={InputMode.WRITE}
              title="아이디"
              placeHold={null}
              description={null}
              width="100%"
              onTextChanged={(str: string) => {
                inputs.id = str;
              }}
              setClearInput={() => {}}
            />

            <PassWordInput
              needRegex={false}
              inputMode={InputMode.WRITE}
              width="100%"
              onTextChanged={(str: string) => {
                inputs.pw = str;
              }}
            />

            <CommonSpacer />
            <CommonSpacer />
            <CenterStyle>
              <CommonBlueButton
                text="로그인"
                onclick={() => {
                  tryLogin(
                    inputs.id,
                    inputs.pw,
                    inputs.userType,
                    () => {
                      navigate("/");
                    },
                    () => {
                      showToast("로그인에 실패하였습니다", 2000);
                    },
                    false,
                    true,
                  );
                }}
                minWidth="100px"
              />
              <CommonSpacer />
              <CommonSpacer />
            </CenterStyle>

            {/* Button */}
            <ButtonWrapper>
              <CommonOnlyTextButton
                text="회원가입"
                onclick={() => {
                  navigate("/registerCompany");
                }}
              />
              /
              <CommonOnlyTextButton
                text="비밀번호 찾기"
                onclick={() => {
                  navigate("/forgotPassWord");
                }}
              />
            </ButtonWrapper>
          </ForWidth>
        </CommonFloatingCard>
      </Wrapper>
    </CenterStyle>
    // <button
    //   onClick={() => {
    //     tryLogin(
    //       "123",
    //       "123",
    //       USER_TYPE.BLUENORMAL,
    //       () => {
    //         navigate("/");
    //       },
    //       () => {},
    //     );
    //   }}
    // >
    //   임시 로그인 버튼
    // </button>
  );
}

const ForWidth = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 * Wrapper 속성 복사 후 width 프로퍼티 추가
 */
const CenterStyle = styled(Wrapper)`
  width: 100%;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  font-size: 60px;
  margin: 0px;
  margin-top: 30px;
`;
const SubDescrition = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin: 0px;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  width: 300px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
