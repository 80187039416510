import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../configs/user";

export function LogoutPage() {
  const navigate = useNavigate();
  useEffect(() => {
    logout(() => {
      navigate("/");
    });
  }, []);

  return <div>로그아웃중</div>;
}
