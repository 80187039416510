import { SideBar, SideBarItem } from "../../../components/sideBar/SideBar";
import manageLoan from "../../../../assets/sideBarIcons/company//manageLoan.png";
import manageAgent from "../../../../assets/sideBarIcons/company//manageAgent.png";
import myPage from "../../../../assets/sideBarIcons/company//myPage.png";
import manageLoan_x from "../../../../assets/sideBarIcons/company//manageLoan_x.png";
import manageAgent_x from "../../../../assets/sideBarIcons/company//manageAgent_x.png";
import myPage_x from "../../../../assets/sideBarIcons/company//myPage_x.png";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { zIndex } from "../../../../configs/zIndex";
import { ManageCompanyPage } from "../ManageCompanyPage";
import { ManageLoanTax } from "./ManageLoanTax";
import { ManageAgentTax } from "./ManageAgentTax";

export const MyPageIndex = {
  Unselected: "-1",
  MyPage: "0",
  ManageLoan: "2",
  ManageAgent: "3",
};

export function TaxMyPage() {
  const [step, setStep] = useState(MyPageIndex.MyPage);
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    switch (step) {
      case MyPageIndex.Unselected:
        setContent(null);
        break;
      case MyPageIndex.MyPage:
        setContent(<ManageCompanyPage />);
        break;
      case MyPageIndex.ManageLoan:
        setContent(<ManageLoanTax />);
        break;
      case MyPageIndex.ManageAgent:
        setContent(<ManageAgentTax />);
        break;
    }
  }, [step]);

  return (
    <SeparateStyle>
      <StickyStyle>
        <SideBar
          items={getItems(
            step ? step : MyPageIndex.Unselected,
            (step: string) => {
              setStep(step);
            },
          )}
        />
      </StickyStyle>
      <ContentStyle>{content ? content : ""}</ContentStyle>
    </SeparateStyle>
  );
}

const SeparateStyle = styled.div`
  display: flex;
`;
const StickyStyle = styled.div`
  position: fixed;
  top: 25%;
  z-index: ${zIndex.stikyContent};
  width: 15%;
  height: 50%;
`;
const ContentStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

function getItems(
  step: string,
  onClickStep: (step: string) => void,
): SideBarItem[] {
  const items: SideBarItem[] = [];
  items.push({
    text: "마이페이지",
    imgSrc: myPage,
    imgSrc_Selected: myPage_x,
    isSelected: step === MyPageIndex.MyPage,
    onClick: () => {
      onClickStep(MyPageIndex.MyPage);
    },
  });
  items.push({
    text: "대출 비교 관리하기",
    imgSrc: manageLoan,
    imgSrc_Selected: manageLoan_x,
    isSelected: step === MyPageIndex.ManageLoan,
    onClick: () => {
      onClickStep(MyPageIndex.ManageLoan);
    },
  });
  items.push({
    text: "담당자 관리",
    imgSrc: manageAgent,
    imgSrc_Selected: manageAgent_x,
    isSelected: step === MyPageIndex.ManageAgent,
    onClick: () => {
      onClickStep(MyPageIndex.ManageAgent);
    },
  });

  return items;
}
