import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonDoneUI } from "../../../components/CommonDoneUi";
import { CommonFloatingCard } from "../../../components/CommonFloatingCard";
import { CommonInput } from "../../../components/inputs/CommonInput";
import {
  CommonBlueButton,
  CommonRedButton,
} from "../../../components/buttons/commonButtons";
import { getLoginedUser, logout } from "../../../../configs/user";
import { checkPassword } from "../../../../repository/userApi";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import styled from "styled-components";
import { deleteTaxAgent, makeTaxAgent } from "../../../../repository/agentApi";
import { fontFamily } from "../../../../configs/font";
import { InputMode } from "../../../../configs/inputMode";
import { PassWordInput } from "../../../components/inputs/PassWordInput";
import { PassWordCheckInput } from "../../../components/inputs/PassWordInputCheck";

const STEP = {
  password: "password",
  createTaxId: "createTaxId",
  doneCreate: "doneCreate",
  doneDelete: "doneDelete",
};
const width = "350px";

export function ManageTaxPage() {
  const [step, setStep] = useState<string>(STEP.password);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    switch (step) {
      case STEP.password:
        setContent(
          <PasswordCard
            setNextStep={() => {
              setStep(STEP.createTaxId);
            }}
          />,
        );
        break;
      case STEP.createTaxId:
        setContent(
          <CreateTaxIdCard
            setCreateDoneStep={() => {
              setStep(STEP.doneCreate);
            }}
            setDeleteDoneStep={() => {
              setStep(STEP.doneDelete);
            }}
          />,
        );

        break;
      case STEP.doneCreate:
        setContent(<GotoDonePageCreate />);
        break;
      case STEP.doneDelete:
        setContent(<GotoDonePageDelete />);
        break;
    }
  }, [step]);
  return <div>{content}</div>;
}

function GotoDonePageCreate() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      "/done/세무대리인 ID 생성 완료/세무대리인 아이디가 성공적으로 생성되었습니다.",
    );
  }, []);
  return <div></div>;
}
function GotoDonePageDelete() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      "/done/세무대리인 ID 삭제 완료/세무대리인 아이디가 성공적으로 삭제 되었습니다.",
    );
  }, []);
  return <div></div>;
}

function PasswordCard({ setNextStep }: { setNextStep: () => void }) {
  const navigate = useNavigate();
  let password = "";
  let clearInput: (() => void) | null = null;
  return (
    <CommonFloatingCard>
      <PassWordInput
        needRegex={false}
        inputMode={InputMode.WRITE}
        width="350px"
        onTextChanged={(str) => {
          password = str;
        }}
      />

      <SpaceStyle />
      <CenterStyle>
        <CommonBlueButton
          minWidth="100px"
          text="비밀번호 확인"
          onclick={() => {
            const loginedUser = getLoginedUser();
            if (loginedUser) {
              checkPassword(loginedUser, password)
                .then((result) => {
                  if (result) {
                    setNextStep();
                  } else {
                    showToast("비밀번호가 일치하지 않습니다.", 3000);
                  }
                })
                .catch(() => {
                  showToast("오류가 발생 했습니다.", 3000);
                });
            } else {
              logout(() => {
                navigate("/");
              });
            }
          }}
        />
      </CenterStyle>
    </CommonFloatingCard>
  );
}
const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
const SpaceStyle = styled.div`
  width: 1px;
  height: 20px;
`;

export interface ManageTaxAgentInfo {
  id: string;
  pw: string;
}
function CreateTaxIdCard({
  setCreateDoneStep,
  setDeleteDoneStep,
  taxAgentInfo,
}: {
  setCreateDoneStep: () => void;
  setDeleteDoneStep: () => void;
  taxAgentInfo?: ManageTaxAgentInfo;
}) {
  const [input, inputs] = useState<ManageTaxAgentInfo>(
    taxAgentInfo
      ? taxAgentInfo
      : {
          id: getLoginedUser()?.userId + "_세무",
          pw: "",
        },
  );
  const [checkPW, setCheclPW] = useState<string | null>(null);
  return (
    <div>
      <CommonFloatingCard>
        <CommonInput
          inputMode={InputMode.READ}
          initialInput={input.id}
          title="세무대리인 로그인 아이디"
          onTextChanged={(s) => {}}
          setClearInput={() => {}}
          description={null}
          placeHold={null}
          width={width}
        />
        <SpaceStyle />

        <PassWordInput
          needRegex={true}
          inputMode={InputMode.RW}
          width={width}
          onTextChanged={(str) => {
            input.pw = str;
          }}
          title="세무대리인 임시 비밀번호"
          description={
            "세무대리인 임시 비밀번호는 세무대리인이 변경 가능합니다."
          }
        />

        <SpaceStyle />
        <PassWordCheckInput
          inputMode={InputMode.WRITE}
          width={width}
          pwValueForCheck=""
          title="임시 비밀번호 확인"
        />

        <SpaceStyle />
        <CenterStyle>
          <CommonBlueButton
            minWidth="100px"
            text="세무대리인 계정 생성"
            onclick={() => {
              showLoading(true);
              makeTaxAgent(input.id, input.pw)
                .then((r: boolean) => {
                  if (r) {
                    setCreateDoneStep();
                  }
                })
                .catch((e) => {
                  showToast("기존 세무대리인이 존재합니다", 2000);
                })
                .finally(() => {
                  showLoading(false);
                });
            }}
          />
        </CenterStyle>
      </CommonFloatingCard>
      <SpaceStyle />
      <CenterStyle>
        <DStyle>세무사무소가 변경 되었나요?</DStyle>
        <CommonRedButton
          minWidth="100px"
          text="기존 세무대리인 삭제하기"
          onclick={() => {
            showLoading(true);
            const cpId = getLoginedUser()?.userId;
            deleteTaxAgent(cpId ? cpId : "")
              .then((r: boolean) => {
                if (r) {
                  setDeleteDoneStep();
                }
              })
              .catch((e) => {
                showToast("세무대리인 삭제 실패", 2000);
              })
              .finally(() => {
                showLoading(false);
              });
          }}
        />
        <DStyle>
          세무 담당자 정보 변경은 세무사 계정 내에서 가능합니다.
          <br />
          담당자 변경 문의는 세무사무소를 통해 진행해주세요.
        </DStyle>
      </CenterStyle>
    </div>
  );
}

const DStyle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 12px;
`;
