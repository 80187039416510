import { CommonFloatingCard } from "../components/CommonFloatingCard";
import { InputMode } from "../../configs/inputMode";
import { CommonInput } from "../components/inputs/CommonInput";
import { useState } from "react";
import { styled } from "styled-components";
import { CommonBlueButton } from "../components/buttons/commonButtons";
import { fontFamily } from "../../configs/font";
import { colors } from "../../configs/colors";
import { getPhoneNumberCheck } from "../../utils/login/inputCheck";
import { isDebug } from "../../configs/debug";
import { useEffect } from "react";
import { showToast } from "../components/baseComponents/BaseComponent";
import { showLoading } from "../components/baseComponents/BaseComponent";
import { useNavigate } from "react-router-dom";
import { requestTmpPassword } from "../../repository/userApi";
import { PhoneNumberInput } from "../components/inputs/PhoneNumberInput";

interface ForgotPassWordInputs {
  id: string;
  agentName: string;
  agentPhoneNumber: string;
}

interface ForgotPassWordInputsErrorText {
  id: string | null;
  agentName: string | null;
  agentPhoneNumber: string | null;
}
/**
 * 비밀번호 찾기 페이지
 * @returns
 */
export function ForgotPassWordPage() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState<ForgotPassWordInputs>({
    id: "",
    agentName: "",
    agentPhoneNumber: "",
  });

  const [errorText, setErrorText] = useState<ForgotPassWordInputsErrorText>({
    id: "",
    agentName: "",
    agentPhoneNumber: "",
  });

  const checkPhoneNumber = getPhoneNumberCheck();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Center>
      <PageTitle>비밀번호 찾기</PageTitle>
      <SubDescrition>
        정보를 입력하시면, 회원가입 시 기입한{" "}
        <span className="emphasize">담당자 이메일</span>로 임시비밀번호를
        전달드립니다.
      </SubDescrition>

      <CommonFloatingCard>
        <ParentStyle>
          <CommonInput
            inputMode={InputMode.WRITE}
            title="아이디"
            placeHold={null}
            description={null}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.id = str;
            }}
            setClearInput={() => {}}
          />

          <CommonInput
            inputMode={InputMode.WRITE}
            title="담당자 이름"
            placeHold={null}
            description={null}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.agentName = str;
            }}
            setClearInput={(clearInputFunction: () => void) => {}}
          />

          <PhoneNumberInput
            inputMode={InputMode.WRITE}
            description={""}
            width="100%"
            onTextChanged={(str: string) => {
              inputs.agentPhoneNumber = str;
            }}
          />

          <ButtonWrapper>
            <CommonBlueButton
              text="임시 비밀번호 발급"
              onclick={() => {
                showLoading(true);

                if (isDebug) {
                  console.log(inputs);
                }
                requestTmpPassword(
                  inputs.id,
                  inputs.agentName,
                  inputs.agentPhoneNumber,
                )
                  .then((r: boolean) => {
                    if (r) {
                      navigate(
                        "/done/임시비밀번호 발급 완료/담당자 이메일로 임시 비밀번호가 발급되었습니다.",
                      );
                    }
                  })
                  .catch((e) => {
                    showToast("오류가 발생했습니다.", 2000);
                  })
                  .finally(() => {
                    showLoading(false);
                  });
              }}
              minWidth="100px"
            />
          </ButtonWrapper>
        </ParentStyle>
      </CommonFloatingCard>
    </Center>
  );
}

const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  /* footer랑 차이 두기 위해 margin 설정 */
  margin-bottom: 100px;
`;

const ParentStyle = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  font-size: 60px;
  margin: 0px;
  margin-top: 30px;
  padding: 5px;
  font-family: ${fontFamily.Regular};
`;

const SubDescrition = styled.p`
  font-weight: 500;
  font-size: 24px;
  margin: 0px;
  margin-bottom: 30px;
  padding: 5px;
  font-family: ${fontFamily.Regular};

  .emphasize {
    color: ${colors.main};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
