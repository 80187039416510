import { useState } from "react";
import { CommonFloatingCard } from "../../../components/CommonFloatingCard";
import { CommonInput } from "../../../components/inputs/CommonInput";
import styled from "styled-components";
import { CommonDropDownItem } from "../../../components/CommonDropDown";
import { LOAN_TYPE } from "../../../../repository/models";
import { CommonWhiteButton } from "../../../components/buttons/commonButtons";
import { InputMode } from "../../../../configs/inputMode";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import {
  getLoanInfoById,
  ServerLoanDetailInfo,
  suggestMainAudit,
} from "../../../../repository/loanApi";
import { value2Type } from "../../../../utils/convertUtils";

interface SuggestInputs {
  loanRate: string;
  loanMax: string;
  loanType: string;
  memo: string;
}
export function SuggestComponent({
  loanId,
  loanType,
  gotoPrev,
}: {
  loanId: string;
  loanType: string;
  gotoPrev: () => void;
}) {
  const [inputs, setInputs] = useState<SuggestInputs>({
    loanRate: "",
    loanMax: "",
    loanType: value2Type(LOAN_TYPE, loanType),
    memo: "",
  });
  const [loanTypeDropText, setLoanTypeDropText] = useState<string>("대출 유형");
  const [loanTypeDrop, setLoanTypeDrop] = useState<CommonDropDownItem[]>(
    Object.keys(LOAN_TYPE).map((key) => {
      return {
        text: LOAN_TYPE[key],
        onClick: () => {
          inputs.loanType = LOAN_TYPE[key];
          setLoanTypeDropText(LOAN_TYPE[key]);
        },
      };
    }),
  );
  return (
    <CommonFloatingCard>
      <ForWidth>
        <CommonInput
          inputMode={InputMode.WRITE}
          title="대출 금리"
          placeHold={"숫자만 입력해 주세요 (4.8)"}
          description={null}
          width="100%"
          onTextChanged={(str: string) => {
            inputs.loanRate = str;
          }}
          setClearInput={() => {}}
          needPercent={true}
        />
        <CommonInput
          inputMode={InputMode.WRITE}
          title="대출 한도"
          placeHold={null}
          description={"숫자만 입력해 주세요 (100000000)"}
          width="100%"
          onTextChanged={(str: string) => {
            inputs.loanMax = str;
          }}
          setClearInput={() => {}}
          needWon={true}
        />
        <CommonInput
          inputMode={InputMode.WRITE}
          title="메모란"
          placeHold={null}
          description={null}
          width="100%"
          onTextChanged={(str: string) => {
            inputs.memo = str;
          }}
          setClearInput={() => {}}
        />

        <CenterStyle>
          <CommonWhiteButton
            text="대출 정보 전달하기"
            onclick={() => {
              showLoading(true);
              getLoanInfoById(loanId)
                .then((info: ServerLoanDetailInfo) => {
                  return suggestMainAudit(
                    loanId,
                    inputs.memo,
                    inputs.loanMax,
                    inputs.loanType,
                    info.prevLoanInfo.loanPurpose,
                    inputs.loanRate,
                  )
                    .then(() => {
                      gotoPrev();
                    })
                    .catch(() => {
                      showToast("오류가 발생했습니다.", 2000);
                    });
                })
                .catch(() => {
                  showToast("오류가 발생했습니다.", 2000);
                })
                .finally(() => {
                  showLoading(false);
                });
            }}
            minWidth="100px"
          />
        </CenterStyle>
      </ForWidth>
    </CommonFloatingCard>
  );
}

const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;

const ForWidth = styled.div`
  width: 800px;
`;
