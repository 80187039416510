// API 호출 임시 예시

import { isDebug } from "../configs/debug";
import { getLoginedUser, tryLogin } from "../configs/user";
import { LoginedUser } from "../uiModels/User";
import { showToast } from "../views/components/baseComponents/BaseComponent";
import { BNManageMemberInfo } from "../views/pages/MyPages/MyPage_BlueNormal/ManageMembers";
import { callWithJwt, callWithOutJwt } from "./baseApi";
import { USER_TYPE } from "./models";

export function login(
  id: string,
  pw: string,
  type: string,
): Promise<LoginedUser> {
  // const serverUserType = Object.keys(USER_TYPE).find((k) => {
  //   if (USER_TYPE[k] === type) {
  //     return k;
  //   }
  // });

  const bodyJsonString = JSON.stringify({
    userId: id,
    userPw: pw,
    userType: type, //serverUserType ? serverUserType : "",
  });

  return callWithOutJwt("/api/auth/login", "post", bodyJsonString).then(
    (json) => {
      let loginedUser: LoginedUser | null = {
        userId: "",
        userType: "",
        jwtToken: "",
        jwtRefreshToekn: "",
      };

      try {
        const token = json["data"]["data"]["tokeninfo"]["accessToken"];
        const userType = json["data"]["data"]["authMember"]["userType"];
        const id = json["data"]["data"]["authMember"]["userId"];
        if (!(token && userType && id)) {
          throw new Error();
        }
        loginedUser.jwtToken = token;
        loginedUser.userId = id;
        loginedUser.userType = userType;
      } catch (err) {
        loginedUser = null;
        throw new Error();
      }
      if (loginedUser) {
        return loginedUser;
      } else {
        throw new Error();
      }
    },
  );
}

export function fetchPost(value: string): Promise<{ ok: boolean }> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (value === "yj12345") return reject({ ok: false });
      return resolve({ ok: true });
    }, 300);
  });
}

export async function fetchCheckDuplicateID(value: string): Promise<boolean> {
  try {
    const response = await fetchPost(value);

    //! 백엔드와 response 논의한 후 수정 예정
    if (!response.ok) {
      return false;
    }
    return true;
  } catch (e) {
    console.error("Error:", e); // 에러 처리
    return false;
  }
}

export function checkPassword(
  loginedUser: LoginedUser,
  password: string,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    tryLogin(
      loginedUser.userId,
      password,
      loginedUser.userType,
      () => {
        resolve(true);
      },
      () => {
        showToast("비밀번호를 확인해 주세요", 1000);
        resolve(false);
      },
      true,
      true,
    );
  });
}

export function requestTmpPassword(
  id: string,
  name: string,
  phoneNumber: string,
): Promise<boolean> {
  const url = "/api/auth/temp-pw";
  const method = "post";
  const body = JSON.stringify({
    userId: id,
    adminNm: name,
    adminPhone: phoneNumber,
    userType: "COMPANY",
  });
  return callWithOutJwt(url, method, body)
    .then((json) => {
      return true;
    })
    .catch((e) => {
      if (isDebug) {
        console.log(e);
      }
      throw new Error();
    });
}

export function changePassword(prePw: string, newPw: string): Promise<boolean> {
  const loginedUser = getLoginedUser();
  const id = loginedUser?.userId ? loginedUser?.userId : "";
  const url = "/api/user/common/pw";
  const method = "put";
  const body = JSON.stringify({
    userId: id,
    prevUserPw: prePw,
    newUserPw: newPw,
  });
  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}

export function getAllCompanyInfo(): Promise<BNManageMemberInfo[]> {
  const loginedUser = getLoginedUser();
  if (loginedUser?.userType !== "BLUENORMAL") {
    return new Promise((a, b) => {
      b("권한이 없는 계정입니다.");
    });
  }
  const url = "/api/user/bluenormal/users?userType=COMPANY";
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      const list: any[] = data[0];
      return list.map((result) => {
        return {
          cpId: result["userId"],
          companyName: result["cpNm"],
          ceoName: result["ceoNm"],
        };
      });
    } catch (e) {
      throw e;
    }
  });
}

export function deleteCompanyId(cpId: string): Promise<boolean> {
  const url = "/api/user/company/" + cpId;
  const method = "delete";
  return callWithJwt(url, method, null).then((json) => {
    return true;
  });
}
