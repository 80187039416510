import { type } from "os";
import { fetchCheckDuplicateID } from "../../repository/userApi";

export interface InputInjection {
  input: string;
  needCheckButton: Boolean;
  getErrorMessage(): string | null;
}

type myRegex = {
  regex: RegExp;
  errMessage: string | null;
};

type LengthInfo = {
  min: number | null;
  max: number | null;
};

const regExp: { [key: string]: myRegex } = {
  containCapital: {
    regex: /(?=.*[A-Z])/,
    errMessage: "최소 1개 이상의 대문자 필수입니다.",
  },
  containNumber: {
    regex: /(?=.*\d)/,
    errMessage: "1개 이상의 숫자 필수입니다.",
  },
  contianSpecialChar: {
    regex: /(?=.*[@$!%*?&])/,
    errMessage: "1개 이상의 특수문자 필수입니다.",
  },
  idIsValid: {
    regex: /^[A-Za-z0-9]{4,12}$/,
    errMessage: "ID 규칙을 확인해주세요.",
  },
  containEmailFormat: {
    regex: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\./,
    errMessage: "이메일 형식을 확인해주세요.",
  },
  phoneNumberIsValid: {
    regex: /^01([0|1|6|7|8|9])([0-9]{4})([0-9]{4})$/,
    errMessage: "전화번호 형식을 확인주세요.",
  },
  isBlank: {
    regex: /^\s*$/,
    errMessage: "공백입니다.",
  },
};

/**
 * null 반환시 옳은 조건
 * min, max 사용하지 않는 속성이 있다면 null을 넣어줘야함
 * @param target
 * @returns
 */
function checkLength(
  target: string,
  min: number | null,
  max: number | null,
): string | null {
  const withoutSpacesTarget = target.replace(/\s/g, "");
  let result = null;

  if (min !== null && withoutSpacesTarget.length < min) {
    result = `최소 ${min}자 이상이어야합니다.`;
  }
  if (max !== null && withoutSpacesTarget.length > max) {
    result = `최대 ${max}자 입니다.`;
  }

  return result;
}

function checkRegex(mRegExp: myRegex[], target: string): string | null {
  for (let i = 0; i < mRegExp.length; i++) {
    const myRegex = mRegExp[i];
    const isVaild = myRegex.regex.test(target);
    if (!isVaild) return myRegex.errMessage;
  }
  return null;
}

/**
 *
 * @param mRegExp
 * @param target
 * @param lengthInfo
 * @returns
 * 조건에 부합하지 않으면 str
 * 부합한다면 null
 *
 */
function checkAll(
  mRegExp: myRegex[],
  target: string,
  lengthInfo: LengthInfo,
): string | null {
  const { min, max } = lengthInfo;

  const lengthCheckStr = checkLength(target, min, max);
  if (lengthCheckStr != null) return lengthCheckStr;

  const regexCheck = checkRegex(mRegExp, target);
  return regexCheck;
}

/**
 * getErrorMessage
 * 조건을 만족했을때 null 반환
 * @returns
 */
export function getPasswordCheck(): InputInjection {
  const mRegExp = [
    regExp.containCapital,
    regExp.containNumber,
    regExp.contianSpecialChar,
  ];
  const lengthInfo = {
    min: 8,
    max: null,
  };

  return {
    input: "",
    needCheckButton: true,
    getErrorMessage: function () {
      return checkAll(mRegExp, this.input, lengthInfo);
    },
  };
}

/**
 *  ! 코드리뷰
 * 만약 null일 시 메시지 string은 어디서 할당..?
 * @returns
 */
export function getIdCheck(): InputInjection {
  const mRegExp = [regExp.containCapital, regExp.containNumber];

  const lengthInfo = {
    min: 4,
    max: 12,
  };

  return {
    needCheckButton: true,
    input: "",

    getErrorMessage: function () {
      return checkAll(mRegExp, this.input, lengthInfo);
    },
  };
}

export function getCommonCheck(): InputInjection {
  const mRegExp = [regExp.containCapital, regExp.containNumber];
  const lengthInfo = {
    min: null,
    max: null,
  };

  return {
    needCheckButton: true,
    input: "",
    getErrorMessage: function () {
      return checkAll(mRegExp, this.input, lengthInfo);
    },
  };
}

export function getEmailCheck(): InputInjection {
  const mRegExp = [regExp.containEmailFormat];
  const lengthInfo = {
    min: null,
    max: null,
  };

  return {
    needCheckButton: true,
    input: "",
    getErrorMessage: function () {
      return checkAll(mRegExp, this.input, lengthInfo);
    },
  };
}

export function getPhoneNumberCheck(): InputInjection {
  const mRegExp = [regExp.phoneNumberIsValid];
  const lengthInfo = {
    min: 0,
    max: null,
  };
  return {
    needCheckButton: true,
    input: "",
    getErrorMessage: function () {
      return checkAll(mRegExp, this.input, lengthInfo);
    },
  };
}

/**
 * input의 입력값이  공백임을 확인하는 함수
 * @returns
 */
export function getBlankCheck(): InputInjection {
  const mRegExp: myRegex[] = [regExp.isBlank];
  const lengthInfo = {
    min: null,
    max: null,
  };
  return {
    needCheckButton: true,
    input: "",
    getErrorMessage: function () {
      return checkAll(mRegExp, this.input, lengthInfo);
    },
  };
}
