export function number2FormatMoneyString(number: number): string {
  //   return new Intl.NumberFormat("ko-KR", {
  //     style: "currency",
  //     currency: "KRW",
  //   }).format(number);
  return (
    new Intl.NumberFormat("ko-KR", {
      style: "decimal",
      currency: "KRW",
    }).format(number) + "원"
  );
}

export function number2FormatPerCentString(number: number): string {
  //   return new Intl.NumberFormat("ko-KR", {
  //     style: "currency",
  //     currency: "KRW",
  //   }).format(number);
  return new Intl.NumberFormat("ko-KR", {
    style: "percent",
  }).format(number / 100);
}

export function date2Strig(date: Date): string {
  const d = date.getDate();
  const m = date.getMonth() + 1; //January is 0!
  const y = date.getFullYear();

  let dd = d.toString();
  let mm = m.toString();
  let yyyy = y.toString();
  if (d < 10) {
    dd = "0" + dd;
  }
  if (m < 10) {
    mm = "0" + mm;
  }

  return yyyy + mm + dd;
}
