import { useEffect, useState } from "react";
import { CommonFloatingWhiteCard } from "../../../components/CommonFloatingCard";
import styled from "styled-components";
import { fontFamily } from "../../../../configs/font";
import {
  number2FormatMoneyString,
  number2FormatPerCentString,
} from "../../../../utils/formatUtils";
import { CommonWhiteButton } from "../../../components/buttons/commonButtons";
import { colors } from "../../../../configs/colors";
import {
  CommonDropDown,
  CommonDropDownItem,
} from "../../../components/CommonDropDown";
import { APPLY_STATUS } from "../../../../repository/models";
import {
  PreScreeningComponent,
  PreScreeningInput,
} from "../../../components/PreScreening";
import { PreScreeningReject } from "./PrescreeningReject";
import { CommonSpacer } from "../../../components/CommonSpacer";
import { InputMode } from "../../../../configs/inputMode";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import TitleWithLine from "../../../components/TitleWithLine";
import {
  acceptPreScreening,
  getAllLoans,
  getConvertedLoanByID_PreScreening,
  LoanRequestInfo,
  ServerLoanDetailInfo,
} from "../../../../repository/loanApi";
import { parseDateForServer } from "../../../../utils/dateUtils";
import {
  LoanResultListComponent,
  LoanResultListComponentDetail,
} from "../MyPage_Company/LoanResultListComponent";

const STEP = {
  list: "list",
  detail: "detail",
  preScreening: "preScreening",
  reject: "reject",
  showResults: "showResults",
};
interface LoanInfo {
  loanRequestId: string;
}
interface LoanResultInfo {
  company: string;
  state: string;
  date: Date;
  id: string;
}
const SortValue = {
  date: "최신순",
  dateOld: "느린순",
};
function getSortedList(
  list: LoanResultInfo[],
  sortValue: string,
): LoanResultInfo[] {
  let result: LoanResultInfo[] = list;
  switch (sortValue) {
    case SortValue.date:
      result = result.sort(function (a: LoanResultInfo, b: LoanResultInfo) {
        console.log(a);
        console.log(b);
        return b.date.getTime() - a.date.getTime();
      });
      break;
    case SortValue.dateOld:
      result = result.sort(function (a: LoanResultInfo, b: LoanResultInfo) {
        return a.date.getTime() - b.date.getTime();
      });
      break;
  }
  return list;
}

export function LoanResultListComponent_BlueNormal() {
  const [step, setStep] = useState<string>(STEP.list);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  useEffect(() => {
    if (step === STEP.list) {
      setContent(
        <AllLoanList_Bluenormal
          onClickItem={(item: LoanResultInfo) => {
            setSelectedId(item.id);
            switch (item.state) {
              case APPLY_STATUS.TEMP:
                // todo 임시저장 구현시 여기도 구현해야 함
                break;
              case APPLY_STATUS.PREV_PROG:
                setStep(STEP.preScreening);
                break;
              case APPLY_STATUS.PREV_COMP:
                // do nothing
                break;
              case APPLY_STATUS.MAIN_PROG:
                setStep(STEP.showResults);
                break;
              case APPLY_STATUS.MAIN_COMP:
                setStep(STEP.showResults);
                break;
              case APPLY_STATUS.EXP:
                setStep(STEP.showResults);
                // do nothing
                break;
            }
          }}
        />,
      );
    } else if (step === STEP.detail) {
      setContent(<LoanResultListComponentDetail selectedId={selectedId} />);
    } else if (step === STEP.preScreening) {
      showLoading(true);
      getConvertedLoanByID_PreScreening(selectedId)
        .then((result: PreScreeningInput) => {
          setContent(
            <>
              <TitleWithLine title="사전심사 심사하기" />

              <PreScreeningWrapper>
                <PreScreeningComponent
                  inputMode={InputMode.READ}
                  onClickAccept={(inputs: PreScreeningInput) => {
                    showLoading(true);
                    acceptPreScreening(selectedId)
                      .then(() => {})
                      .catch(() => {})
                      .finally(() => {
                        showLoading(false);
                        setStep(STEP.list);
                      });
                  }}
                  onClickReject={(inputs: PreScreeningInput) => {
                    setStep(STEP.reject);
                  }}
                  onClickSubmit={(inputs: PreScreeningInput) => {}}
                  onClickTmpSave={(inputs: PreScreeningInput) => {}}
                  preScreeningInput={result}
                />
              </PreScreeningWrapper>
            </>,
          );
        })
        .catch((e) => {
          showToast("정보를 받아오는 중 오류가 발생했습니다.", 1000);
        })
        .finally(() => {
          showLoading(false);
        });
    } else if (step === STEP.reject) {
      setContent(
        <PreScreeningReject
          id={selectedId}
          onClickPre={() => {
            setStep(STEP.list);
          }}
        />,
      );
    } else if (step === STEP.showResults) {
      setContent(
        <LoanResultListComponent
          loanInfo={{
            loanRequestId: selectedId,
          }}
        />,
      );
    }
  }, [step]);
  return <div>{content ? content : ""}</div>;
}

const PreScreeningWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
`;

// interface LoanDetailInfo {
//   bank: string;
//   type: string;
//   purpose: string;
//   rate: string;
//   max: string;
//   note: string;
// }
// function LoanResultListComponentDetail({ selectedId }: { selectedId: string }) {
//   const info: LoanDetailInfo = {
//     bank: "은행이름",
//     type: "대환",
//     purpose: "대출 변경",
//     rate: "10%",
//     max: "10000000원",
//     note: "담당자의 말",
//   };
//   return (
//     <ParentStyle>
//       <div>
//         <CommonSpacer />

//         <TitleWithLine title="대환대출 결과 확인하기" />
//         <CommonSpacer />
//         <TitleStyle>은행 명</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.bank}</TextStyle>

//         <TitleStyle>대출 종류</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.type}</TextStyle>

//         <TitleStyle>대출 목적</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.purpose}</TextStyle>

//         <TitleStyle>대출 금리</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.rate}%</TextStyle>

//         <TitleStyle>대출 한도</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.max}</TextStyle>

//         <TitleStyle>담당자 한말씀</TitleStyle>
//         <UnderLine />
//         <TextStyle>{info.note}</TextStyle>
//       </div>
//     </ParentStyle>
//   );
// }

function AllLoanList_Bluenormal({
  onClickItem,
}: {
  onClickItem: (item: LoanResultInfo) => void;
}) {
  const [loanRequests, setLoanRequests] = useState<LoanResultInfo[]>([]);
  const [contentUi, setContentUi] = useState<JSX.Element[]>([]);
  const [sort, setSort] = useState<string>(SortValue.date);
  const dropDownItems: CommonDropDownItem[] = [
    {
      text: SortValue.date,
      onClick: () => {
        setSort(SortValue.date);
      },
    },
    {
      text: SortValue.dateOld,
      onClick: () => {
        setSort(SortValue.dateOld);
      },
    },
  ];
  useEffect(() => {
    let uiList: JSX.Element[] = [];
    const sorted = getSortedList(loanRequests, sort);
    sorted.forEach((item) => {
      uiList.push(
        <LoanResultItemComponent
          item={item}
          key={item.id}
          onClick={() => {
            onClickItem(item);
          }}
        />,
      );
    });
    setContentUi(uiList);
  }, [loanRequests, sort]);
  useEffect(() => {
    getAllLoans().then((result: LoanRequestInfo[]) => {
      const converted: LoanResultInfo[] = result.map(
        (item: LoanRequestInfo) => {
          const tmp: LoanResultInfo = {
            company: item.cpNm,
            state: APPLY_STATUS[item.status],
            date: new Date(parseDateForServer(item.reqDate)),
            id: item.loanRefReqId.toString(),
          };
          return tmp;
        },
      );
      setLoanRequests(converted);
    });
    // // 원래는 api 호출부임
    // setLoanRequests([
    //   {
    //     company: "임시회사1",
    //     state: APPLY_STATUS.PREV_PROG,
    //     date: new Date(100),
    //     id: "1",
    //   },
    //   {
    //     company: "임시회사2",
    //     state: APPLY_STATUS.PREV_PROG,
    //     date: new Date(1000),
    //     id: "2",
    //   },
    //   {
    //     company: "임시회사3",
    //     state: APPLY_STATUS.MAIN_PROG,
    //     date: new Date(1000),
    //     id: "3",
    //   },
    //   {
    //     company: "임시회사4",
    //     state: APPLY_STATUS.MAIN_PROG,
    //     date: new Date(1000),
    //     id: "4",
    //   },
    // ]);
  }, []);
  return (
    <ParentStyle>
      <div>
        <TitleWithLine title="서비스 데이터 관리" />
        <LeftStyle>
          <CommonDropDown
            inputMode={InputMode.WRITE}
            text={sort}
            items={dropDownItems}
            width="fit-content"
            extraZIndex={0}
          />
        </LeftStyle>
        {contentUi}
      </div>
    </ParentStyle>
  );
}
const LeftStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
`;

const TitleStyle = styled.div`
  font-family: ${fontFamily.Medium};
  font-size: 24px;
`;

const UnderLine = styled.div`
  width: 800px;
  background-color: ${colors.main};
  height: 2px;
`;

const ParentStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function LoanResultItemComponent({
  item,
  onClick,
}: {
  item: LoanResultInfo;
  onClick: () => void;
}) {
  return (
    <CommonFloatingWhiteCard>
      <ItemPatentStyle>
        <TextStyle>{item.company}</TextStyle>
        <TextStyle>{item.state}</TextStyle>

        <CommonWhiteButton
          text="확인하기"
          minWidth="80px"
          onclick={() => {
            onClick();
          }}
        />
      </ItemPatentStyle>
    </CommonFloatingWhiteCard>
  );
}

const ItemPatentStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 800px;
`;

const TextStyle = styled.p`
  display: flex;
  align-self: center;
  margin: 0px;

  font-family: ${fontFamily.Regular};
  font-size: 20px;
  width: 30%;
  word-break: break-all;
`;
