import { isDebug } from "../configs/debug";
import { getLoginedUser } from "../configs/user";
import { parseDateForServer } from "../utils/dateUtils";
import { dataURLtoFile, file2Base64 } from "../utils/fileUtils";
import {
  MainAudit_Corperation_Input,
  MainAudit_Corperation_Input_Id,
} from "../views/components/MainAudit_Corperation";
import {
  MainAudit_Personal_Input,
  MainAudit_Personal_Input_Id,
} from "../views/components/MainAudit_Personal";
import { PreScreeningInput } from "../views/components/PreScreening";
import { RegisterCompanyInputs } from "../views/pages/Register/RegisterCompanyPage";
import { getCompanyInfo_BLUENORMAL } from "./agentApi";
import { callWithJwt } from "./baseApi";
import { downLoadFile, File_Server, upLoadFile } from "./fileApi";
import {
  BANK_PRIME,
  COLLATERAL,
  COMAPNY_TYPE,
  LOAN_PURPOSE,
  LOAN_TYPE,
  REF_DOC_TYPE,
} from "./models";

export interface ServerLoanDetailFileInfo {
  fileId: number;
  loanReqId: number;
  fileType: string;
}
export interface ServerLoanDetailResume {
  content: string;
  endDate: string;
  startDate: string;
}
export interface ServerLoanDetailInfo {
  loanReqId: number;
  loanRefReq: {
    cpId: string;
    status: string;
    regiDt: string;
    regiId: string;
    collProvEqual: string;
    cpType: string;
    resume: ServerLoanDetailResume[];
  };
  prevLoanInfo: {
    loanBankCd: string;
    loanType: string;
    loanStartDt: string;
    loanEndDt: string;
    loanPurpose: string;
    loanAmount: number;
    interestRate: number;
    collateralType: string;
    collateralLocation: string;
  };
  loanRefFiles: ServerLoanDetailFileInfo[];
}
export interface LoanRequestInfo {
  loanRefReqId: number;
  cpNm: string;
  status: string;
  reqDate: string;
}

export interface ServerMainAuditResult {
  loanResId: number;
  loanReqId: number;
  resStatus: string;
  mainResRegiId: string;
  mainResRegiDt: string;
  mainRejectReason: any;
  memo: any;
  loanBound: number;
  loanType: string;
  loanPurpose: string;
  interestRate: number;
  bankCd: string;
}

export interface MainAuditResult {
  loanResId: number;
  loanReqId: number;
  resStatus: string;
  mainResRegiId: string;
  date: string;
  mainRejectReason: any;
  memo: any;
  maxLoan: number;
  loanType: string;
  loanPurpose: string;
  interestRate: number;
  bank: string;
}

export function getAllLoans(): Promise<LoanRequestInfo[]> {
  const url = "/api/loan/ref/req/all";
  const method = "post";
  const userInfo = getLoginedUser();
  const bodyString = JSON.stringify({
    userId: userInfo?.userId,
    userType: userInfo?.userType,
  });
  return callWithJwt(url, method, bodyString).then((json) => {
    let result: LoanRequestInfo[] | null = [];
    try {
      const list: any[] = json["data"]["data"];
      list.forEach((item) => {
        const tmp: LoanRequestInfo = {
          loanRefReqId: item["loanRefReqId"],
          cpNm: item["cpNm"],
          status: item["status"],
          reqDate: item["reqDate"],
        };
        result?.push(tmp);
      });
    } catch (e) {
      throw new Error("파싱 에러");
    }

    if (result) {
      return result;
    } else {
      throw new Error();
    }
  });
}

/**
 * 대출 신청 상세 조회
 * @param loanId
 * @returns
 */
export function getLoanInfoById(loanId: string): Promise<ServerLoanDetailInfo> {
  const url = "/api/loan/ref/req?loanRefReqId=" + loanId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    try {
      const data = json["data"]["data"];
      const _loanRefReq = data["loanRefReq"];
      const resumes = JSON.parse(_loanRefReq["resume"]);
      const resumeList: ServerLoanDetailResume[] = [];
      Object.keys(resumes).forEach((k) => {
        const r = resumes[k];
        const newR: ServerLoanDetailResume = {
          content: r["content"],
          endDate: r["endDate"],
          startDate: r["startDate"],
        };
        resumeList.push(newR);
      });
      const loanRefReq = {
        cpId: _loanRefReq["cpId"],
        status: _loanRefReq["status"],
        regiDt: _loanRefReq["regiDt"],
        regiId: _loanRefReq["regiId"],
        collProvEqual: _loanRefReq["collProvEqual"],
        cpType: _loanRefReq["cpType"],
        resume: resumeList,
      };
      const _prev = data["prevLoanInfo"];
      const prevLoanInfo = {
        loanBankCd: _prev["loanBankCd"],
        loanType: _prev["loanType"],
        loanStartDt: _prev["loanStartDt"],
        loanEndDt: _prev["loanEndDt"],
        loanPurpose: _prev["loanPurpose"],
        loanAmount: _prev["loanAmount"],
        interestRate: _prev["interestRate"],
        collateralType: _prev["collateralType"],
        collateralLocation: _prev["collateralLocation"],
      };
      const loanRefFiles: ServerLoanDetailFileInfo[] = [];
      data["loanRefFiles"].forEach((item: any) => {
        const tmp = {
          fileId: item["fileId"],
          loanReqId: item["loanReqId"],
          fileType: item["fileType"],
        };
        loanRefFiles.push(tmp);
      });
      return {
        loanReqId: _loanRefReq["loanReqId"],
        loanRefReq: loanRefReq,
        prevLoanInfo: prevLoanInfo,
        loanRefFiles: loanRefFiles,
      };
    } catch (e) {
      if (isDebug) {
        console.log(e);
      }
      throw e;
    }
  });
}

export function getConvertedLoanByID_PreScreening(
  loanId: string,
): Promise<PreScreeningInput> {
  const preScrinfo: PreScreeningInput = {
    presentName: "",
    presentRecoed: [],
    bank: "",
    loanType: "",
    loanDuration: {
      start: new Date(),
      end: new Date(),
    },
    loanPurpose: "",
    loanMoney: "",
    loanRate: "",
    pawnType: "",
    pawnPlace: "",
    financialFile1: null,
    financialFile2: null,
    financialFile3: null,
  };
  return getLoanInfoById(loanId).then((value: ServerLoanDetailInfo) => {
    value.loanRefReq.resume.forEach((item) => {
      preScrinfo.presentRecoed.push({
        content: item.content,
        start: new Date(parseDateForServer(item.startDate)),
        end: new Date(parseDateForServer(item.endDate)),
        uniqueKey: Math.random(),
      });
    });
    preScrinfo.bank = BANK_PRIME[value.prevLoanInfo.loanBankCd];
    preScrinfo.loanType = LOAN_TYPE[value.prevLoanInfo.loanType];
    preScrinfo.loanDuration.start = new Date(
      parseDateForServer(value.prevLoanInfo.loanStartDt),
    );
    preScrinfo.loanDuration.end = new Date(
      parseDateForServer(value.prevLoanInfo.loanEndDt),
    );
    preScrinfo.loanPurpose = LOAN_PURPOSE[value.prevLoanInfo.loanPurpose];
    preScrinfo.loanMoney = value.prevLoanInfo.loanAmount.toString();
    preScrinfo.loanRate = value.prevLoanInfo.interestRate.toString();
    preScrinfo.pawnType = COLLATERAL[value.prevLoanInfo.collateralType];
    preScrinfo.pawnPlace = value.prevLoanInfo.collateralLocation;

    return getCompanyInfo_BLUENORMAL(value.loanRefReq.cpId).then(
      (result: RegisterCompanyInputs) => {
        preScrinfo.presentName = result.ceoNm ? result.ceoNm : "이름 없음";

        const filePromise: Promise<File_Server>[] = [];
        value.loanRefFiles.forEach((item) => {
          filePromise.push(downLoadFile(item.fileId.toString()));
        });
        const final = filePromise.map((p: Promise<File_Server>) => {
          return p.then((f: File_Server) => {
            return dataURLtoFile(
              f.file,
              REF_DOC_TYPE[f.fileType] + ".pdf",
            ).then((file: File) => {
              if (REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_1) {
                preScrinfo.financialFile1 = file;
              } else if (
                REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_2
              ) {
                preScrinfo.financialFile2 = file;
              } else if (
                REF_DOC_TYPE[f.fileType] === REF_DOC_TYPE.FIN_STAT_REF_3
              ) {
                preScrinfo.financialFile3 = file;
              }
              return true;
            });
          });
        });
        return Promise.all(final).then((r: boolean[]) => {
          return preScrinfo;
        });
      },
    );
  });
}

export function acceptPreScreening(loanId: string): Promise<boolean> {
  const url = "/api/loan/ref/res/prev";
  const method = "put";
  const body = JSON.stringify({
    loanReqId: loanId,
    preResStatus: "PREV_APPR",
    preResRegiId: "admin",
  });
  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}
export function rejectPreScreening(
  loanId: string,
  reason: string,
): Promise<boolean> {
  const url = "/api/loan/ref/res/prev";
  const method = "put";
  const body = JSON.stringify({
    loanReqId: loanId,
    preResStatus: "PREV_REJ",
    preResRegiId: "admin",
    reason: reason,
  });
  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}
export function rejectMainAudit(
  loanId: string,
  reason: string,
): Promise<boolean> {
  const rId = getLoginedUser()?.userId;
  const url = "/api/loan/ref/res/main";
  const method = "post";
  // reject이지만 필수 옵션에는 아무 값이나 넣는다
  const body = JSON.stringify({
    loanReqId: Number(loanId),
    resStatus: "MAIN_REJ",
    mainResRegiId: rId ? rId : "",
    mainRejectReason: reason,
    loanBound: 1,
    loanType: "PAWN",
    loanPurpose: "FACILITY",
    interestRate: 1.1,
  });
  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}

export function suggestMainAudit(
  loanId: string,
  memo: String,
  loanBound: string,
  loanType: string,
  loanPurpose: string,
  interestRate: string,
) {
  const rId = getLoginedUser()?.userId;
  const url = "/api/loan/ref/res/main";
  const method = "post";
  const body = JSON.stringify({
    loanReqId: Number(loanId),
    resStatus: "MAIN_APPR",
    mainResRegiId: rId ? rId : "",
    memo: memo,
    loanBound: Number(loanBound),
    loanType: loanType,
    loanPurpose: loanPurpose,
    interestRate: Number(interestRate),
  });
  return callWithJwt(url, method, body);
}

export function requestMainAudit_Personal(
  loanId: string,
  inputs: MainAudit_Personal_Input,
  ids: MainAudit_Personal_Input_Id | null,
): Promise<boolean> {
  return getLoanInfoById(loanId).then((info: ServerLoanDetailInfo) => {
    const url = "/api/loan/ref/req/main";
    const method = "post";
    const body = JSON.stringify({
      loanRefReq: {
        loanReqId: Number(loanId),
        cpId: info.loanRefReq.cpId,
        regiId: info.loanRefReq.cpId,
        collProvEqual: inputs.isSamePerson ? "Y" : "N",
        mainApplyId: info.loanRefReq.cpId,
      },
      prevLoanInfo: {
        loanReqId: Number(loanId),
        loanBankCd: info.prevLoanInfo.loanBankCd,
        loanType: info.prevLoanInfo.loanType,
        loanStartDt: info.prevLoanInfo.loanStartDt,
        loanEndDt: info.prevLoanInfo.loanEndDt,
        loanPurpose: info.prevLoanInfo.loanPurpose,
        loanAmount: info.prevLoanInfo.loanAmount,
        interestRate: info.prevLoanInfo.interestRate,
        collateralType: info.prevLoanInfo.collateralType,
      },
    });
    return callWithJwt(url, method, body).then((json) => {
      const lid = json["data"]["data"]["loanReqId"];
      const upLoadFilePromiseList: Promise<boolean>[] = [];
      Object.keys(inputs).forEach((k: string) => {
        if (typeof inputs[k] !== "boolean" && inputs[k]) {
          upLoadFilePromiseList.push(
            file2Base64(inputs[k]).then((content: string) => {
              const fileServer: File_Server = {
                fileId: ids ? (ids[k] ? ids[k] : null) : null,
                loanReqId: Number(loanId),
                fileType: k,
                file: content,
              };
              return upLoadFile(fileServer, info.loanRefReq.cpId);
            }),
          );
        }
      });

      return Promise.all(upLoadFilePromiseList).then((callback: boolean[]) => {
        return true;
      });
    });
  });
}

/**
 * 특정 대출 결과 전체조회
 * @returns
 */

export function getAllCertianLoans(loanId: string): Promise<MainAuditResult[]> {
  const url = "/api/loan/ref/res/all?loanReqId=" + loanId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    let result: MainAuditResult[] | null = [];

    try {
      const list: ServerMainAuditResult[] = json["data"]["data"];
      result = list.map((serverLoanInfo) => {
        if (isDebug) {
          console.log("responseOfBankValue:" + serverLoanInfo.bankCd);
        }
        const tmp: MainAuditResult = {
          loanResId: serverLoanInfo.loanResId,
          loanReqId: serverLoanInfo.loanReqId,
          resStatus: serverLoanInfo.resStatus,
          mainResRegiId: serverLoanInfo.mainResRegiId,
          date: serverLoanInfo.mainResRegiDt,
          mainRejectReason: serverLoanInfo.mainRejectReason,
          memo: serverLoanInfo.memo,
          maxLoan: serverLoanInfo.loanBound,
          loanType: serverLoanInfo.loanType,
          loanPurpose: serverLoanInfo.loanPurpose,
          interestRate: serverLoanInfo.interestRate,
          bank: serverLoanInfo.bankCd,
        };
        return tmp;
      });
    } catch (e) {
      throw new Error("파싱 에러");
    }

    if (result) {
      return result;
    } else {
      throw new Error();
    }
  });
}

/**
 * 본심사 결과 상세 조회
 * @returns
 */
export function getMainAuditResultDetail(
  loanId: string,
): Promise<MainAuditResult> {
  const url = "/api/loan/ref/res?loanResId=" + loanId;
  const method = "get";
  return callWithJwt(url, method, null).then((json) => {
    let result: MainAuditResult | null;

    try {
      const serverLoanInfo: ServerMainAuditResult = json["data"]["data"];
      result = {
        loanResId: serverLoanInfo.loanResId,
        loanReqId: serverLoanInfo.loanReqId,
        resStatus: serverLoanInfo.resStatus,
        mainResRegiId: serverLoanInfo.mainResRegiId,
        date: serverLoanInfo.mainResRegiDt,
        mainRejectReason: serverLoanInfo.mainRejectReason,
        memo: serverLoanInfo.memo,
        maxLoan: serverLoanInfo.loanBound,
        loanType: serverLoanInfo.loanType,
        loanPurpose: serverLoanInfo.loanPurpose,
        interestRate: serverLoanInfo.interestRate,
        bank: serverLoanInfo.bankCd,
      };
    } catch (e) {
      console.log(e);
      throw new Error("파싱 에러");
    }

    if (result) {
      return result;
    } else {
      throw new Error();
    }
  });
}

export function requestMainAudit_Corperation(
  loanId: string,
  inputs: MainAudit_Corperation_Input,
  ids: MainAudit_Corperation_Input_Id | null,
): Promise<boolean> {
  return getLoanInfoById(loanId).then((info: ServerLoanDetailInfo) => {
    const url = "/api/loan/ref/req/main";
    const method = "post";
    const body = JSON.stringify({
      loanRefReq: {
        loanReqId: Number(loanId),
        cpId: info.loanRefReq.cpId,
        regiId: info.loanRefReq.cpId,
        collProvEqual: "Y",
        mainApplyId: info.loanRefReq.cpId,
      },
      prevLoanInfo: {
        loanReqId: Number(loanId),
        loanBankCd: info.prevLoanInfo.loanBankCd,
        loanType: info.prevLoanInfo.loanType,
        loanStartDt: info.prevLoanInfo.loanStartDt,
        loanEndDt: info.prevLoanInfo.loanEndDt,
        loanPurpose: info.prevLoanInfo.loanPurpose,
        loanAmount: info.prevLoanInfo.loanAmount,
        interestRate: info.prevLoanInfo.interestRate,
        collateralType: info.prevLoanInfo.collateralType,
      },
    });
    return callWithJwt(url, method, body).then((json) => {
      const lid = json["data"]["data"]["loanReqId"];
      const upLoadFilePromiseList: Promise<boolean>[] = [];
      Object.keys(inputs).forEach((k: string) => {
        if (typeof inputs[k] !== "boolean" && inputs[k]) {
          upLoadFilePromiseList.push(
            file2Base64(inputs[k]).then((content: string) => {
              const fileServer: File_Server = {
                fileId: ids ? (ids[k] ? ids[k] : null) : null,
                loanReqId: Number(loanId),
                fileType: k,
                file: content,
              };
              return upLoadFile(fileServer, info.loanRefReq.cpId);
            }),
          );
        }
      });
      return Promise.all(upLoadFilePromiseList).then((callback: boolean[]) => {
        return true;
      });
    });
  });
}

export function tmpStore_Cor(
  loanId: string,
  inputs: MainAudit_Corperation_Input,
  ids: MainAudit_Corperation_Input_Id | null,
): Promise<boolean> {
  return getLoanInfoById(loanId).then((info: ServerLoanDetailInfo) => {
    const url = "/api/loan/ref/req/temp";
    const method = "post";

    const { loanRefReq, prevLoanInfo } = info;

    let resumeObj = {};

    loanRefReq.resume.forEach((val, key) => {
      return (resumeObj = {
        ...resumeObj,
        [key]: val,
      });
    });

    const body = JSON.stringify({
      loanRefReq: {
        loanReqId: Number(loanId), // 사전심사때부터 쓰던 id값
        cpId: loanRefReq.cpId,
        regiId: loanRefReq.regiId,
        collProvEqual: "Y",
        resume: JSON.stringify(resumeObj),
      },
      prevLoanInfo: {
        loanReqId: Number(loanId),
        loanBankCd: prevLoanInfo.loanBankCd,
        loanType: prevLoanInfo.loanType,
        loanStartDt: prevLoanInfo.loanStartDt,
        loanEndDt: prevLoanInfo.loanEndDt,
        loanPurpose: prevLoanInfo.loanPurpose,
        loanAmount: prevLoanInfo.loanAmount,
        interestRate: prevLoanInfo.interestRate,
        collateralType: prevLoanInfo.collateralType,
        collateralLocation: prevLoanInfo.collateralLocation,
      },
    });

    return callWithJwt(url, method, body).then((json) => {
      const lid = json["data"]["data"]["loanReqId"];
      const upLoadFilePromiseList: Promise<boolean>[] = [];

      Object.keys(inputs).forEach((k: string) => {
        if (typeof inputs[k] !== "boolean" && inputs[k]) {
          upLoadFilePromiseList.push(
            file2Base64(inputs[k]).then((content: string) => {
              const fileServer: File_Server = {
                fileId: ids ? (ids[k] ? ids[k] : null) : null,
                loanReqId: Number(loanId),
                fileType: k,
                file: content,
              };
              return upLoadFile(fileServer, info.loanRefReq.cpId);
            }),
          );
        }
      });

      console.log(upLoadFilePromiseList);
      return Promise.all(upLoadFilePromiseList)
        .then((callback: boolean[]) => {
          console.log("callback");
          console.log(callback);
          return true;
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    });
  });
}

export function tmpStore_Personal(
  loanId: string,
  inputs: MainAudit_Personal_Input,
  ids: MainAudit_Personal_Input_Id | null,
): Promise<boolean> {
  return getLoanInfoById(loanId).then((info: ServerLoanDetailInfo) => {
    const url = "/api/loan/ref/req/temp";
    const method = "post";

    const { loanRefReq, prevLoanInfo } = info;

    let resumeObj = {};

    loanRefReq.resume.forEach((val, key) => {
      return (resumeObj = {
        ...resumeObj,
        [key]: val,
      });
    });

    const body = JSON.stringify({
      loanRefReq: {
        loanReqId: Number(loanId), // 사전심사때부터 쓰던 id값
        cpId: loanRefReq.cpId,
        regiId: loanRefReq.regiId,
        collProvEqual: inputs.isSamePerson ? "Y" : "N",
        resume: JSON.stringify(resumeObj),
      },
      prevLoanInfo: {
        loanReqId: Number(loanId),
        loanBankCd: prevLoanInfo.loanBankCd,
        loanType: prevLoanInfo.loanType,
        loanStartDt: prevLoanInfo.loanStartDt,
        loanEndDt: prevLoanInfo.loanEndDt,
        loanPurpose: prevLoanInfo.loanPurpose,
        loanAmount: prevLoanInfo.loanAmount,
        interestRate: prevLoanInfo.interestRate,
        collateralType: prevLoanInfo.collateralType,
        collateralLocation: prevLoanInfo.collateralLocation,
      },
    });

    return callWithJwt(url, method, body).then((json) => {
      const lid = json["data"]["data"]["loanReqId"];
      const upLoadFilePromiseList: Promise<boolean>[] = [];

      Object.keys(inputs).forEach((k: string) => {
        if (typeof inputs[k] !== "boolean" && inputs[k]) {
          upLoadFilePromiseList.push(
            file2Base64(inputs[k]).then((content: string) => {
              const fileServer: File_Server = {
                fileId: ids ? (ids[k] ? ids[k] : null) : null,
                loanReqId: Number(loanId),
                fileType: k,
                file: content,
              };
              return upLoadFile(fileServer, info.loanRefReq.cpId);
            }),
          );
        }
      });

      return Promise.all(upLoadFilePromiseList).then((callback: boolean[]) => {
        return true;
      });
    });
  });
}
