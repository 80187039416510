import { useEffect, useState } from "react";
import { CommonFloatingCard } from "../../../components/CommonFloatingCard";
import styled from "styled-components";
import { fontFamily } from "../../../../configs/font";
import { CommonInput } from "../../../components/inputs/CommonInput";
import {
  CommonBlueButton,
  CommonWhiteButton,
} from "../../../components/buttons/commonButtons";
import { changePassword, checkPassword } from "../../../../repository/userApi";
import { getLoginedUser, logout } from "../../../../configs/user";
import { useNavigate } from "react-router-dom";
import {
  showLoading,
  showToast,
} from "../../../components/baseComponents/BaseComponent";
import { randomInt } from "crypto";
import {
  getTaxAgentInfoSelf,
  setAgentInfo_TaxAgent,
} from "../../../../repository/agentApi";
import { CommonDoneUI } from "../../../components/CommonDoneUi";
import { InputMode } from "../../../../configs/inputMode";
import { resourceUsage } from "process";
import { PassWordInput } from "../../../components/inputs/PassWordInput";
import { PassWordCheckInput } from "../../../components/inputs/PassWordInputCheck";
import { PhoneNumberInput } from "../../../components/inputs/PhoneNumberInput";
import { isDebug } from "../../../../configs/debug";
import EmailInput from "../../../components/inputs/EmailInput";

const STEP = {
  password: "password",
  manageInfo: "manageIndo",
  done: "done",
};
const width = "350px";

export function ManageAgentTax() {
  const [step, setStep] = useState<string>(STEP.password);
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [oldPw, setOldPw] = useState("");
  useEffect(() => {
    switch (step) {
      case STEP.password:
        setContent(
          <PasswordCard
            setNextStep={(oldPw: string) => {
              setOldPw(oldPw);
              setStep(STEP.manageInfo);
            }}
          />,
        );
        break;
      case STEP.manageInfo:
        const loginedUser = getLoginedUser();
        let taxId =
          loginedUser?.userId +
          (loginedUser?.userType === "AGENT" ? "" : "_세무");
        showLoading(true);
        getTaxAgentInfoSelf(taxId)
          .then((result: ManageTaxAgnetInfoSelf) => {
            setContent(
              <ChangeInfoCards
                setNextStep={() => {
                  setStep(STEP.done);
                }}
                info={result}
                oldPw={oldPw}
              />,
            );
          })
          .catch(() => {
            showToast("오류 발생", 1000);
          })
          .finally(() => {
            showLoading(false);
          });
        break;
      case STEP.done:
        setContent(<GotoDonePage />);
        break;
    }
  }, [step]);
  return <div>{content}</div>;
}

function GotoDonePage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      "/done/담당자 정보 변경 완료/담당자 정보 변경이 성공적으로 완료되었습니다.",
    );
  }, []);
  return <div></div>;
}

function PasswordCard({
  setNextStep,
}: {
  setNextStep: (oldPW: string) => void;
}) {
  const navigate = useNavigate();
  let password = "";
  let clearInput: (() => void) | null = null;
  return (
    <CommonFloatingCard>
      <PassWordInput
        needRegex={false}
        inputMode={InputMode.WRITE}
        width="350px"
        onTextChanged={(str) => {
          password = str;
        }}
      />

      <SpaceStyle />
      <CenterStyle>
        <CommonBlueButton
          minWidth="100px"
          text="비밀번호 확인"
          onclick={() => {
            const loginedUser = getLoginedUser();
            if (loginedUser) {
              checkPassword(loginedUser, password)
                .then((result) => {
                  if (result) {
                    setNextStep(password);
                  } else {
                    showToast("비밀번호가 일치하지 않습니다.", 3000);
                  }
                })
                .catch(() => {
                  showToast("오류가 발생 했습니다.", 3000);
                });
            } else {
              logout(() => {
                navigate("/");
              });
            }
          }}
        />
      </CenterStyle>
    </CommonFloatingCard>
  );
}
const CenterStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const SpaceStyle = styled.div`
  width: 1px;
  height: 40px;
`;

function ChangeInfoCards({
  setNextStep,
  info,
  oldPw,
}: {
  setNextStep: () => void;
  info: ManageTaxAgnetInfoSelf;
  oldPw: string;
}) {
  return (
    <div>
      <ManageTaxCommonInfoCard
        inputMode={InputMode.RW}
        gotoDone={setNextStep}
        info={info}
      />
      <SpaceStyle />
      <ManagePasswordCard oldPw={oldPw} setNext={setNextStep} />
    </div>
  );
}

function ManagePasswordCard({
  oldPw,
  setNext,
}: {
  oldPw: string;
  setNext: () => void;
}) {
  const [pw, setPW] = useState("");
  const [valid, setValid] = useState(false);
  const [valid2, setValid2] = useState(false);
  return (
    <CommonFloatingCard>
      <PassWordInput
        needRegex={true}
        inputMode={InputMode.WRITE}
        width={width}
        onTextChanged={(s) => {
          setPW(s);
        }}
        isValid={(valid: boolean) => {
          setValid2(valid);
        }}
      />

      <PassWordCheckInput
        inputMode={InputMode.WRITE}
        width={width}
        pwValueForCheck={pw}
        onVaild={(valid: boolean) => {
          setValid(valid);
        }}
      />

      <SpaceStyle />
      <CenterStyle>
        <CommonBlueButton
          minWidth="100px"
          text="비밀번호 변경하기"
          onclick={() => {
            if (valid && valid2) {
              showLoading(true);
              changePassword(oldPw, pw)
                .then(() => {
                  setNext();
                })
                .catch(() => {
                  showToast("오류가 발생했습니다.", 1000);
                })
                .finally(() => {
                  showLoading(false);
                });
            } else {
              showToast("비밀번호를 확인해 주세요", 1000);
            }
          }}
        />
      </CenterStyle>
      <SpaceStyle />
    </CommonFloatingCard>
  );
}

export interface ManageTaxAgnetInfoSelf {
  agentDepartment: string;
  agnetName: string;
  agnetEMail: string;
  agnetPhone: string;
}

const manageTaxCommonInfoCardInputWidth = "100%";
export function ManageTaxCommonInfoCard({
  inputMode,
  gotoDone,
  info,
}: {
  inputMode: string;
  gotoDone: () => void;
  info: ManageTaxAgnetInfoSelf;
}) {
  const [input, setInput] = useState<ManageTaxAgnetInfoSelf>(
    info
      ? info
      : {
          agentDepartment: "",
          agnetName: "",
          agnetEMail: "",
          agnetPhone: "",
        },
  );
  return (
    <Wrapper>
      <CommonFloatingCard>
        <CommonInput
          inputMode={inputMode}
          initialInput={input.agentDepartment}
          title="세무사 사무실명"
          onTextChanged={(s) => {
            input.agentDepartment = s;
          }}
          setClearInput={() => {}}
          description={"세무 담당자 사무실 이름을 입력해 주세요."}
          placeHold={null}
          width={manageTaxCommonInfoCardInputWidth}
        />
        <CommonInput
          inputMode={inputMode}
          initialInput={input.agnetName}
          title="담당자 이름"
          onTextChanged={(s) => {
            input.agnetName = s;
          }}
          setClearInput={() => {}}
          description={null}
          placeHold={null}
          width={manageTaxCommonInfoCardInputWidth}
        />

        <EmailInput
          inputMode={inputMode}
          initialInput={input.agnetEMail}
          onTextChanged={(s) => {
            input.agnetEMail = s;
          }}
          width={manageTaxCommonInfoCardInputWidth}
        />

        <PhoneNumberInput
          inputMode={inputMode}
          initialInput={input.agnetPhone}
          onTextChanged={(s) => {
            input.agnetPhone = s;
          }}
          width={manageTaxCommonInfoCardInputWidth}
        />
        <SpaceStyle />
        {inputMode !== InputMode.READ ? (
          <CenterStyle>
            <CommonBlueButton
              minWidth="100px"
              text="담당자 정보 변경"
              onclick={() => {
                if (isDebug) {
                  console.log(input);
                }
                showLoading(true);
                setAgentInfo_TaxAgent(
                  input.agentDepartment,
                  input.agnetName,
                  input.agnetEMail,
                  input.agnetPhone,
                )
                  .then((b) => {
                    if (b) {
                      gotoDone();
                    } else {
                      showToast("실패하였습니다.", 3000);
                    }
                  })
                  .catch(() => {
                    showToast("실패하였습니다.", 3000);
                  })
                  .finally(() => showLoading(false));
              }}
            />
          </CenterStyle>
        ) : (
          ""
        )}

        <SpaceStyle />
      </CommonFloatingCard>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  /* Wrapper style 자식은 Wrapper(부모) 넓이의 100% */
  > * {
    width: 100%;
  }
`;
