export const LOAN_TYPE: { [key: string]: string } = {
  PAWN: "담보",
  CREDIT: "신용",
  MIX: "혼합",
  ETC: "기타",
};

export const LOAN_PURPOSE: { [key: string]: string } = {
  FACILITY: "시설",
  OPERATION: "운전",
  ETC: "기타",
};

export const COLLATERAL: { [key: string]: string } = {
  FACILITY: "설비",
  ESTATE: "토지",
  STAND_BUILDING: "단독건물",
  COLL_BUILDING: "집합건물 특정호수",
  MIX: "혼합",
  ETC: "기타",
  WARRANTY: "보증서",
  PATENT: "특허",
};

export const COMAPNY_TYPE: { [key: string]: string } = {
  PRI: "개인 사업자",
  ENT: "법인 사업자",
};

export const COMPANY_SIZE: { [key: string]: string } = {
  OF: "1-50",
  FOH: "51-100",
  OHFH: "101-500",
  FHOT: "501-1000",
  OTFT: "1001-5000",
  FT: "5000-",
};

export const APPLY_STATUS: { [key: string]: string } = {
  TEMP: "임시저장",
  PREV_PROG: "사전심사 진행중",
  PREV_COMP: "사전심사 완료",
  MAIN_PROG: "본심사 진행중",
  MAIN_COMP: "본심사 완료",
  EXP: "만료",
};

export const AUDIT_STATUS: { [key: string]: string } = {
  PREV_APPR: "사전심사 승인",
  PREV_REJ: "사전심사 반려",
  MAIN_APPR: "본심사 승인",
  MAIN_REJ: "본심사 반려",
};

export const BANK_PRIME: { [key: string]: string } = {
  KBKM: "KB국민은행",
  SHH: "신한은행",
  WR: "우리은행",
  HN: "하나은행",
  IM: "IM뱅크(구.대구은행)",
  KS: "한국스탠다드차타드은행",
  KC: "한국씨티은행",
  BNK_GN: "BNK경남은행",
  GJ: "광주은행",
  JB: "JB전북은행",
  BNK_BS: "BNK부산은행",
  JJ: "제주은행",
  TS: "토스뱅크",
  KKB: "카카오뱅크",
  KB: "케이뱅크",
  NH: "NH농협은행",
  SH: "SH수협은행",
  JS: "중소기업은행",
  KI: "한국산업은행",
  KE: "한국수출입은행",
};

export const USER_TYPE: { [key: string]: string } = {
  COMPANY: "기업",
  BANK: "은행",
  BLUENORMAL: "블루노멀",
  AGENT: "세무 대리인",
};

export const YN_FLAG: { [key: string]: boolean } = {
  Y: true,
  N: false,
};

export const INQ_STATUS: { [key: string]: string } = {
  PROG: "진행중",
  COMP: "완료",
};

export const REF_DOC_TYPE: { [key: string]: string } = {
  FIN_STAT_REF_1: "재무재표 1",
  FIN_STAT_REF_2: "재무재표 2",
  FIN_STAT_REF_3: "재무재표 3",
  FIN_STAT_1: "재무재표 증명원 1",
  FIN_STAT_2: "재무재표 증명원 2",
  FIN_STAT_3: "재무재표 증명원 3",
  SURTAX_1: "부가세 과세표준 증명원 1",
  SURTAX_2: "부가세 과세표준 증명원 2",
  SP_TAX: "매출매입처별 세금계산서 합계표",
  CORP_TAX: "법인세 신고자료(전체 일괄송부)",
  PAY_1: "납부내역증명서 1",
  PAY_2: "납부내역증명서 2",
  PAY_3: "납부내역증명서 3",
  PROOF_TAX_ENT: "국세완납증명서(납세증명서)-법인",
  PROOF_TAX_PRI: "국세완납증명서(납세증명서)-대표이사 개인",
  LOCAL_TAX_ENT: "지방세납세증명서-법인",
  LOCAL_TAX_PRI: "지방세납세증명서-대표이사 개인",
  STOCK: "주식이동상황명세서",
  WITHOLDING: "원천징수이행상황신고서",
  TAX_CREDIT: "세액공제신청서",
  FIN_TRANS: "금융거래확인서",
  PROOF_TAX_PROP: "국세 납세증명서",
  PROOF_TAX_PROP_BY: "국세 납세사실증명원",
  LOCAL_TAX_1: "지방세 납세증명서 1",
  LOCAL_TAX_2: "지방세 납세증명서 2",
  LOCAL_TAX_3: "지방세 납세증명서 3",
  E_LOCAL_TAX_1: "지방세 세목별 과세증명서 1",
  E_LOCAL_TAX_2: "지방세 세목별 과세증명서 2",
  E_LOCAL_TAX_3: "지방세 세목별 과세증명서 3",
  LEASE: "임대차계약서 사본",
  RENT: "최근 6개월 임대료 입금내역",
  REGISTRATION: "등기권리증(등기필증) 원본(공동명의자 포함)",
  PROOF_TAX_PROP_P: "국세 납세증명서",
  PROOF_TAX_PROP_BY_P: "국세 납세사실증명원",
  LOCAL_TAX_P_1: "지방세 납세증명서 1",
  LOCAL_TAX_P_2: "지방세 납세증명서 2",
  LOCAL_TAX_P_3: "지방세 납세증명서 3",
  E_LOCAL_TAX_P_1: "지방세 세목별 과제증명서 1",
  E_LOCAL_TAX_P_2: "지방세 세목별 과제증명서 2",
  E_LOCAL_TAX_P_3: "지방세 세목별 과제증명서 3",
};
