import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { Header } from "./header";
import { Footer } from "./Footer";
import { LoginedUser } from "../../../uiModels/User";
import { getLoginedUser } from "../../../configs/user";
import { zIndex } from "../../../configs/zIndex";
import loadingGif from "../../../assets/loading.gif";
import { colors } from "../../../configs/colors";
import { fontFamily } from "../../../configs/font";

interface BaseComponentData {
  showLoading?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  toastMessage?: [
    ToastRequest,
    React.Dispatch<React.SetStateAction<ToastRequest>>,
  ];
}
interface ToastRequest {
  text: string;
  milliSec: number;
}
const componentData: BaseComponentData = {};

export function BaseComponent({ children }: { children: ReactNode }) {
  componentData.toastMessage = useState<ToastRequest>({
    text: "",
    milliSec: 0,
  });
  // const [loginedUser, setLoginedUSer] = useState<LoginedUser | null>(null);
  const loginedUser = getLoginedUser();

  return (
    <div>
      <Header loginUserInfo={loginedUser} />
      <ContentArea>{children}</ContentArea>
      <Footer />
      <LoadingComponent />
      <ToastComponent />
    </div>
  );
}

function ToastComponent() {
  const [text, setText] = useState("");
  componentData.toastMessage = useState({ text: "", milliSec: 0 });
  const requestToastMessage = componentData.toastMessage[0];
  const [deque, setDeque] = useState<string[]>([]);
  useEffect(() => {
    if (componentData.toastMessage) {
      // 가장 위에 원소를 집어넣음
      deque.push(componentData.toastMessage[0].text);
      setText(componentData.toastMessage[0].text);
      setTimeout(() => {
        // 가장 아래있는 원소를 뺌
        deque.shift();
        if (deque.length <= 0) {
          setText("");
        }
      }, componentData.toastMessage[0].milliSec);
      console.log(deque.length);
    }
  }, [requestToastMessage]);
  return (
    <div>
      {text !== "" ? (
        <TaostParentStyle>
          <div>{text}</div>
        </TaostParentStyle>
      ) : (
        ""
      )}
    </div>
  );
}

function LoadingComponent() {
  componentData.showLoading = useState(false);
  let show = componentData.showLoading[0];
  return (
    <div>
      {show ? (
        <div>
          <LoadingBackgroundStyle />
          <LoadingParentStyle>
            <LoadingStyle src={loadingGif} />
          </LoadingParentStyle>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export function showLoading(show: boolean) {
  if (componentData.showLoading) {
    componentData.showLoading[1](show);
  }
}
export function isLoadingShow(): boolean {
  if (componentData.showLoading) {
    return componentData.showLoading[0] === true;
  }
  return false;
}
export function showToast(text: string, milliSec: number) {
  if (componentData.toastMessage) {
    componentData.toastMessage[1]({ text: text, milliSec: milliSec });
  }
}

const LoadingBackgroundStyle = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loading};
  position: fixed;
  transform: translateY(0);
  top: 0;
  left: 0;
  background-color: gray;
  opacity: 0.9;
`;
const LoadingParentStyle = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${zIndex.loading + 1};
  position: fixed;
  transform: translateY(0);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
`;
const LoadingStyle = styled.img`
  z-index: ${zIndex.loading + 2};
  width: 150px;
  height: 150px;
`;
const TaostParentStyle = styled.div`
  width: 344px;
  padding: 10px;
  z-index: ${zIndex.toast};
  position: fixed;
  transform: translateY(0);
  top: 100px;
  left: calc(50% - 172px - 20px);
  display: flex;
  justify-content: center; // 거로축 중앙
  align-self: flex-start; // 세로축 첫번째
  background-color: white;
  border: 2px solid black;
  border-radius: 8px;
  font-family: ${fontFamily.Medium};
  font-style: 12px;
`;

const ContentArea = styled.div`
  width: 100%;
  /* padding-top: 150px; */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  min-height: calc(100vh - 340px);
`;
