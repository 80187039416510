import { useNavigate } from "react-router-dom";
import { getLoginedUser } from "../../../configs/user";
import { LoginedUser } from "../../../uiModels/User";
import { CompanyMyPage } from "./MyPage_Company/CompanyMyPage";
import { TaxMyPage } from "./MyPage_Tax/TaxMyPage";
import { BlueNornalMyPage } from "./MyPage_BlueNormal/BlueNormalMyPage";
import { USER_TYPE } from "../../../repository/models";
import { useEffect, useState } from "react";
import { BankMyPage } from "./MyPage_Bank/BankMyPage";

export function MyPage() {
  const navigate = useNavigate();
  const [content, setContent] = useState<JSX.Element | null>(null);
  useEffect(() => {
    const user: LoginedUser | null = getLoginedUser();
    const tmpType = USER_TYPE[user?.userType ? user?.userType : ""];

    if (tmpType === USER_TYPE.COMPANY) {
      setContent(<CompanyMyPage />);
    } else if (tmpType === USER_TYPE.AGENT) {
      setContent(<TaxMyPage />);
    } else if (tmpType === USER_TYPE.BLUENORMAL) {
      setContent(<BlueNornalMyPage />);
    } else if (tmpType === USER_TYPE.BANK) {
      setContent(<BankMyPage />);
    }
  }, []);

  return content;
}
