import { useEffect, useState } from "react";
import { CommonFloatingCard } from "../../../components/CommonFloatingCard";
import { CommonInput } from "../../../components/inputs/CommonInput";
import {
  CommonRedButton,
  CommonWhiteButton,
} from "../../../components/buttons/commonButtons";
import styled from "styled-components";
import { InputMode } from "../../../../configs/inputMode";
import TitleWithLine from "../../../components/TitleWithLine";
import { showLoading } from "../../../components/baseComponents/BaseComponent";
import { rejectPreScreening } from "../../../../repository/loanApi";

export function PreScreeningReject({
  id,
  onClickPre,
}: {
  id: string;
  onClickPre: () => void;
}) {
  const [reason, setReason] = useState<string>("");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <TitleWithLine title="사전심사 심사하기" />
      <Wrapper>
        <CommonFloatingCard>
          <p>반려 사유</p>
          <CommonInput
            inputMode={InputMode.WRITE}
            title="반려 사유 입력하기"
            placeHold={null}
            description={null}
            width="500px"
            onTextChanged={(s: string) => {
              setReason(s);
            }}
            setClearInput={() => {}}
          />

          <ButtonWrapper>
            <RowStyle>
              <CommonWhiteButton
                text="이전으로"
                onclick={() => {
                  onClickPre();
                }}
                minWidth="100px"
              />
              <CommonRedButton
                text="반려하기"
                onclick={() => {
                  showLoading(true);
                  rejectPreScreening(id, reason)
                    .then(() => {})
                    .catch(() => {})
                    .finally(() => {
                      showLoading(false);
                      onClickPre();
                    });
                }}
                minWidth="100px"
              />
            </RowStyle>
          </ButtonWrapper>
        </CommonFloatingCard>
      </Wrapper>
    </>
  );
}

const RowStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 90px;
  padding-bottom: 200px;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
`;
