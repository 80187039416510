import { CommonInput, CommonInputProps } from "./CommonInput";
import { useState } from "react";
import { getPasswordCheck } from "../../../utils/login/inputCheck";
import { PWdescriptionObj } from "./PassWordInput";

interface PassWordCheckProps {
  inputMode: string;
  width: string;
  title?: string;
  initialInput?: string;
  pwValueForCheck: string;
  onVaild?: (isValid: boolean) => void;
}

export const PWInputType = {
  INPUT: "input",
  CHECK: "check",
};

export function PassWordCheckInput({
  inputMode,
  width,
  initialInput,
  pwValueForCheck,
  title,
  onVaild,
}: PassWordCheckProps) {
  const [pwConfirmDes, setPwConfirmDes] = useState<string>("");

  //   비밀번호 확인
  //   onChange만 필요
  const onTextChanged = (str: string) => {
    if (str !== pwValueForCheck) {
      setPwConfirmDes(PWdescriptionObj[PWInputType.CHECK]);
      if (onVaild) onVaild(false);
    } else {
      setPwConfirmDes("");
      if (onVaild) onVaild(true);
    }
  };

  return (
    <CommonInput
      inputMode={inputMode}
      title={title ? title : "비밀번호 확인"}
      placeHold={null}
      description={pwConfirmDes}
      width={width}
      onTextChanged={onTextChanged}
      setClearInput={(clearInputFunction: () => void) => {}}
      initialInput={initialInput}
      passWordType={true}
    />
  );
}
