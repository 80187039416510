import styled from "styled-components";
import { fontFamily } from "../../configs/font";
import { colors } from "../../configs/colors";

export default function TitleWithLine({ title }: { title: string }) {
  return (
    <Wrapper>
      <PageTitleStyle>{title}</PageTitleStyle>
      <UnderLine />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const PageTitleStyle = styled.h1`
  font-family: ${fontFamily.Medium};
  font-size: 24px;
`;

const UnderLine = styled.div`
  min-width: 800px;
  background-color: ${colors.main};
  height: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
`;
