import { isDebug } from "../configs/debug";
import { getLoginedUser } from "../configs/user";
import { ManageTaxAgnetInfoSelf } from "../views/pages/MyPages/MyPage_Tax/ManageAgentTax";
import { callWithJwt } from "./baseApi";
import { REF_DOC_TYPE, USER_TYPE } from "./models";

export interface File_Server {
  fileId: number | null;
  loanReqId: number;
  fileType: string;
  file: string;
}
export interface Resume_Server {
  content: string;
  startDate: string;
  endDate: string;
}

function checkFileTypeValid(type: string): boolean {
  let result = false;
  Object.keys(REF_DOC_TYPE).forEach((k) => {
    if (type === k) {
      result = true;
      return true;
    }
  });
  return result;
}

/** 외부에서 직접 호출하지 말 것 */
export function upLoadFile(
  file_server: File_Server,
  companyId: string,
): Promise<boolean> {
  if (!checkFileTypeValid(file_server.fileType)) {
    return new Promise((a, b) => {
      b(false);
    });
  }
  const url = "/api/loan/ref/file";
  const method = "post";
  const body = JSON.stringify({
    fileId: file_server.fileId,
    loanReqId: file_server.loanReqId,
    fileType: file_server.fileType,
    file: file_server.file,
    regiId: companyId,
  });

  return callWithJwt(url, method, body).then((json) => {
    return true;
  });
}

/** 외부에서 직접 호출하지 말 것 */
export function downLoadFile(fileId: string): Promise<File_Server> {
  const url = "/api/loan/ref/file?fileId=" + fileId;
  const method = "get";

  return callWithJwt(url, method, null).then((json) => {
    //   {
    //     "fileId": 20,
    //     "loanReqId": 16,
    //     "file": "파일 테스트 1",
    //     "regiDt": "2024-09-29T17:02:51.580+00:00",
    //     "regiId": "company_1",
    //     "fileType": "FIN_STAT"
    // },
    try {
      const data = json["data"]["data"];
      return {
        fileId: data["fileId"],
        loanReqId: data["loanReqId"],
        fileType: data["fileType"],
        file: data["file"],
      };
    } catch (e) {
      if (isDebug) {
        console.log(e);
      }
      throw e;
    }
  });
}
