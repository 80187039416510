export const Errors: { [key: string]: string } = {
  ERR001: "INVALID_REQEUST",
  ERR002: "INVALID PARAMETER",
  ERR003: "INTERNAL ERROR",
  ERR004: "ILLEGAL ARGUMENT",
  ERR005: "SQL VIOLATION",
  ERR011: "NOT FOUND USER",
  ERR012: "USER LOCKED",
  ERR013: "BAD CREDENTIAL PASSWORD",
  ERR014: "EXPIRED PASSWORD",
};

export const Errors_JWT: { [key: string]: string } = {
  ERR006: "JWT UNKNOWN ERROR",
  ERR007: "JWT WRONG TYPE TOKEN",
  ERR008: "JWT EXPIRED TOKEN",
  ERR009: "JWT UNSUPPORTED_TOKEN",
  ERR010: "JWT ACCESS DENIED",
};
