import { isDebug } from "../configs/debug";
import { getLoginedUser, logout } from "../configs/user";
import { showToast } from "../views/components/baseComponents/BaseComponent";
import { Errors, Errors_JWT } from "./errorModels";

export { callWithJwt, callWithOutJwt };

// const baseUrl = "http://203.249.181.47:18080";
// const baseUrl = "http://dev.bluenormal.co.kr:18080";
// const baseUrl = "https://dev.bluenormal.co.kr:18443";
const baseUrl = "https://dev.bluenormal.co.kr";

function callWithJwt(
  uri: string,
  method: string,
  bodyJsonString: string | null,
): Promise<any> {
  if (isDebug) {
    console.log(`${uri}\n${method}\n${bodyJsonString}`);
  }
  const jwtToken = getLoginedUser()?.jwtToken;
  if (jwtToken === null) {
    return new Promise((resolve, reject) => {
      return reject("jwt토큰 없음");
    });
  }
  let query: string = "";
  const option: any = {};
  option.method = method;
  if (
    method === "POST" ||
    method === "post" ||
    method === "put" ||
    method === "PUT"
  ) {
    option.body = bodyJsonString;
  }
  option.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken,
  };

  return fetch(baseUrl + uri + query, option)
    .then((res) => {
      if (isDebug) {
        console.log(res);
      }
      const status = res.status;
      if (status < 200 || status >= 300) {
        throw new Error();
      }
      return res.json();
    })
    .then((json) => {
      const code = json["data"]["code"];
      if (code) {
        if (
          Object.keys(Errors_JWT).find((k) => {
            if (k === code) {
              return k;
            }
          })
        ) {
          // jwt 에러일 경우
          throw new Error();
        }
        if (
          Object.keys(Errors).find((k) => {
            if (k === code) {
              return k;
            }
          })
        ) {
          throw new Error();
        }
      } else {
        // 정상 응답 아닌 경우임
        throw new Error();
      }
      return json;
    })
    .catch((e) => {
      if (isDebug) {
        console.log(e);
      }
      throw e;
    });
}

function callWithOutJwt(
  uri: string,
  method: string,
  bodyJsonString: string | null,
): Promise<any> {
  if (isDebug) {
    console.log(`${uri}\n${method}\n${bodyJsonString}`);
  }
  let query: string = "";
  const option: any = {};
  option.method = method;

  if (
    method === "POST" ||
    method === "post" ||
    method === "put" ||
    method === "PUT"
  ) {
    option.body = bodyJsonString;
  }
  option.headers = {
    "Content-Type": "application/json",
  };

  return fetch(baseUrl + uri + query, option)
    .then((res) => {
      if (isDebug) {
        console.log(res);
      }
      const status = res.status;
      if (status < 200 || status >= 300) {
        throw new Error();
      }
      return res.json();
    })
    .then((json) => {
      const code = json["data"]["code"];
      if (code) {
        if (
          Object.keys(Errors).find((k) => {
            if (k === code) {
              return k;
            }
          })
        ) {
          throw new Error();
        }
        if (
          Object.keys(Errors).find((k) => {
            if (k === code) {
              return k;
            }
          })
        ) {
          throw new Error();
        }
      } else {
        throw new Error();
      }
      return json;
    })
    .catch((e) => {
      console.log(e);
      throw e;
    });
}
