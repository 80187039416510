import { CommonInput, CommonInputProps } from "./CommonInput";
import { useState } from "react";
import { getPasswordCheck } from "../../../utils/login/inputCheck";

/**
 * pwValueForCheck
 * 비밀번호 확인을 위한 값
 */
interface PassWordInputProps {
  needRegex: boolean;
  inputMode: string;
  width: string;
  onTextChanged: (str: string) => void;
  initialInput?: string;
  title?: string;
  description?: string;
  isValid?: (valid: boolean) => void;
}

export const PWInputType = {
  INPUT: "input",
  CHECK: "check",
};

export const PWdescriptionObj: { [key: string]: string } = {
  input: `최소 비밀번호 길이 8자리 \n 1개 이상의 대문자, 숫자, 특수문자 필수`,
  check: "같은 비밀번호를 입력해 주세요",
};

/**
 * onTextChanged 예시
 *  (str: string) => {inputs.pw = str;}
 * 객체의 속성 변경하는 함수

 * @param param0
 * @returns
 */
export function PassWordInput({
  needRegex,
  inputMode,
  width,
  onTextChanged,
  initialInput,
  title,
  description,
  isValid,
}: PassWordInputProps) {
  const [errorText, setErrorText] = useState<string | null>("");
  const passwordCheck = getPasswordCheck();

  //   비밀번호
  // errText 필요
  const PWInputOnTextChanged = (str: string) => {
    passwordCheck.input = str;
    const errMsg = passwordCheck.getErrorMessage();
    if (isValid) {
      isValid(errMsg === null || errMsg === "");
    }
    setErrorText(errMsg);
  };

  const onTextChangedCustom = (str: string) => {
    onTextChanged(str);
    if (needRegex) {
      PWInputOnTextChanged(str);
    }
  };

  return (
    <CommonInput
      inputMode={inputMode}
      title={title ? title : "비빌번호"}
      placeHold={null}
      description={
        "" +
        (description ? description : "") +
        "\n" +
        (needRegex ? PWdescriptionObj[PWInputType.INPUT] : "")
      }
      errorText={errorText}
      width={width}
      onTextChanged={onTextChangedCustom}
      setClearInput={(clearInputFunction: () => void) => {}}
      initialInput={initialInput}
      passWordType={true}
    />
  );
}
