import { useEffect, useState } from "react";
import { getLoginedUser } from "../../../configs/user";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../components/baseComponents/BaseComponent";
import styled from "styled-components";
import logo from "../../../assets/logo.png";
import { fontFamily } from "../../../configs/font";
import { colors } from "../../../configs/colors";
import corperation from "../../../assets/companyTypeImages/corperation.png";
import corperation_x from "../../../assets/companyTypeImages/corperation_x.png";
import personal from "../../../assets/companyTypeImages/personal.png";
import personal_x from "../../../assets/companyTypeImages/personal_x.png";
import { CommonBlueButton } from "../../components/buttons/commonButtons";
import { ApplyService_Pre } from "./ApplyService_Pre";

export function ApplyServicePageIndex() {
  const navigate = useNavigate();
  const [content, setContent] = useState<JSX.Element | null>(null);
  useEffect(() => {
    const user = getLoginedUser();

    if (user) {
      setContent(<ApplyService_Pre />);
      // setContent(<SelectCompanyType />);
    } else {
      showToast("로그인이 필요한 서비스 입니다", 3000);
      navigate("/");
    }
  }, []);
  return <div>{content ? content : ""}</div>;
}

export function SelectCompanyType() {
  const navigate = useNavigate();
  const [isP, setIsP] = useState<boolean>(false);
  const [isC, setIsC] = useState<boolean>(false);
  return (
    <Parent>
      <LogoStyle src={logo} />
      <BigTitle>대환대출 신청하기</BigTitle>
      <SmallTitle>사업자 유형을 선택해 주세요</SmallTitle>
      <RowSt>
        <HoverImgStyle
          src={!isC ? corperation : corperation_x}
          onClick={() => {
            setIsP(false);
            setIsC(true);
          }}
        />
        <HoverImgStyle
          src={!isP ? personal : personal_x}
          onClick={() => {
            setIsC(false);
            setIsP(true);
          }}
        />
      </RowSt>
      <CommonBlueButton
        text="서비스 신청하기"
        onclick={() => {
          if (isC) {
            navigate("/applyService/Corperation");
          } else if (isP) {
            navigate("/applyService/Peronnal");
          } else {
            showToast("유형을 선택해 주세요", 2000);
          }
        }}
        minWidth="100px"
      />
    </Parent>
  );
}
const RowSt = styled.div`
  display: flex;
`;
const HoverImgStyle = styled.img`
  width: 160px;
  height: 160px;
  &:hover {
    cursor: pointer;
  }
`;
const BigTitle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 40px;
  color: black;
`;
const SmallTitle = styled.p`
  font-family: ${fontFamily.Medium};
  font-size: 20px;
  color: black;
`;
const Parent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LogoStyle = styled.img`
  width: 330px;
  height: 60px;
  margin-top: 20px;
`;
